import { useState } from 'react'

const usePasswords = () => {
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return {
    togglePasswordVisibility,
    showPassword,
    setShowPassword,
  }
}

export default usePasswords
