import { useState, useEffect } from "react";
import createAxiosInstance from "@api/Api";
import toast from "react-hot-toast";

const useEmailArticles = () => {
  const api = createAxiosInstance("user/email-articles");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [emailArticles, setEmailArticles] = useState([]);
  const [userEmailArticles, setUserEmailArticles] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(saveEmailArticles(), {
      loading: "Saving...",
      success: (data) => data.message,
      error: "Could not save.",
    });
  };

  const handleCheckboxChange = () => {
    setUserEmailArticles(getSelectedCheckboxes);
  };

  const getSelectedCheckboxes = () => {
    const checkboxes = document.querySelectorAll(".email-articles:checked");
    const selectedValues = Array.from(checkboxes).map((checkbox) => checkbox.value);
    return selectedValues;
  };

  const getEmailArticles = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(``);

      setEmailArticles(data.email_articles);
      setUserEmailArticles(data.user_email_articles);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveEmailArticles = async () => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put(``, {
        email_articles: userEmailArticles,
      });
      return Promise.resolve(data);
    } catch (error) {
      setErrors(error.response.data.errors);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmailArticles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    errors,
    emailArticles,
    userEmailArticles,
    handleCheckboxChange,
    handleSubmit,
    getEmailArticles,
    saveEmailArticles,
  };
};

export default useEmailArticles;
