import Card from "@/components/Element/Card";
import useTaxBenefitsTool from "@api/Standard/WealthCreation/useTaxBenefitsTool";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import PercentageSwitch from "@form/PercentageSwitch";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";

const TaxBenefits = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    handleSubmit,
    handleChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
  } = useTaxBenefitsTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("tax-benefits-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Background
              <ul className="pl-4">
                <li className="my-1">
                  a) Retirement contributions are tax deductible - maximum of 27.5% of taxable income up to a maximum of
                  R350k p.a. (excess contributions can be rolled forward to following tax year)
                </li>
                <li className="my-1">
                  b) Returns on retirement fund returns (including interest, income, dividends & capital gains) are
                  tax-free within the fund.
                </li>
                <li className="my-1">
                  c){" "}
                  <i>
                    We note that up to a third of an RA can be taken as a lump sum (or all if it is worth less than
                    R247,500). First R500k is untaxed, next R200k is taxed at 18%, next R350k at 27%, thereafter at 36%;
                    however, the balance must be converted to an annuity. We ignore this scenario for the purposes of
                    this representation.
                  </i>
                </li>
                <li className="my-1">
                  d) In this tool, we are comparing a WITHDRAWAL scenario to an after-tax investment scenario.
                </li>
                <li className="my-1">
                  e) Retirement withdrawals are taxed at a lower rate than pre-retirement income (lower scales); however
                  Capital Gains Tax (CGT) is a maximum of 20% (inclusion rate of 40%, max marginal rate of 45%)
                </li>
                <li className="my-1">
                  f) All numbers are subject to annual review by the Minister of Finance but are likely to be adjusted
                  for inflation
                </li>
                <li className="my-1">
                  g) Hence, we need to allow for 3 tax rates
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Normal income tax - this allows us to identify what the tax rebate is for retirement fund
                      contributions to calculate what the value of the equivalent investment contribution is
                    </li>
                    <li className="my-1">
                      ii) Retirement fund withdrawal rates - this allows us to identify the after-tax capital value of
                      the retirement fund
                    </li>
                    <li className="my-1">
                      iii) Applicable CGT - we need to calculate the investors equivalent marginal tax rate to calculate
                      the CGT payable on the investment.
                    </li>
                  </ul>
                </li>
                <li className="my-1">
                  h) Note that this comparison is a forward projection; hence it ignores the tax status of prior
                  contributions.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Inputs are as follows:
              <ul className="pl-4">
                <li className="my-1">
                  a) Generic: projected average inflation rate, effective date, age, Retirement age, years to
                  retirement, <strong>projected tax tables (both retirement and capital gains tax)</strong>
                </li>
                <li className="my-1">
                  b) Generic: pre-tax contributions, pre-and post-retirement growth rates and associated costs
                </li>
                <li className="my-1">
                  c) Additional: annual income (determines marginal tax racket), annual real growth in income,
                  contributions, annual real growth in contributions (standard assumption is that contributions increase
                  in line with income)
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is represented by a table that shows real and nominal values of
              <ul className="pl-4">
                <li className="my-1">
                  a) Post-tax withdrawal cumulative value for retirement contributions at retirement
                </li>
                <li className="my-1">
                  b) Post-tax withdrawal cumulative value for investment contributions at retirement
                </li>
                <li className="my-1">c) Difference</li>
                <li className="my-1">d) 3 different net pre-retirement growth rates</li>
                <li className="my-1">e) Chart is a choice of 2 representations (real and nominal) of output data.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("tax-benefits-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Tax benefits of investing in a retirement fund vs other?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Tax Benefits" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="number"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="growthLow"
                              id="growthLow"
                              value={formData.growthLow}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="growthMedium"
                              id="growthMedium"
                              value={formData.growthMedium}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="growthHigh"
                              id="growthHigh"
                              value={formData.growthHigh}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="annualCosthLow"
                              id="annualCosthLow"
                              value={formData.annualCosthLow}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="annualCostMedium"
                              id="annualCostMedium"
                              value={formData.annualCostMedium}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="annualCosthHigh"
                              id="annualCosthHigh"
                              value={formData.annualCosthHigh}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-[12px]">Low</th>
                        <th className="text-[12px]">Medium</th>
                        <th className="text-[12px]">High</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="py-[5px] text-[12px] font-bold">Net growth pre-retirement</td>
                      <td className="py-[5px]">
                        <Percentage
                          name="netGrowthLow"
                          id="netGrowthLow"
                          value={formData.netGrowthLow}
                          onChange={handleChange}
                          variant="red"
                          disabled="disabled"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </td>
                      <td className="py-[5px]">
                        <Percentage
                          name="netGrowthMedium"
                          id="netGrowthMedium"
                          value={formData.netGrowthMedium}
                          onChange={handleChange}
                          variant="red"
                          disabled="disabled"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </td>
                      <td className="py-[5px]">
                        <Percentage
                          name="netGrowthHigh"
                          id="netGrowthHigh"
                          value={formData.netGrowthHigh}
                          onChange={handleChange}
                          variant="red"
                          disabled="disabled"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Currency
                      label="Annual pre-tax income"
                      name="annual_pre_tax_income"
                      id="annual_pre_tax_income"
                      value={formData.annual_pre_tax_income}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <PercentageSwitch
                      label={capitalizeFirstLetter(formData.primary_representation) + " growth in income"}
                      primaryRepresentation={formData.primary_representation}
                      realField="real_growth_in_income"
                      nominalField="nominal_growth_in_income"
                      formData={formData}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Input
                      label="Current tax year"
                      name="current_tax_year"
                      id="current_tax_year"
                      value={formData.current_tax_year}
                      onChange={handleChange}
                      variant="red"
                      disabled="disabled"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Percentage
                      label="Marginal tax rate"
                      name="marginal_tax_rate"
                      id="marginal_tax_rate"
                      value={formData.marginal_tax_rate}
                      onChange={handleCurrencyChange}
                      variant="red"
                      disabled="disabled"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Currency
                      label="Current monthly contribution"
                      name="current_monthly_contribution"
                      id="current_monthly_contribution"
                      value={formData.current_monthly_contribution}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Currency
                      label="Equivalent after-tax contribution"
                      name="equivalent_after_tax_contribution"
                      id="equivalent_after_tax_contribution"
                      value={formData.equivalent_after_tax_contribution}
                      onChange={handleCurrencyChange}
                      variant="red"
                      disabled="disabled"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Input
                      label="Retirement date"
                      name="retirement_date"
                      id="retirement_date"
                      type="date"
                      value={formData.retirement_date}
                      onChange={handleChange}
                      variant="red"
                      disabled="disabled"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Input
                      label="Retirement tax year"
                      name="retirement_tax_year"
                      id="retirement_tax_year"
                      type="date"
                      value={formData.retirement_tax_year}
                      onChange={handleChange}
                      variant="red"
                      disabled="disabled"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows after-tax difference in the value of your investment through a retirement fund
                    vs a standard investment fund paying capital gains tax (CGT). NB: South African law provides for a
                    Tax-free savings account to avoid CGT up to a current contribution of R36,000 p.a. per individual -
                    we highly recommend its use.
                  </p>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Comparison at retirement
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Net retirement fund capital (after withdrawal tax)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.retiremnt_after_withdrawal_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.retiremnt_after_withdrawal_medium}
                      </td>
                      <td className="bg-[rgb(242,225,195)] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.retiremnt_after_withdrawal_high}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Net investment capital (after capital gains tax)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.investment_after_cgt_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.investment_after_cgt_medium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.investment_after_cgt_high}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Difference (after-tax)
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.investment_after_cgt_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.investment_after_cgt_medium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.after_tax_difference_high}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} Capital growth & capital at work -
                pre-retirement
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                        className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default TaxBenefits;
