import createAxiosInstance from "@api/Api";
import { useEffect, useState } from "react";

const useLifestyleTools = () => {
  const api = createAxiosInstance("standard/lifestyle/buy-vs-rent");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "chart",
      data: [1, 2, 3, 4, 5],
    },
  ]);

  const [formData, setFormData] = useState({
    primary_representation_id: "",
    primary_representation: "",
    effective_date: "",
    projected_inflation_rate: "",
    growth_rate: "Low",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCurrencyChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePrimaryRepresentationChange = (value) => {
    setFormData({ ...formData, primary_representation: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateOutput(formData);
  };

  const calculateOutput = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);
      console.log(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation_id: data.ufs.primary_representation_id || "",
        primary_representation: data.ufs.primary_representation || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        effective_date: data.ufs.effective_date,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    handleChange,
    handleCurrencyChange,
    handleSubmit,
    chartSeries,
    setChartSeries,
    handlePrimaryRepresentationChange,
  };
};
export default useLifestyleTools;
