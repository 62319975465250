import { FaEye, FaEyeSlash } from "react-icons/fa";

const VisibilityToggle = ({ keyName, isVisible, toggleVisibility }) => {
  return (
    <>
      {!isVisible(keyName) ? (
        <FaEye
          onClick={() => {
            toggleVisibility(keyName);
          }}
        />
      ) : (
        <FaEyeSlash
          onClick={() => {
            toggleVisibility(keyName);
          }}
        />
      )}
    </>
  );
};

export default VisibilityToggle;
