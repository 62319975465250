import useFinancialSettings from "@api/User/useFinancialSettings";
import Button from "@form/Button";
import Error from "@form/Error";
import Currency from "@form/Currency";
import Percentage from "@form/Percentage";
import Select from "@form/Select";

const FinancialSettings = () => {
  const {
    formData,
    handleChange,
    handleCurrencyChange,
    handleSubmit,
    handleCheckboxChange,
    handleSelectChange,
    errors,
    currencies,
    taxDomiciles,
    primaryRepresentations,
    loading,
    handleNominalChange,
    handleRealChange,
  } = useFinancialSettings();

  return (
    <>
      <h3 className="mb-4 text-xl font-semibold">Financial Settings</h3>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-1">
            <div className="flex items-center justify-between py-4">
              <div className="flex flex-col flex-grow">
                <div className="text-lg font-semibold text-gray-900">Use Default</div>
              </div>
              <label
                className="relative inline-flex items-center cursor-pointer"
                htmlFor="default_financial_settings"
              >
                <input
                  type="checkbox"
                  name="default_financial_settings"
                  className="sr-only peer"
                  id="default_financial_settings"
                  value="default_financial_settings"
                  checked={formData.default_financial_settings}
                  onChange={handleCheckboxChange}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#243665]"></div>
              </label>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-6 sm:col-span-1">
            <Select
              label="Currency"
              defaultItem="Select Currency"
              id="currency_id"
              name="currency_id"
              value={formData.currency_id}
              onChange={handleChange}
              items={currencies}
              disabled={formData.default_financial_settings ? true : false}
            />
            <Error error={errors && errors.currency_id} />
          </div>
          <div className="col-span-6 sm:col-span-1">
            <Select
              label="Tax Domicile"
              defaultItem="Select Tax Domicile"
              id="tax_domicile_id"
              name="tax_domicile_id"
              value={formData.tax_domicile_id}
              onChange={handleChange}
              items={taxDomiciles}
              disabled={formData.default_financial_settings ? true : false}
            />
            <Error error={errors && errors.tax_domicile_id} />
          </div>
          <div className="col-span-6 sm:col-span-1">
            <Select
              label="Primary Representation"
              id="primary_representation_id"
              name="primary_representation_id"
              value={formData.primary_representation_id}
              onChange={handleSelectChange}
              items={primaryRepresentations}
              disabled={formData.default_financial_settings ? true : false}
            />
            <Error error={errors && errors.primary_representation_id} />
          </div>
          <div className="col-span-6 sm:col-span-1">
            <Percentage
              label="Projected Inflation Rate"
              name="projected_inflation_rate"
              id="projected_inflation_rate"
              value={formData.projected_inflation_rate}
              onChange={handleCurrencyChange}
              disabled={formData.default_financial_settings ? true : false}
              variant="white"
            />
            <Error error={errors && errors.projected_inflation_rate} />
          </div>

          {/* REAL Pre Retirement */}
          {formData.primary_representation_id === 1 ? (
            <>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Real pre-retirement capital growth low"
                  name="real_pre_retirement_capital_growth_low"
                  id="real_pre_retirement_capital_growth_low"
                  value={formData.real_pre_retirement_capital_growth_low}
                  onChange={handleRealChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.real_pre_retirement_capital_growth_low} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Real pre-retirement capital growth medium"
                  name="real_pre_retirement_capital_growth_medium"
                  id="real_pre_retirement_capital_growth_medium"
                  value={formData.real_pre_retirement_capital_growth_medium}
                  onChange={handleRealChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.real_pre_retirement_capital_growth_medium} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Real pre-retirement capital growth high"
                  name="real_pre_retirement_capital_growth_high"
                  id="real_pre_retirement_capital_growth_high"
                  value={formData.real_pre_retirement_capital_growth_high}
                  onChange={handleRealChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.real_pre_retirement_capital_growth_high} />
              </div>
            </>
          ) : (
            <>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Nominal pre-retirement capital growth low"
                  name="nominal_pre_retirement_capital_growth_low"
                  id="nominal_pre_retirement_capital_growth_low"
                  value={formData.nominal_pre_retirement_capital_growth_low}
                  onChange={handleNominalChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.nominal_pre_retirement_capital_growth_low} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Nominal pre-retirement capital growth medium"
                  name="nominal_pre_retirement_capital_growth_medium"
                  id="nominal_pre_retirement_capital_growth_medium"
                  value={formData.nominal_pre_retirement_capital_growth_medium}
                  onChange={handleNominalChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.nominal_pre_retirement_capital_growth_medium} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Nominal pre-retirement capital growth high"
                  name="nominal_pre_retirement_capital_growth_high"
                  id="nominal_pre_retirement_capital_growth_high"
                  value={formData.nominal_pre_retirement_capital_growth_high}
                  onChange={handleNominalChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.nominal_pre_retirement_capital_growth_high} />
              </div>
            </>
          )}

          <div className="col-span-6 sm:col-span-1">
            <Percentage
              label="Pre-retirement costs"
              name="pre_retirement_costs"
              id="pre_retirement_costs"
              value={formData.pre_retirement_costs}
              onChange={handleCurrencyChange}
              disabled={formData.default_financial_settings ? true : false}
              variant="white"
            />
            <Error error={errors && errors.pre_retirement_costs} />
          </div>

          {/* REAL Post Retirement */}

          {formData.primary_representation_id === 1 ? (
            <>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Real post-retirement capital growth low"
                  name="real_post_retirement_capital_growth_low"
                  id="real_post_retirement_capital_growth_low"
                  value={formData.real_post_retirement_capital_growth_low}
                  onChange={handleRealChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.real_post_retirement_capital_growth_low} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Real post-retirement capital growth medium"
                  name="real_post_retirement_capital_growth_medium"
                  id="real_post_retirement_capital_growth_medium"
                  value={formData.real_post_retirement_capital_growth_medium}
                  onChange={handleRealChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.real_post_retirement_capital_growth_medium} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Real post-retirement capital growth high"
                  name="real_post_retirement_capital_growth_high"
                  id="real_post_retirement_capital_growth_high"
                  value={formData.real_post_retirement_capital_growth_high}
                  onChange={handleRealChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.real_post_retirement_capital_growth_high} />
              </div>
            </>
          ) : (
            <>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Nominal post-retirement capital growth low"
                  name="nominal_post_retirement_capital_growth_low"
                  id="nominal_post_retirement_capital_growth_low"
                  value={formData.nominal_post_retirement_capital_growth_low}
                  onChange={handleNominalChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.nominal_post_retirement_capital_growth_low} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Nominal post-retirement capital growth medium"
                  name="nominal_post_retirement_capital_growth_medium"
                  id="nominal_post_retirement_capital_growth_medium"
                  value={formData.nominal_post_retirement_capital_growth_medium}
                  onChange={handleNominalChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.nominal_post_retirement_capital_growth_medium} />
              </div>
              <div className="col-span-6 sm:col-span-1">
                <Percentage
                  label="Nominal post-retirement capital growth high"
                  name="nominal_post_retirement_capital_growth_high"
                  id="nominal_post_retirement_capital_growth_high"
                  value={formData.nominal_post_retirement_capital_growth_high}
                  onChange={handleNominalChange}
                  disabled={formData.default_financial_settings ? true : false}
                  variant="white"
                />
                <Error error={errors && errors.nominal_post_retirement_capital_growth_high} />
              </div>
            </>
          )}

          <div className="col-span-6 sm:col-span-1">
            <Percentage
              label="Post-retirement costs"
              name="post_retirement_costs"
              id="post_retirement_costs"
              value={formData.post_retirement_costs}
              onChange={handleCurrencyChange}
              disabled={formData.default_financial_settings ? true : false}
              variant="white"
            />
            <Error error={errors && errors.post_retirement_costs} />
          </div>
        </div>

        <div className="grid grid-cols-6 gap-6 mt-6">
          <div className="col-span-6 sm:col-full">
            <Button
              type="submit"
              name="Save"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default FinancialSettings;
