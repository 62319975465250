import useRegister from "@api/Auth/useRegister";
import Modal from "@components/Modals/Modal";
import appContext from "@context/app-context";
import Logo from "@elements/Logo";
import PageTitle from "@elements/PageTitle";
import Button from "@form/Button";
import Input from "@form/Input";
import Password from "@form/Password";
import AuthLayout from "@layouts/AuthLayout";
import usePasswords from "@services/algorithms/usePasswords";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import TermsAndConditions from "../../../components/TermsAndConditions";

export const Register = () => {
  const { togglePasswordVisibility, showPassword } = usePasswords();
  const { register, isLoading } = useContext(appContext);
  const { formData, handleChange, handleCheckboxChange } = useRegister();
  const [modal, setModal] = useState(false);
  const closeModal = () => setModal(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/home");
    }
  });

  return (
    <AuthLayout>
      <PageTitle title="Register" />
      <div className="w-full h-screen ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-screen pt:mt-0">
          <div className="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow">
            <a
              href="/"
              className="flex items-center justify-center mb-8 lg:mb-10"
            >
              <Logo className="mr-4 h-24" />
            </a>
            <h2 className="text-2xl font-bold text-gray-900 text-center">Sign up</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                register(
                  formData.email,
                  formData.password,
                  formData.firstName,
                  formData.lastName,
                  formData.termsAccepted
                );
              }}
              className="mt-8 space-y-6"
            >
              <div>
                <Input
                  label="First name"
                  name="firstName"
                  id="firstName"
                  type="text"
                  required
                  value={formData.firstName}
                  onChange={handleChange}
                  variant="white"
                />
              </div>

              <div>
                <Input
                  label="Last name"
                  name="lastName"
                  id="lastName"
                  type="text"
                  required
                  value={formData.lastName}
                  onChange={handleChange}
                  variant="white"
                />
              </div>
              <div>
                <Input
                  label="Your email"
                  name="email"
                  id="email"
                  type="email"
                  placeholder="name@email.com"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  variant="white"
                />
              </div>
              <div>
                <Password
                  label="Your password"
                  showPassword={showPassword}
                  name="password"
                  id="password"
                  required
                  value={formData.password}
                  onChange={handleChange}
                  togglePasswordVisibility={togglePasswordVisibility}
                  variant="white"
                />
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="termsAccepted"
                    aria-describedby="termsAccepted"
                    name="termsAccepted"
                    type="checkbox"
                    className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300"
                    checked={formData.termsAccepted}
                    onChange={handleCheckboxChange}
                    required
                  />
                </div>
                <div className="ml-3 text-[12px]">
                  <label
                    htmlFor="termsAccepted"
                    className="font-medium text-gray-900 flex"
                  >
                    I accept the
                    <div
                      className="text-primary-700 hover:underline cursor-pointer ml-0.5"
                      onClick={() => {
                        setModal(true);
                      }}
                    >
                      Terms and Conditions
                    </div>
                  </label>
                </div>
              </div>
              <Button
                type="submit"
                name="Create account"
                loading={isLoading}
                className={twMerge("min-w-[135px]")}
              />
              <div className="text-[12px] font-medium text-gray-500">
                Already have an account?{" "}
                <div
                  onClick={() => navigate(-1)}
                  className="text-primary-700 hover:underline inline-block cursor-pointer"
                >
                  Login here
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        closeModal={closeModal}
        modalContainerPadding={twMerge("py-[50px]")}
        modalContent={<TermsAndConditions />}
      />
    </AuthLayout>
  );
};
