import Card from "@/components/Element/Card";
import useTargetedInvestmentsTool from "@api/Standard/WealthCreation/useTargetedInvestmentsTool";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import PercentageSwitch from "@form/PercentageSwitch";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { IoCloseCircleOutline } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

const TargetedInvestments = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    handleSubmit,
    handleChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
  } = useTargetedInvestmentsTool();

  const [showHelpPopup, setShowHelpPopup] = useState(
    localStorage.getItem("targeted-investments-help-popup") === "true"
  );

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Tool will assist clients to understand what a reasonable level of savings for retirement would be relative
              to current income, current age and retirement targets.
            </li>
            <li className="mb-2">
              It will similarly present a projected path to retirement given different return expectations.
            </li>
            <li className="mb-2">
              The following information should be noted
              <ul className="pl-4">
                <li className="my-1">
                  a) If one targets a lower future growth rate, then one requires higher historic capital accumulation
                  (and usually higher historic contributions)
                </li>
                <li className="my-1">
                  b) We demonstrate the typical performance of retirement funds throughout each client’s historic
                  capital accumulation period; hence, all histpric representations conform to historic realities and are
                  not adjustable by return, only contribution rate.
                </li>
                <li>
                  c) This allows us to demonstrate realistic targets by age/time to give effect to retirement
                  requirements / other goals.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("targeted-investments-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  const GrowthPreRetirementRow = () => {
    return (
      <tr>
        <td className="py-[5px] text-[12px] font-bold">Net growth pre-retirement</td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="pre_retirement_capital_growth_low"
            id="pre_retirement_capital_growth_low"
            value={formData.pre_retirement_capital_growth_low}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="pre_retirement_capital_growth_medium"
            id="pre_retirement_capital_growth_medium"
            value={formData.pre_retirement_capital_growth_medium}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="pre_retirement_capital_growth_high"
            id="pre_retirement_capital_growth_high"
            value={formData.pre_retirement_capital_growth_high}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
      </tr>
    );
  };

  const GrowthPostRetirementRow = () => {
    return (
      <tr>
        <td className="py-[5px] text-[12px] font-bold">Net growth post-retirement</td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="post_retirement_capital_growth_low"
            id="post_retirement_capital_growth_low"
            value={formData.post_retirement_capital_growth_low}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="post_retirement_capital_growth_medium"
            id="post_retirement_capital_growth_medium"
            value={formData.post_retirement_capital_growth_medium}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="post_retirement_capital_growth_high"
            id="post_retirement_capital_growth_high"
            value={formData.post_retirement_capital_growth_high}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
      </tr>
    );
  };

  const [data, setData] = useState([
    [
      {
        label: "Phase #",
        name: `description`,
        inputType: "description",
      },
      {
        value: "",
        name: "age_from0",
        inputType: "input",
        disabled: true,
      },
      {
        value: "",
        name: "age_to0",
        inputType: "input",
      },
      {
        value: "",
        name: "period0",
        inputType: "input",
        disabled: true,
      },
      {
        value: "",
        name: "salary_growth0",
        inputType: "percentage",
        primaryRepresentation: true,
      },
      {
        value: "",
        name: "salary_contributions0",
        inputType: "percentage",
      },
    ],
  ]);

  const handleOnChange = (e) => {
    const newData = data.map((d, i) => {
      if (i === row) {
        d[col].value = e.target.value;
      }

      return d;
    });
    setData(newData);
  };

  const addRow = () => {
    setData([
      ...data,
      [
        {
          label: "Phase #",
          name: `description`,
          inputType: "description",
        },
        {
          value: "",
          name: `age_from${data.length}`,
          inputType: "input",
        },
        {
          value: "",
          name: `age_to${data.length}`,
          inputType: "input",
        },
        {
          value: "",
          name: `period${data.length}`,
          inputType: "input",
          disabled: true,
        },
        {
          value: "",
          name: `salary_growth${data.length}`,
          inputType: "percentage",
          primaryRepresentation: true,
        },
        {
          value: "",
          name: `salary_contributions${data.length}`,
          inputType: "percentage",
        },
      ],
    ]);
  };

  const removeRow = (index) => {
    const _data = [...data];
    _data.splice(index, 1);
    setData(_data);
  };

  const PhaseRow = ({ data, handleOnChange, addRow, removeRow }) => {
    return (
      <>
        {data.map((items, i1) => (
          <>
            <tr>
              {items.map((item, i2) => (
                <>
                  <td
                    key={i2}
                    className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]"
                  >
                    {item.inputType === "description" ? (
                      <div className="flex items-center leading-[normal] font-bold">
                        {data.length > 1 && (
                          <IoCloseCircleOutline
                            onClick={() => removeRow(i1)}
                            color="red"
                            className="mr-1 cursor-pointer"
                          />
                        )}
                        {item.label}
                        {i1 + 1}
                      </div>
                    ) : item.inputType === "input" ? (
                      <Input
                        type="number"
                        name=""
                        onChange={(e) => handleOnChange(e, i1, i2)}
                        variant={item.disabled ? "red" : "green"}
                        disabled={item.disabled}
                        twMergeClassName={twMerge(`text-center `)}
                      />
                    ) : item.inputType === "currency" ? (
                      <Currency
                        name=""
                        onChange={(e) => handleOnChange(e, i1, i2)}
                        variant={item.disabled ? "red" : "green"}
                        disabled={item.disabled}
                        twMergeClassName={twMerge(`text-center `)}
                      />
                    ) : item.inputType === "percentage" ? (
                      <>
                        {item.primaryRepresentation ? (
                          <Percentage
                            name=""
                            onChange={(e) => handleOnChange(e, i1, i2)}
                            variant={formData.primary_representation === "nominal" ? "red" : "green"}
                            disabled={formData.primary_representation === "nominal"}
                            twMergeClassName={twMerge(`text-center `)}
                          />
                        ) : (
                          <Percentage
                            name=""
                            onChange={(e) => handleOnChange(e, i1, i2)}
                            variant={item.disabled ? "red" : "green"}
                            disabled={item.disabled}
                            twMergeClassName={twMerge(`text-center `)}
                          />
                        )}
                      </>
                    ) : item.inputType === "date" ? (
                      <Input
                        type="date"
                        name=""
                        onChange={(e) => handleOnChange(e, i1, i2)}
                        variant={item.disabled ? "red" : "green"}
                        disabled={item.disabled}
                        twMergeClassName={twMerge(`text-center `)}
                      />
                    ) : (
                      <></>
                    )}
                  </td>
                </>
              ))}
            </tr>

            {i1 === data.length - 1 && (
              <>
                <tr>
                  <td className="p-2 text-[12px] font-bold border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    Overall annualised growth
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 text-[12px] font-bold text-center border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Percentage
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                </tr>
                <tr>
                  <td className="p-2 text-[12px] font-bold border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    Growth until retirement
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                  <td className="p-2 text-[12px] font-bold text-center border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Percentage
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <Button
                      onClick={addRow}
                      name="Add phase"
                      className="mt-4 mb-2"
                    />
                  </td>
                </tr>
              </>
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Target financial investments by age"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Targeted Investments" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="number"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_pre_retirement_capital_growth_low"
                              nominalField="nominal_pre_retirement_capital_growth_low"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_pre_retirement_capital_growth_medium"
                              nominalField="nominal_pre_retirement_capital_growth_medium"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_pre_retirement_capital_growth_high"
                              nominalField="nominal_pre_retirement_capital_growth_high"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        {GrowthPreRetirementRow()}
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] normal-case font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth post-retirement
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_low"
                              nominalField="nominal_post_retirement_capital_growth_low"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_medium"
                              nominalField="nominal_post_retirement_capital_growth_medium"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_high"
                              nominalField="nominal_post_retirement_capital_growth_high"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        {GrowthPostRetirementRow()}
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        {GrowthPreRetirementRow()}
                        {GrowthPostRetirementRow()}
                      </tbody>
                    </>
                  )}
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Currency
                      label="Annual pre-tax income"
                      name="annual_pre_tax_income"
                      id="annual_pre_tax_income"
                      value={formData.annual_pre_tax_income}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Percentage
                      label="Target income on retirement (% of pre-retirement)"
                      name="pre_retirement_income_percentage"
                      id="pre_retirement_income_percentage"
                      value={formData.pre_retirement_income_percentage}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Input
                      label="Contribution commencement age"
                      name="contribution_commencement_age"
                      id="contribution_commencement_age"
                      type="number"
                      value={formData.contribution_commencement_age}
                      onChange={handleChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Contribution & income growth map</h3>
                </div>
                <div className="scrollbar overflow-x-scroll">
                  <table className="table-auto w-full">
                    <thead className="bg-[#ffffff]">
                      <tr className="whitespace-nowrap bg-[#f1f1f1]">
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Age related brackets
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Age from
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Age to
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Period
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          {capitalizeFirstLetter(formData.primary_representation)} salary growth
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Salary contributions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <PhaseRow
                        data={data}
                        addRow={addRow}
                        removeRow={removeRow}
                        handleOnChange={handleOnChange}
                      />
                    </tbody>
                  </table>
                </div>
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                </div>
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <Currency
                      disabled
                      label="Estimated income at contribution inception"
                      onChange={handleCurrencyChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div>
                    <Currency
                      disabled
                      label="Target income at retirement:"
                      onChange={handleCurrencyChange}
                      value={formData.target_income}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="align-middle text-[12px] text-left font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Description
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Low
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Medium
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        What should I have accumulated by my current age?
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Estimated amount at retirement on this path
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Minimum targeted real capital by now
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Amount required at retirement at expected growth rates
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-4 text-xs font-medium">
                  (*these use historical retirement fund outcomes +- 1.5% p.a.)
                </p>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">Estimated value of investments by age</p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                        className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default TargetedInvestments;
