import createAxiosInstance from "@api/Api";
import helpers from "@utils/helpers";
import { useEffect, useState } from "react";

const useTargetCapitalCalculation = (formData) => {
  const calculateTargetCapital = (representation, inflation, years) => {
    const { targetCapital } = formData;
    if (representation === "real") {
      return (targetCapital / Math.pow(1 + inflation / 100, years)).toFixed(2);
    } else if (representation === "nominal") {
      return (targetCapital * Math.pow(1 + inflation / 100, years)).toFixed(2);
    }
    return "";
  };

  return calculateTargetCapital;
};

const useWealthCreationTools = () => {
  const api = createAxiosInstance("standard/wealth-creation/goal-investing");
  const { convertChartSeries } = helpers();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "",
      data: [],
    },
  ]);

  const [formData, setFormData] = useState({
    horizonMonths: 0,
    horizonYears: 0,
    targetDate: "",
    effectiveDate: "",
    initialCapital: 0,
    targetCapital: 0,
    inflation: 0,
    riskLevel: "Low",
    primaryRepresentation: "real",
    growthLow: 0,
    growthMedium: 0,
    growthHigh: 0,
    annualCosthLow: 0,
    annualCostMedium: 0,
    annualCosthHigh: 0,
    netGrowthLow: 0,
    netGrowthMedium: 0,
    netGrowthHigh: 0,
    savingsRequiredNoIncreaseLow: 0,
    savingsRequiredNoIncreaseMedium: 0,
    savingsRequiredNoIncreaseHigh: 0,
    savingsRequiredIncreaseLow: 0,
    savingsRequiredIncreaseMedium: 0,
    savingsRequiredIncreaseHigh: 0,
  });

  const calculateTargetCapital = useTargetCapitalCalculation(formData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateOutput(formData);
  };

  const handlePrimaryRepresentationChange = (value) => {
    setFormData({ ...formData, primary_representation: value });
  };

  const handleCurrencyChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const calculateOutput = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { low, medium, high } = data.summary;

      setFormData((prevFormData) => ({
        ...prevFormData,
        savingsRequiredNoIncreaseLow: low.noIncrease.toFixed(2),
        savingsRequiredNoIncreaseMedium: medium.noIncrease.toFixed(2),
        savingsRequiredNoIncreaseHigh: high.noIncrease.toFixed(2),
        savingsRequiredIncreaseLow: low.increase.toFixed(2),
        savingsRequiredIncreaseMedium: medium.increase.toFixed(2),
        savingsRequiredIncreaseHigh: high.increase.toFixed(2),
      }));

      const series = convertChartSeries(data.data);
      setChartSeries(series);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const years = formData.horizonMonths / 12;
    const newTargetCapital = calculateTargetCapital(formData.primaryRepresentation, formData.inflation, years);
    setFormData((prevFormData) => ({
      ...prevFormData,
      targetCapital: newTargetCapital,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.primaryRepresentation, formData.inflation]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      horizonYears: prevFormData.horizonMonths / 12,
    }));
  }, [formData.horizonMonths]);

  useEffect(() => {
    setFormData((prevFormData) => {
      const [year, month, day] = prevFormData.effectiveDate.split("-");
      const targetYear = parseInt(year) + prevFormData.horizonYears;
      return {
        ...prevFormData,
        targetDate: `${targetYear}-${month}-${day}`,
      };
    });
  }, [formData.effectiveDate, formData.horizonYears]);

  useEffect(() => {
    //calculateOutput(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        horizonMonths: data.ufs.horizonMonths || "",
        horizonYears: data.ufs.horizonYears || "",
        targetDate: data.ufs.targetDate || "",
        effectiveDate: data.ufs.effectiveDate || "",
        initialCapital: data.ufs.initialCapital || "",
        targetCapital: data.ufs.targetCapital || "",
        inflation: data.ufs.inflation || "",
        riskLevel: data.ufs.riskLevel || "",
        primaryRepresentation: data.ufs.primaryRepresentation || "",
        growthLow: data.ufs.growthLow || "",
        growthMedium: data.ufs.growthMedium || "",
        growthHigh: data.ufs.growthHigh || "",
        annualCosthLow: data.ufs.annualCosthLow || "",
        annualCostMedium: data.ufs.annualCostMedium || "",
        invesannualCosthHightment_costs: data.ufs.annualCosthHigh || "",
        netGrowthLow: data.ufs.netGrowthLow || "",
        netGrowthMedium: data.ufs.netGrowthMedium || "",
        netGrowthHigh: data.ufs.netGrowthHigh || "",
        savingsRequiredNoIncreaseLow: data.ufs.savingsRequiredNoIncreaseLow || "",
        savingsRequiredNoIncreaseMedium: data.ufs.savingsRequiredNoIncreaseMedium || "",
        savingsRequiredNoIncreaseHigh: data.ufs.savingsRequiredNoIncreaseHigh || "",
        savingsRequiredIncreaseLow: data.ufs.savingsRequiredIncreaseLow || "",
        savingsRequiredIncreaseMedium: data.ufs.savingsRequiredIncreaseMedium || "",
        savingsRequiredIncreaseHigh: data.ufs.savingsRequiredIncreaseHigh || "",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    handleChange,
    handleSubmit,
    chartSeries,
    calculateTargetCapital,
    handlePrimaryRepresentationChange,
    handleCurrencyChange,
  };
};

export default useWealthCreationTools;
