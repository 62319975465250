import { useState } from "react";
import createAxiosInstance from "@api/Api";
import toast from "react-hot-toast";

const usePasswordInformation = () => {
  const api = createAxiosInstance("user/password-information");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const [formData, setFormData] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(savePasswordInformation(formData), {
      loading: "Saving...",
      success: (data) => data.message,
      error: "Could not save.",
    });
  };

  const savePasswordInformation = async (formData) => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put(``, {
        current_password: formData.currentPassword,
        password: formData.password,
        password_confirmation: formData.confirmPassword,
      });
      return Promise.resolve(data);
    } catch (error) {
      setErrors(error.response.data.errors);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    errors,
    formData,
    handleChange,
    handleSubmit,
    savePasswordInformation,
  };
};

export default usePasswordInformation;
