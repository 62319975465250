import { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";

type Props = {
  title: string | null;
} & PropsWithChildren;

function PageTitle({ title }: Props) {
  return (
    <Helmet>
      <title>Future You - {title}</title>
    </Helmet>
  );
}

export default PageTitle;
