import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosError } from "axios";

const createAxiosInstance = (urlSuffix: string): AxiosInstance => {
  const baseURL = `${import.meta.env.VITE_APP_API_BASE_URL}${urlSuffix}`;
  const instance = axios.create({
    baseURL,
    withCredentials: false,
  });

  const addAuthorizationToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };

  const handleRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  };

  instance.interceptors.request.use(addAuthorizationToken, handleRequestError);

  return instance;
};

export default createAxiosInstance;
