import useTwoPotRetirementTool from "@api/Standard/Additional/useTwoPotRetirementTool";
import PageTitle from "@elements/PageTitle";
import StandardToolLayout from "@layouts/StandardToolLayout";
import { useState } from "react";

const TwoPotRetirement = () => {
  const { loading, error, formData, handleChange, handleSubmit, twoPotRetirementData } = useTwoPotRetirementTool();

  const [showHelpPopup, setShowHelpPopup] = useState(
    () => localStorage.getItem("two-pot-retirement-help-popup") === "true"
  );

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <p className="mb-0 px-3">
          Effective 31 August 2024, all historical contributions and accumulated assets into retirement funds are
          considered to be vested and, as before, are required to adhere to prevailing (historic) legislation. After
          that date, future retirement fund contributions are split 1/3rd and 2/3rd into 2 separate “pots”, the savings
          pot and the retirement pot respectively. Additionally, the savings pot can be seeded by up to 10% or R30,000
          (whichever amount is lower) from the afore-mentioned vested retirement funds.
          <br />
          <br />
          After 31 August 2024, fund owners can withdraw the full (or a lower) amount from the savings pot{" "}
          <strong>once per year</strong> as long as the savings pot has more than R2000 capital available – note that
          tax is payable on withdrawals since all contributions were made before income tax was applied. However, the
          retirement fund portion cannot be touched until retirement.
          <br />
          <br />
          This tool shows the impact on your overall retirement capital if you make certain specified withdrawals from
          your savings pot OR draw out the maximum available to you every year.
          <br />
          <br />
          As always, we encourage clients to retain as much capital as possible in all their retirement funds.
        </p>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("two-pot-retirement-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Two Pot Retirement Tool"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Two Pot Retirement" />
      <div>
        <form
          onSubmit={handleSubmit}
          className="lg:w-[90%] mx-auto"
        >
          <label>
            Amount:
            <input
              type="text"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
          </label>

          <button type="submit">Submit</button>
        </form>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {twoPotRetirementData && <div>{/* Display twoPotRetirementData */}</div>}
      </div>
    </StandardToolLayout>
  );
};

export default TwoPotRetirement;
