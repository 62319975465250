import AppState from "@/context/AppState";
import "@/main.css";
import AuthRoutes from "@/routes/AuthRoutes";
import CommentaryRoutes from "@/routes/CommentaryRoutes";
import DashboardRoutes from "@/routes/DashboardRoutes";
import StandardRoutes from "@/routes/StandardRoutes";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <AppState>
        <AuthRoutes />
        <DashboardRoutes />
        <StandardRoutes />
        <CommentaryRoutes />
        <Toaster />
      </AppState>
    </Router>
  );
}

export default App;
