import useSolarEnergyTool from "@api/Standard/Additional/useSolarEnergyTool";
import PageTitle from "@elements/PageTitle";
import StandardToolLayout from "@layouts/StandardToolLayout";
import { useState } from "react";

const SolarEnergy = () => {
  const { loading, error, formData, handleChange, handleSubmit, solarEnergyData } = useSolarEnergyTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("solar-energy-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              The goal of this tool is to see whether the cost of installing a solar system (with or without battery
              backup) can be materially offset by the energy produced by the system in contrast to what it costs to
              purchase energy from a supplier.
            </li>
            <li className="mb-2">
              Step #1 : design your system and size by using the universal tool{" "}
              <a
                href="www.pvwatts.nrel.gov"
                target="_blank"
              >
                <u>www.pvwatts.nrel.gov</u>
              </a>
            </li>
            <li className="mb-2">
              This tool will generate an approximate amount of annual energy from solar given your home location,
              orientation, and your specific installation size.
            </li>
            <li className="mb-2">
              Based on your installation size we give you an approximate cost for the standard components for both a
              solar only, as well as one with battery backup (the latter also requires a size specification). Please
              note that this is indicative, and we strongly recommend getting an official quote.
            </li>
            <li className="mb-2">
              We provide default costings and annual adjustments for the following elements:
              <ul className="pl-4">
                <li className="my-1">
                  a) Insurance & maintenance of the solar panels and other elements of the system
                </li>
                <li>
                  b) Annual production degradation of the system (inception, efficiency over time, deterioration, life
                  expectancy)
                </li>
                <li className="my-1">c) Battery (if selected) replacement cycle.</li>
                <li className="my-1">
                  d) Projected grid energy costs, loan costs, interest rates, increased home insurance
                </li>
                <li className="my-1">e) Comparison over time</li>
              </ul>
            </li>
            <li className="mb-2">
              We calculate a breakeven period if you had purchased the solar system outright i.e. how long it takes for
              the initial capital outlay to be overtaken by the cumulative value of electricity that was NOT supplied
              from the grid.
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("solar-energy-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="How much life insurance do I need?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Solar Energy" />
      <div>
        <form
          onSubmit={handleSubmit}
          className="lg:w-[90%] mx-auto"
        >
          <label>
            Amount:
            <input
              type="text"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
          </label>

          <button type="submit">Submit</button>
        </form>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {solarEnergyData && <div>{/* Display solarEnergyData */}</div>}
      </div>
    </StandardToolLayout>
  );
};

export default SolarEnergy;
