import AnalysisType from "@/components/Form/AnalysisType";
import Button from "@/components/Form/Button";
import GrowthRate from "@/components/Form/GrowthRate";
import PercentageSwitch from "@/components/Form/PercentageSwitch";
import Select from "@/components/Form/Select";
import words from "@/services/algorithms/words";
import useLifestyleTools from "@api/Standard/Lifestyle/useBuyVsRentTool";
import Card from "@components/Element/Card";
import VisibilityToggle from "@elements/VisibilityToggle";
import Currency from "@form/Currency";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";

const homeType = [
  {
    name: "Primary Home",
  },
  {
    name: "Secondary Home",
  },
];

const BuyVsRentToolTwo = () => {
  const { isVisible, toggleVisibility } = useVisibility();
  const { chartOptionsDefault } = helpers();
  const { capitalizeFirstLetter } = words();

  const { chartSeries, formData, handleSubmit, handleChange, handleCurrencyChange, handlePrimaryRepresentationChange } =
    useLifestyleTools();

  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit}
          className="lg:w-[90%] mx-auto"
        >
          <div className="grid grid-cols-7 gap-4 w-full min-h-full px-4 pt-4">
            <div className="col-span-7">
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center">
                  <AnalysisType
                    primaryRepresentation={formData.primary_representation}
                    handleChange={handlePrimaryRepresentationChange}
                  />
                </div>
                <div className="flex items-center justify-center ml-10">
                  <GrowthRate
                    riskLevel={formData.growth_rate}
                    handleChange={handleChange}
                  />
                </div>
                <div className="flex items-center justify-center ml-10">
                  <Button
                    type="submit"
                    name="Calculate"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <div className="max-h-[calc(100vh-220px)] scrollbar overflow-y-scroll overflow-x-hidden">
                <Card
                  variant="white"
                  className="mb-4"
                >
                  <div className={`flex items-center mb-4`}>
                    <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                    <span className="ml-4 cursor-pointer">
                      <VisibilityToggle
                        keyName="GenericTable"
                        isVisible={isVisible}
                        toggleVisibility={toggleVisibility}
                      />
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-3 items-end">
                    <div className="col-span-1">
                      <Input
                        label="Date of analysis"
                        name="effective_date"
                        id="effective_date"
                        type="date"
                        value={formData.effective_date}
                        onChange={handleChange}
                        variant="red"
                        twMergeClassName={twMerge(`text-center`)}
                      />
                    </div>
                    {isVisible("GenericTable") && (
                      <>
                        <div className="col-span-1">
                          <Percentage
                            label="CPI inflation"
                            name="projected_inflation_rate"
                            id="projected_inflation_rate"
                            value={formData.projected_inflation_rate}
                            onChange={handleCurrencyChange}
                            variant="red"
                            twMergeClassName={twMerge(`text-center`)}
                          />
                        </div>
                        <div className="col-span-1">
                          <Currency
                            label="Monthly income after tax"
                            name=""
                            id=""
                            onChange={handleCurrencyChange}
                            variant="green"
                            twMergeClassName={twMerge(`text-center`)}
                          />
                        </div>
                        <div className="col-span-1">
                          <Select
                            label="Primary / secondary home"
                            defaultItem="Select Primary / secondary home"
                            id=""
                            name=""
                            onChange={handleChange}
                            variant="green"
                            items={homeType}
                          />
                        </div>
                        <div className="col-span-1">
                          <Percentage
                            label="Annual property appreciation"
                            name="annual_property_appreciation"
                            id="annual_property_appreciation"
                            onChange={handleCurrencyChange}
                            variant="green"
                            twMergeClassName={twMerge(`text-center`)}
                          />
                        </div>
                        <div className="col-span-1">
                          <Input
                            label="Location"
                            name="location"
                            id="location"
                            type="text"
                            onChange={handleChange}
                            variant="green"
                            twMergeClassName={twMerge(`text-center`)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Card>

                <div className="col-span-4 mb-4">
                  <div className="bg-white rounded-md">
                    <div className="rounded-t-md p-4">
                      <h3 className="text-[16px] font-semibold">Home purchase description</h3>
                    </div>
                    <div className="mb-1">
                      <div className={`flex items-center bg-[#C3CBBE]`}>
                        <p className="text-[14px] font-bold p-2">Purchase elements</p>
                        <span className="ml-4 cursor-pointer">
                          <VisibilityToggle
                            keyName="purchase_elements"
                            isVisible={isVisible}
                            toggleVisibility={toggleVisibility}
                          />
                        </span>
                      </div>
                      {isVisible("purchase_elements") && (
                        <div className="grid grid-cols-2 gap-3 items-end p-4">
                          <div className="col-span-1">
                            <Currency
                              label="Deposit available"
                              name="deposit_available"
                              id="deposit_available"
                              onChange={handleCurrencyChange}
                              variant="green"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Maximum repayment (35% of income)"
                              name="mortgage_repayment"
                              id="mortgage_repayment"
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Input
                              label="Mortgage term (years)"
                              name="mortgage_term"
                              id="mortgage_term"
                              onChange={handleChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Percentage
                              label="Mortgage interest rate"
                              name="mortgage_interest_rate"
                              id="mortgage_interest_rate"
                              onChange={handleChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Maximum mortgage"
                              name="maximum_mortgage"
                              id="maximum_mortgage"
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Maximum home value"
                              name="maximum_home_value"
                              id="maximum_home_value"
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-1">
                      <div className={`flex items-center bg-[#C3CBBE]`}>
                        <p className="text-[14px] font-bold p-2">Initial costs</p>
                        <span className="ml-4 cursor-pointer">
                          <VisibilityToggle
                            keyName="initial_costs"
                            isVisible={isVisible}
                            toggleVisibility={toggleVisibility}
                          />
                        </span>
                      </div>
                      {isVisible("initial_costs") && (
                        <div className="grid grid-cols-2 gap-3 items-end p-4">
                          <div className="col-span-1">
                            <Currency
                              label="Transfer duties"
                              name="transfer_duties"
                              id="transfer_duties"
                              onChange={handleCurrencyChange}
                              variant="red"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Legal fees - transfer costs"
                              name="legal_fees"
                              id="legal_fees"
                              onChange={handleCurrencyChange}
                              variant="red"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Legal fees - bond registration"
                              name="legal_fees"
                              id="legal_fees"
                              onChange={handleCurrencyChange}
                              variant="red"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Other purchase costs"
                              name="other_purchase_costs"
                              id="other_purchase_costs"
                              onChange={handleCurrencyChange}
                              variant="green"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Total initial costs"
                              name="total_initial_costs"
                              id="total_initial_costs"
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="mb-1">
                      <div className={`flex items-center bg-[#C3CBBE]`}>
                        <p className="text-[14px] font-bold p-2">Asset preservation & cost of ownership</p>
                        <span className="ml-4 cursor-pointer">
                          <VisibilityToggle
                            keyName="asset_preservation"
                            isVisible={isVisible}
                            toggleVisibility={toggleVisibility}
                          />
                        </span>
                      </div>
                      {isVisible("asset_preservation") && (
                        <div className="grid grid-cols-2 gap-3 items-end p-4">
                          <div className="col-span-1">
                            <Currency
                              label="Monthly insurance"
                              name="monthly_insurance"
                              id="monthly_insurance"
                              onChange={handleCurrencyChange}
                              variant="red"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Monthly maintenance"
                              name="monthly_maintenance"
                              id="monthly_maintenance"
                              onChange={handleCurrencyChange}
                              variant="red"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Monthly rates"
                              name="monthly_rates"
                              id="monthly_rates"
                              onChange={handleCurrencyChange}
                              variant="green"
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <PercentageSwitch
                              label={`${capitalizeFirstLetter(formData.primary_representation)} service escalation`}
                              primaryRepresentation={formData.primary_representation}
                              // realField="real_service_escalation"
                              nominalField="nominal_service_escalation"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Total initial monthly costs"
                              name="total_initial_monthly_costs"
                              id="total_initial_monthly_costs"
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              allowNegativeValue={true}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div>
                      <div className={`flex items-center bg-[#C3CBBE]`}>
                        <p className="text-[14px] font-bold p-2">Liquidation costs</p>
                        <span className="ml-4 cursor-pointer">
                          <VisibilityToggle
                            keyName="liquidation_costs"
                            isVisible={isVisible}
                            toggleVisibility={toggleVisibility}
                          />
                        </span>
                      </div>
                      {isVisible("liquidation_costs") && (
                        <div className="grid grid-cols-2 gap-3 items-end p-4">
                          <div className="col-span-1">
                            <Percentage
                              label="Estate agent commission"
                              name="estate_agent_commission"
                              id="estate_agent_commission"
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Percentage
                              label="Legal fees & certifications"
                              name="legal_fees"
                              id="legal_fees"
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Percentage
                              label="Marginal tax rate"
                              name="marginal_tax_rate"
                              id="marginal_tax_rate"
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Percentage
                              label="CGT inclusion"
                              name="cgt_inclusion"
                              id="cgt_inclusion"
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                          <div className="col-span-1">
                            <Currency
                              label="Primary home exclusion"
                              name="primary_home_exclusion"
                              id="primary_home_exclusion"
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Card variant="white">
                  <div className=" mb-4">
                    <h3 className="text-[16px] font-semibold">Output</h3>
                    {/* <p className="text-[12px] font-semibold mt-2">Some text goes here...</p> */}
                  </div>
                  <table className="table-auto w-full border border-[#999]">
                    <thead>
                      <tr className="bg-[#f2b443]">
                        <th className="align-middle text-[12px] text-left font-bold p-2 border-r border-l border-solid border-[#999]">
                          Description
                        </th>
                        <th className="align-middle text-[12px] font-bold p-2">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-l border-solid border-[#999]">
                          Total downpayments
                        </td>
                        <td className="bg-[#f2e1c3] align-middle text-[12px] text-center font-semibold p-2  border-b border-l border-solid border-[#999]">
                          R{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-l border-solid border-[#999]">
                          Initial monthly cost of ownership
                        </td>
                        <td className="bg-[#f2e1c3] align-middle text-[12px] text-center font-semibold p-2 border-b border-l border-solid border-[#999]">
                          R{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>

            <div className="col-span-4">
              <Card
                variant="white"
                className="h-full"
              >
                <p className="mr-8 font-bold text-[16px] text-center mb-4">
                  Property - {capitalizeFirstLetter(formData.primary_representation)} Cost Of Ownership
                </p>
                <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                  {chartSeries && (
                    <Chart
                      series={chartSeries}
                      options={chartOptionsDefault}
                      type="line"
                      width="100%"
                      height="100%"
                      className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default BuyVsRentToolTwo;
