export default function DontShowAgain() {
  return (
    <>
      <div>
        <div className="mx-auto text-center">
          <h2 className="text-2xl tracking-tight font-extrabold text-gray-900 text-center mb-6">Welcome text</h2>
          <p className="mb-6 mt-3 text-md">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse venenatis mi et lorem suscipit, eu
            pharetra leo dictum. Duis a orci porttitor, viverra orci et, dictum massa. Suspendisse euismod a est id
            ornare. Nunc tempor dolor et lectus gravida, ut tempor lectus dignissim. Proin viverra auctor velit quis
            auctor. Vivamus nec malesuada dolor. Praesent non consectetur eros, ac luctus justo. Fusce mollis vestibulum
            ex, sed dictum nulla egestas luctus.
          </p>
        </div>
        <h3 className="text-md font-medium mb-4">
          <div className="flex items-center justify-center mb-4">
            <input
              id="welcome-hide"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
            />
            <label
              htmlFor="welcome-hide"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </h3>
      </div>
    </>
  );
}
