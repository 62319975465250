import Navbar from "@components/Layout/Navbar";
import HelpModal from "@modals/HelpModal";
import Modal from "@modals/Modal";
import StandardOption from "@modals/StandardOption";
import { useState } from "react";

const DashboardLayout = ({ children, pageTitle, modalContent, showBackButton }) => {
  // Help modal
  const [helpModal, setHelpModal] = useState(false);
  const ToggleHelpModal = () => setHelpModal(!helpModal);
  const closeHelpModal = () => setHelpModal(false);

  // Modal
  const [modal, setModal] = useState(false);
  const ToggleModal = () => setModal(!modal);
  const closeModal = () => setModal(false);

  return (
    <div>
      <Navbar
        pageTitle={pageTitle}
        modalContent={modalContent}
        toggleHelpModal={ToggleHelpModal}
        toggleModal={ToggleModal}
        showBackButton={showBackButton}
      />
      <div className="pt-[70px] min-h-[100vh]">
        {children}
        <HelpModal
          show={helpModal}
          closeHelpModal={closeHelpModal}
          modalContent={modalContent}
        />
        <Modal
          show={modal}
          closeModal={closeModal}
          modalContent={<StandardOption />}
        />
      </div>
    </div>
  );
};

export default DashboardLayout;
