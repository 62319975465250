import words from "@/services/algorithms/words";
import useEducationCostTool from "@api/Standard/Lifestyle/useEducationCostTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { IoMdPersonAdd } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

const EducationCost = () => {
  const { isVisible, toggleVisibility } = useVisibility();
  const { capitalizeFirstLetter } = words();

  const {
    loading,
    formData,
    handleSubmit,
    handleChange,
    handleCurrencyChange,
    handleDependantPhaseChange,
    handleDependantPhaseCurrencyChange,
    handlePrimaryRepresentationChange,
    chartSeries,
    chartOptions,
  } = useEducationCostTool();

  const [showHelpPopup, setShowHelpPopup] = useState(
    () => localStorage.getItem("education-cost-help-popup") === "true"
  );

  const HelpText = () => {
    return (
      <div className="text-[12px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This is a tool designed to show the present value (i.e. todays' money terms) of the cost of educating a
              dependant through multiple stages of schooling (including tertiary study).
            </li>
            <li className="mb-2">
              We provide the option to store multiple dependant plans under a single client ID, and aggregate as
              required
            </li>
            <li className="mb-2">
              Inputs are standardized and assist the client in determining when (i.e. future year) expenditure will take
              place, the amount of expenditure per schooling year (defaults are available) and the number of years of
              expenditure.
            </li>
            <li className="mb-2">
              Cost per year is dependent upon a <strong>"base year"</strong> i.e. the year that determines all reference
              costs. This allows the client to input his selected school costs as they are currently published by grade.
              The tool will automatically adjust these current costs for the dependant's future education year by the
              input education inflation rate.
            </li>
            <li className="mb-2">
              Goal is to indicate how much must be saved monthly per dependant to cover their full costs until the last
              year of study, offset by current savings to date. The client has the ability to remove certain education
              periods as required.
            </li>
            <li className="mb-2">
              Note that education inflation is significantly higher than normal inflation and, consequently, all
              representations assume increasing savings every year i.e. adjusted by prevailing CPI.
            </li>
            <li className="mb-2">
              Note the opportunity to insert a specific return on investment (or can use default as per standard)
            </li>
          </ul>
        </div>
        <div className="text-[12px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("education-cost-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const [tabs, setTabs] = useState([
    {
      label: "Dependent",
    },
  ]);

  const addDependent = () => {
    setTabs([
      ...tabs,
      {
        label: "Dependent",
      },
    ]);
  };

  const TabContent = () => {
    return (
      <>
        <div className="col-span-3">
          <div className="max-h-[calc(100vh-235px)] scrollbar overflow-y-scroll overflow-x-hidden">
            <Card
              variant="white"
              className="mb-4"
            >
              <div className={`flex items-center mb-4`}>
                <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                <span className="ml-4 cursor-pointer">
                  <VisibilityToggle
                    keyName="GenericTable"
                    isVisible={isVisible}
                    toggleVisibility={toggleVisibility}
                  />
                </span>
              </div>
              <div className="grid grid-cols-2 gap-3 items-end">
                <div className="col-span-1">
                  <Input
                    label="Date of analysis"
                    name="effective_date"
                    id="effective_date"
                    type="number"
                    value={formData.effective_date}
                    onChange={handleChange}
                    variant="red"
                    twMergeClassName={twMerge(`text-center`)}
                  />
                </div>
                {isVisible("GenericTable") && (
                  <>
                    <div className="col-span-1">
                      <Percentage
                        label="CPI inflation"
                        name="projected_inflation_rate"
                        id="projected_inflation_rate"
                        value={formData.projected_inflation_rate}
                        onChange={handleCurrencyChange}
                        variant="red"
                        twMergeClassName={twMerge(`text-center`)}
                      />
                    </div>
                    <div className="col-span-1">
                      <Percentage
                        label="Education inflation"
                        name="education_inflation"
                        id="education_inflation"
                        value={formData.education_inflation}
                        onChange={handleCurrencyChange}
                        variant="green"
                        twMergeClassName={twMerge(`text-center`)}
                      />
                    </div>
                    <div className="col-span-1">
                      <Percentage
                        label="Projected net return on investment"
                        name="investment_return"
                        id="investment_return"
                        value={formData.investment_return}
                        onChange={handleCurrencyChange}
                        variant="red"
                        twMergeClassName={twMerge(`text-center`)}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <div className={`flex items-center mb-4`}>
                <h3 className="text-[16px] font-semibold">Description</h3>
              </div>
              <div className="grid grid-cols-2 gap-3 items-end">
                <div className="col-span-1">
                  <Input
                    label="Education plan"
                    name=""
                    id=""
                    type="text"
                    onChange={handleChange}
                    variant="green"
                    twMergeClassName={twMerge(`text-center`)}
                    tooltipText="Insert name of dependent"
                  />
                </div>
                <div className="col-span-1">
                  <Input
                    label="Base cost year"
                    name=""
                    id=""
                    type="number"
                    onChange={handleChange}
                    variant="green"
                    twMergeClassName={twMerge(`text-center`)}
                    tooltipText="Insert year on which costs are based"
                  />
                </div>
                <div className="col-span-1">
                  <Currency
                    label="Current savings"
                    name=""
                    id=""
                    onChange={handleCurrencyChange}
                    variant="green"
                    twMergeClassName={twMerge(`text-center`)}
                  />
                </div>
                <div className="col-span-1">
                  <Currency
                    label="Current monthly contributions"
                    name=""
                    id=""
                    onChange={handleCurrencyChange}
                    variant="green"
                    twMergeClassName={twMerge(`text-center`)}
                  />
                </div>
              </div>
            </Card>

            <Card
              variant="white"
              className="mb-4"
            >
              <div className="scrollbar overflow-x-scroll pb-4">
                <table className="table-auto w-full">
                  <thead className="bg-[#ffffff]">
                    <tr className="whitespace-nowrap bg-[#f1f1f1]">
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999] min-w-[180px]">
                        Phase
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        Start age
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        Start year
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        Study period (years)
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        Cost per year (base year)
                      </th>
                      <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                        Include?
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        Pre-primary
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Currency
                          onChange={handleCurrencyChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <select>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        Primary - 1st stage
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Currency
                          onChange={handleCurrencyChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <select>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        Primary - 2nd stage
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Currency
                          onChange={handleCurrencyChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <select>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        Secondary - initial stage
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Currency
                          onChange={handleCurrencyChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <select>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        Secondary - final stage
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Currency
                          onChange={handleCurrencyChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <select>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        Tertiary - bachelor stage
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Currency
                          onChange={handleCurrencyChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <select>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        Tertiary - postgraduate stage
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Input
                          type="number"
                          onChange={handleChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <Currency
                          onChange={handleCurrencyChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                        <select>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>

            <Card variant="white">
              <div className=" mb-4">
                <h3 className="text-[16px] font-semibold">Output</h3>
              </div>
              <table className="table-auto w-full border border-[#dddddd]">
                <tbody>
                  <tr>
                    <td className="border-b border-r border-[#dddddd] text-[12px] font-semibold p-2">
                      Maximum years to save
                    </td>
                    <td className="bg-[#85322f] text-white border-b border-r border-[#dddddd] text-center text-[12px] font-bold p-2 tracking-[1px] min-w-[70px]">
                      {formData.years_to_save}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#dddddd] text-[12px] font-semibold p-2">
                      Present value of future expenditure
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#dddddd] text-center text-[12px] font-bold p-2 tracking-[1px]">
                      {formData.amount || 0}
                    </td>
                  </tr>
                  <tr>
                    <td className="border-b border-r border-[#dddddd] text-[12px] font-semibold p-2">
                      You need to save an additional
                    </td>
                    <td className="bg-[#f2e1c3] border-b border-r border-[#dddddd] text-center text-[12px] font-bold p-2 ">
                      {formData.required_multiple_low}
                    </td>
                    <td>
                      <p className="text-[12px] font-semibold m-0 pl-2">
                        Per month, increasing annually with inflation.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              {tabs.length < 8 && (
                <Button
                  name="Add a dependent"
                  className="mt-4"
                  onClick={() => {
                    addDependent();
                  }}
                />
              )}
            </Card>
          </div>
        </div>

        <div className="col-span-3">
          <Card
            variant="white"
            className="h-full"
          >
            <p className="mr-8 font-bold text-[16px] text-center mb-4">
              {capitalizeFirstLetter(formData.primary_representation)} projected education savings & expenditure
            </p>
            {loading ? (
              <ChartLoading />
            ) : (
              <div className="max-h-[calc(100vh-370px)] h-[calc(100%-43px)]">
                {chartSeries && (
                  <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="line"
                    width="100%"
                    height="100%"
                    className="max-h-[calc(100vh-370px)_!important] h-[calc(100%-43px)]"
                    fontFamily="Helvetica, Arial, sans-serif"
                  />
                )}
              </div>
            )}
          </Card>
        </div>
      </>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Real cost of education"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Education Cost" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-6 gap-4 w-full p-4">
          <div className="col-span-6">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>

          <div className="col-span-6">
            <ul className="hidden text-md text-center rounded-lg shadow sm:flex w-full">
              {tabs.map((item, index) => (
                <li
                  key={index}
                  className="w-full"
                  onClick={() => {
                    setSelectedTab(index);
                  }}
                >
                  <span
                    className={`${index === selectedTab ? "bg-gray-200 font-bold" : "bg-white"} hover:bg-gray-200 hover:font-bold inline-block w-full p-4 text-gray-900 cursor-pointer`}
                  >
                    {item.label} #{index + 1}
                  </span>
                </li>
              ))}
            </ul>
          </div>

          {TabContent()}
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default EducationCost;
