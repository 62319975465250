import useGeneralInformation from "@api/User/useGeneralInformation";
import Button from "@form/Button";
import Currency from "@form/Currency";
import Error from "@form/Error";
import Input from "@form/Input";
import Select from "@form/Select";

const GeneralInformation = () => {
  const { formData, handleChange, handleCurrencyChange, handleSubmit, errors, employmentStatuses, loading } =
    useGeneralInformation();

  return (
    <>
      <h3 className="mb-4 text-xl font-semibold">General information</h3>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-2">
              <Input
                label="Effective Date of analysis"
                type="date"
                name="effective_date"
                id="effective_date"
                value={formData.effective_date}
                onChange={handleChange}
                variant="white"
              />
            </div>
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Select
              label="Employment Status"
              defaultItem="Select Employment Status"
              id="employment_status_id"
              name="employment_status_id"
              value={formData.employment_status_id}
              onChange={handleChange}
              items={employmentStatuses}
            />
            <Error error={errors && errors.employment_status_id} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Currency
              label="Annual pre-tax income"
              name="annual_pre_tax_income"
              id="annual_pre_tax_income"
              value={formData.annual_pre_tax_income}
              onChange={handleCurrencyChange}
              variant="white"
            />
            <Error error={errors && errors.annual_pre_tax_income} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Currency
              label="Annual Bonus"
              name="annual_bonus"
              id="annual_bonus"
              value={formData.annual_bonus}
              onChange={handleCurrencyChange}
              variant="white"
            />
            <Error error={errors && errors.annual_bonus} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Age at analysis date"
              type="text"
              name="current_age"
              id="current_age"
              disabled
              value={formData.current_age}
              variant="white"
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Projected Retirement Date"
              type="date"
              name="projected_retirement_date"
              id="projected_retirement_date"
              disabled
              value={formData.projected_retirement_date}
              onChange={handleChange}
              variant="white"
            />
            <Error error={errors && errors.projected_retirement_date} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Formal Retirement age"
              type="number"
              name="retirement_age"
              id="retirement_age"
              value={formData.retirement_age}
              onChange={handleChange}
              variant="white"
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Years to retirement"
              type="text"
              name="years_to_retirement"
              id="retirement_age"
              disabled
              value={formData.years_to_retirement}
              variant="white"
            />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Life expectancy"
              type="number"
              name="life_expectancy"
              id="life_expectancy"
              disabled
              value={formData.life_expectancy}
              variant="white"
            />
          </div>
          <div className="col-span-6 sm:col-full">
            <Button
              type="submit"
              name="Save"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default GeneralInformation;
