const InvestmentApproach = () => {
  return (
    <div className="text-sm max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-scroll overflow-x-hidden py-4">
      <p className="font-medium mb-4">
        <span className="font-bold">1. </span>
        {`The act of wealth creation is one of mindset.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">2. Warren Buffet: </span>
        {`“compound interest is the 8th wonder of the world”`}
      </p>

      <p className="font-medium">
        <span className="font-bold">3. Dan Lok: </span>
        {`differences between poor & rich people:`}
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-[upper-alpha]">
        <li>
          <p>Poor people watch TV, rich people read books</p>
        </li>
        <li>
          <p>Poor people get paid on time spent, rich people get paid on results</p>
        </li>
        <li>
          <p>Poor people blame others for their misfortune, rich people take responsibility</p>
        </li>
        <li>
          <p>Poor people focus on saving, rich on investing</p>
        </li>
        <li>
          <p>Poor people believe they know it all, rich people continue learning</p>
        </li>
        <li>
          <p>Poor people have a lottery mentality, rich people have an action mentality</p>
        </li>
      </ol>

      <p className="font-medium mb-4">
        <span className="font-bold">4. Terry Pratchett: </span>
        {`“It may not be your fault, but it is your responsibility”.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">5. Warren Buffett: </span>
        {`“Poor people invest their time, rich people invest their money”.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">6. Robert Kiyosaki: </span>
        {`“Poor people add liabilities, rich people add assets”.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">7. </span>
        {`One step between achieving and never achieving financial freedom is not buying what you want directly, it is buying something that will pay for what you want.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">8. </span>
        {`Make money work for you.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">9. </span>
        {`Don’t increase your standard of living to accommodate your increased income; invest your excess net income FIRST before you consume it. `}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">10. </span>
        {`Understand the impact of inflation.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">11. Dave Ramsey: </span>
        {`“Financial peace isn’t the acquisition of stuff; it’s learning to live with less than you make”.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">12. </span>
        {`Poor people bemoan their circumstances, rich people challenge them.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">13. </span>
        {`Being rich is having money, being wealthy is having time.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">14. Robert Kiyosaki: </span>
        {`“it’s not how much money you make, but how much money you keep, how hard it works for you and how many generations you keep it for”.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">15. </span>
        There is no rule that requires you to live <u>above</u> your means
      </p>

      <p className="font-medium">
        <span className="font-bold">16. </span>
        {`Financial freedom ALWAYS requires`}
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-[upper-alpha]">
        <li>
          <p>A plan (and the earlier you start, the better)</p>
        </li>
        <li>
          <p>Deferred consumption (delayed satisfaction)</p>
        </li>
        <li>
          <p>Understanding the difference between assets and liabilities</p>
        </li>
        <li>
          <p>Creating ongoing income unrelated to your working hours</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">17. Charlie Munger life advice: </span>
      </p>
      <ol className="ml-8 space-y-2 my-2 font-medium list-[upper-alpha]">
        <li>
          <p>
            “It’s so simple: you spend less than you earn. Invest shrewdly. Avoid toxic people and toxic activities. Try
            to keep learning all your life. And do a lot of deferred gratification. If you do all these things, you are
            almost certain to succeed. And if you don’t, you’ll need a lot of luck. And you don’t want to need a lot of
            luck. You want to go into a game where you’re highly likely to win without having any unusual luck”
          </p>
        </li>
        <li>
          <p>
            “What I would say is the single most important thing, if you want to avoid all the stupid errors, is knowing
            where you're competent and where you aren't. And that's very hard to do because the human mind naturally
            tries to make you think you're way smarter than you are”
          </p>
        </li>
        <li>
          <p>“You fix what can be fixed, and what can't be fixed you endure”</p>
        </li>
      </ol>
    </div>
  );
};

export default InvestmentApproach;
