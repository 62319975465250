import Card from "@/components/Element/Card";
import useBonusImpactTool from "@api/Standard/WealthCreation/useBonusImpactTool";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import PercentageSwitch from "@form/PercentageSwitch";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";

const BonusImpact = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    handleSubmit,
    handleChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
  } = useBonusImpactTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("bonus-impact-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to assist clients in identifying how much they need to put aside on a monthly basis
              over a set horizon to achieve a target outcome. Inputs required are:
              <ul className="pl-4">
                <li className="my-1">a) Specific: horizon, initial capital, target capital (real or nominal)</li>
                <li className="my-1">
                  b) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is
              <ul className="pl-4">
                <li className="my-1">
                  a) Table of monthly contributions (savings) required over full period on a “flat” or “annually
                  increasing by inflation” basis for the 3 different growth environments.
                </li>
                <li className="my-1">
                  b) Chart shows:
                  <ul className="pl-4">
                    <li className="my-1">i) Value of cumulative contributions</li>
                    <li className="my-1">ii) Value of accumulated capital over horizon</li>
                    <li className="my-1">iii) Scale is generally monthly due to monthly nature of contributions.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("bonus-impact-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  const GrowthPreRetirementRow = () => {
    return (
      <tr>
        <td className="py-[5px] text-[12px] font-bold">Net growth pre-retirement</td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="pre_retirement_capital_growth_low"
            id="pre_retirement_capital_growth_low"
            value={formData.pre_retirement_capital_growth_low}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="pre_retirement_capital_growth_medium"
            id="pre_retirement_capital_growth_medium"
            value={formData.pre_retirement_capital_growth_medium}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="pre_retirement_capital_growth_high"
            id="pre_retirement_capital_growth_high"
            value={formData.pre_retirement_capital_growth_high}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
      </tr>
    );
  };

  const GrowthPostRetirementRow = () => {
    return (
      <tr>
        <td className="py-[5px] text-[12px] font-bold">Net growth post-retirement</td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="post_retirement_capital_growth_low"
            id="post_retirement_capital_growth_low"
            value={formData.post_retirement_capital_growth_low}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="post_retirement_capital_growth_medium"
            id="post_retirement_capital_growth_medium"
            value={formData.post_retirement_capital_growth_medium}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
        <td className="py-[5px]">
          <Percentage
            disabled
            name="post_retirement_capital_growth_high"
            id="post_retirement_capital_growth_high"
            value={formData.post_retirement_capital_growth_high}
            variant="red"
            twMergeClassName={twMerge(`text-center`)}
          />
        </td>
      </tr>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Impact of investing annual bonus on future SOL*"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Bonus Impact" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="number"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_pre_retirement_capital_growth_low"
                              nominalField="nominal_pre_retirement_capital_growth_low"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_pre_retirement_capital_growth_medium"
                              nominalField="nominal_pre_retirement_capital_growth_medium"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_pre_retirement_capital_growth_high"
                              nominalField="nominal_pre_retirement_capital_growth_high"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        {GrowthPreRetirementRow()}
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] normal-case font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth post-retirement
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_low"
                              nominalField="nominal_post_retirement_capital_growth_low"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_medium"
                              nominalField="nominal_post_retirement_capital_growth_medium"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_high"
                              nominalField="nominal_post_retirement_capital_growth_high"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        {GrowthPostRetirementRow()}
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        {GrowthPreRetirementRow()}
                        {GrowthPostRetirementRow()}
                      </tbody>
                    </>
                  )}
                </table>
              </Card>

              <div className="p-4 mb-4 bg-white border border-gray-200 rounded-md shadow-sm 2xl:col-span-2">
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Currency
                      label="Annual pre-tax income"
                      name="annual_income"
                      id="annual_income"
                      value={formData.annual_income}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <PercentageSwitch
                      label={capitalizeFirstLetter(formData.primary_representation) + " growth in income"}
                      primaryRepresentation={formData.primary_representation}
                      realField="real_growth_in_income"
                      nominalField="nominal_growth_in_income"
                      formData={formData}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      label="Annual bonus"
                      name="annual_bonus"
                      id="annual_bonus"
                      value={formData.annual_bonus}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <PercentageSwitch
                      label={capitalizeFirstLetter(formData.primary_representation) + " growth in bonus"}
                      primaryRepresentation={formData.primary_representation}
                      realField="real_growth_in_bonus"
                      nominalField="nominal_growth_in_bonus"
                      formData={formData}
                      onChange={handleCurrencyChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      label="Current accumulated retirement fund value"
                      name="current_accumulated_retrement_fund_value"
                      id="current_accumulated_retrement_fund_value"
                      value={formData.current_accumulated_retrement_fund_value}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      label="Current monthly contribution"
                      name="current_monthly_contribution"
                      id="current_monthly_contribution"
                      value={formData.current_monthly_contribution}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Percentage
                      label="Target income on retirement (% of pre-retirement)"
                      name="post_retirement_income_percentage"
                      id="post_retirement_income_percentage"
                      value={formData.post_retirement_income_percentage}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Percentage
                      label="Target % of bonus contribution"
                      name="post_retirement_bonus_percentage"
                      id="post_retirement_bonus_percentage"
                      value={formData.post_retirement_bonus_percentage}
                      onChange={handleCurrencyChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
              </div>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                </div>
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <Currency
                      label="Target income at retirement:"
                      onChange={handleCurrencyChange}
                      value={formData.target_income}
                      variant="red"
                      disabled
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div></div>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Description</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Required multiple
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_value_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_value_medium}
                      </td>
                      <td className="bg-[rgb(242,225,195)] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_value_high}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Required value
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.projected_value_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.projected_value_medium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.projected_value_high}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Total projected capital:
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        * from initial capital
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_initial_capital_low}%
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_initial_capital_medium}%
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_initial_capital_high}%
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        * from contributions
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_retirement_contributions_low}%
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_retirement_contributions_medium}%
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_retirement_contributions_high}%
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        * from bonus allocations
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_ex_bonus_allocation_low}%
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_ex_bonus_allocation_medium}%
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.required_percentage_from_ex_bonus_allocation_high}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} Capital growth & capital at work -
                pre-retirement
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                        className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default BonusImpact;
