import useHistory from "@api/User/useHistory";
import PageTitle from "@elements/PageTitle";
import DashboardLayout from "@layouts/DashboardLayout";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoLogoWebComponent } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { TbHexagons } from "react-icons/tb";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const History = () => {
  const { formData } = useHistory();

  return (
    <DashboardLayout showBackButton>
      <PageTitle title="History" />
      <div>
        <VerticalTimeline layout={"1-column-left"}>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<TbHexagons />}
          >
            <h3 className="text-[18px] font-bold">Selected System:</h3>
            <h4 className="text-[16px]">{formData.selected_system}</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#009245] opacity-[100_!important] pb-[0_!important]"
            date={formData.last_logged_in}
            iconStyle={{ background: "#009245", color: "#fff" }}
            icon={<MdLogout />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last login</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            date={formData.effective_date}
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<FaRegCalendarAlt />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last Effective Date</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            date={formData.personal}
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<IoLogoWebComponent />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last Changes To Components: Personal</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            date={formData.financial}
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<IoLogoWebComponent />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last Changes To Components: Financial Defaults</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            date={formData.retirement}
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<IoLogoWebComponent />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last Changes To Components: Retirement</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            date={formData.lifestyle}
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<IoLogoWebComponent />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last Changes To Components: Lifestyle</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            date={formData.wealth_creation}
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<IoLogoWebComponent />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last Changes To Components: Wealth Creation</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            dateClassName="font-[bold_!important] text-[#243665] opacity-[100_!important] pb-[0_!important]"
            date={formData.other}
            iconStyle={{ background: "#243665", color: "#fff" }}
            icon={<IoLogoWebComponent />}
          >
            <h3 className="text-[18px] font-bold">Last Usage Information:</h3>
            <h4 className="text-[16px]">Last Changes To Components: Other</h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </DashboardLayout>
  );
};

export default History;
