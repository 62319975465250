import Card from "@/components/Element/Card";
import useLifeInsuranceTool from "@api/Standard/Additional/useLifeInsuranceTool";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import PercentageSwitch from "@form/PercentageSwitch";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { IoCloseCircleOutline } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

const LifeInsurance = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    handleSubmit,
    handleChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
  } = useLifeInsuranceTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("life-insurance-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Minimum required life insurance will be the difference between your projected cumulative financial assets
              and amount required to sustain your beneficiaries at a specified standard of living for a given period.
            </li>
            <li className="mb-2">
              The amount of life insurance required will therefore naturally decline as your financial assets grow.
            </li>
            <li className="mb-2">
              This calculation is one of a “minimum” – we recommend a higher amount than the minimum to order to offset
              any setbacks in future asset growth.
            </li>
            <li className="mb-2">
              Inputs will include all those required to estimate the cost of your beneficiaries standard of living after
              this life event
              <ul className="pl-4">
                <li className="my-1">
                  a) Specific inputs: annual income, annual real growth in income, target support period for
                  beneficiaries and target percentage of income required for beneficiaries on the life event (note that
                  the family unit will usually require less income due to the loss of the family member and may also
                  change spending patterns)
                </li>
                <li className="my-1">
                  b) Since this is an offset calculation, we require all information pertaining to the client’s
                  financial assets including:
                  <ul className="pl-4">
                    <li className="my-1">i) Product types with accumulated capital and monthly contributions</li>
                    <li className="my-1">ii) Net growth rates by product</li>
                    <li className="my-1">iii) Terminal contributions dates by product</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mb-2">
              {`Minimum life assurance amount (sum assured) is likely to be at
              its highest point at the inception of analysis; however, we
              recommend maintaining that amount in real terms until a buffer is
              sufficiently large (capital available on life event is >30% than
              required)`}
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("life-insurance-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  const [data, setData] = useState([
    [
      {
        label: "Retirement #1",
        name: `description`,
        inputType: "description",
      },
      {
        value: "",
        name: "capital0",
        inputType: "currency",
      },
      {
        value: "",
        name: "contribution0",
        inputType: "currency",
      },
      {
        value: "",
        name: "change_in_contribution0",
        inputType: "percentage",
        disabled: true,
      },
      {
        value: "",
        name: "estimated_net_real_growth0",
        inputType: "percentage",
      },
      {
        value: "",
        name: "terminal_contribution0",
        inputType: "date",
      },
      {
        value: "",
        name: "years_remaining0",
        inputType: "input",
        disabled: true,
      },
    ],
    [
      {
        label: "Retirement #2",
        name: `description`,
        inputType: "description",
      },
      {
        value: "",
        name: "capital0",
        inputType: "currency",
      },
      {
        value: "",
        name: "contribution0",
        inputType: "input",
      },
      {
        value: "",
        name: "change_in_contribution0",
        inputType: "percentage",
        disabled: true,
      },
      {
        value: "",
        name: "estimated_net_real_growth0",
        inputType: "percentage",
      },
      {
        value: "",
        name: "terminal_contribution0",
        inputType: "date",
      },
      {
        value: "",
        name: "years_remaining0",
        inputType: "input",
        disabled: true,
      },
    ],
    [
      {
        label: "Investment #1",
        name: `description`,
        inputType: "description",
      },
      {
        value: "",
        name: "capital0",
        inputType: "currency",
      },
      {
        value: "",
        name: "contribution0",
        inputType: "input",
      },
      {
        value: "",
        name: "change_in_contribution0",
        inputType: "percentage",
        disabled: true,
      },
      {
        value: "",
        name: "estimated_net_real_growth0",
        inputType: "percentage",
      },
      {
        value: "",
        name: "terminal_contribution0",
        inputType: "date",
      },
      {
        value: "",
        name: "years_remaining0",
        inputType: "input",
        disabled: true,
      },
    ],
    [
      {
        label: "Investment #2",
        name: `description`,
        inputType: "description",
      },
      {
        value: "",
        name: "capital0",
        inputType: "currency",
      },
      {
        value: "",
        name: "contribution0",
        inputType: "input",
      },
      {
        value: "",
        name: "change_in_contribution0",
        inputType: "percentage",
        disabled: true,
      },
      {
        value: "",
        name: "estimated_net_real_growth0",
        inputType: "percentage",
      },
      {
        value: "",
        name: "terminal_contribution0",
        inputType: "date",
      },
      {
        value: "",
        name: "years_remaining0",
        inputType: "input",
        disabled: true,
      },
    ],
  ]);

  const handleOnChange = (e) => {
    const newData = data.map((d, i) => {
      if (i === row) {
        d[col].value = e.target.value;
      }

      return d;
    });
    setData(newData);
  };

  // const addRow = () => {
  //   setData([
  //     ...data,
  //     [
  //       {
  //         label: "Retirement #",
  //         name: `description`,
  //         inputType: "description",
  //       },
  //       {
  //         value: "",
  //         name: `age_from${data.length}`,
  //         inputType: "input",
  //       },
  //       {
  //         value: "",
  //         name: `age_to${data.length}`,
  //         inputType: "input",
  //       },
  //       {
  //         value: "",
  //         name: `period${data.length}`,
  //         inputType: "input",
  //         disabled: true,
  //       },
  //       {
  //         value: "",
  //         name: `real_salary_growth${data.length}`,
  //         inputType: "percentage",
  //       },
  //       {
  //         value: "",
  //         name: `salary_contributions${data.length}`,
  //         inputType: "percentage",
  //       },
  //     ],
  //   ]);
  // };

  // const removeRow = (index) => {
  //   const _data = [...data];
  //   _data.splice(index, 1);
  //   setData(_data);
  // };

  const FinancialAssetsRow = ({ data, handleOnChange, addRow, removeRow }) => {
    return (
      <>
        {data.map((items, i1) => (
          <>
            <tr>
              {items.map((item, i2) => (
                <>
                  <td
                    key={i2}
                    className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]"
                  >
                    {item.inputType === "description" ? (
                      <div className="flex items-center leading-[normal] font-bold">
                        {/* {data.length > 1 && (
                          <IoCloseCircleOutline
                            onClick={() => removeRow(i1)}
                            color="red"
                            className="mr-1 cursor-pointer"
                          />
                        )} */}
                        {item.label}
                        {/* {i1 + 1} */}
                      </div>
                    ) : item.inputType === "input" ? (
                      <Input
                        type="number"
                        name=""
                        onChange={(e) => handleOnChange(e, i1, i2)}
                        variant={item.disabled ? "red" : "green"}
                        disabled={item.disabled}
                        twMergeClassName={twMerge(`text-center `)}
                      />
                    ) : item.inputType === "currency" ? (
                      <Currency
                        name=""
                        onChange={(e) => handleOnChange(e, i1, i2)}
                        variant={item.disabled ? "red" : "green"}
                        disabled={item.disabled}
                        twMergeClassName={twMerge(`text-center `)}
                      />
                    ) : item.inputType === "percentage" ? (
                      <Percentage
                        name=""
                        onChange={(e) => handleOnChange(e, i1, i2)}
                        variant={item.disabled ? "red" : "green"}
                        disabled={item.disabled}
                        twMergeClassName={twMerge(`text-center `)}
                      />
                    ) : item.inputType === "date" ? (
                      <Input
                        type="date"
                        name=""
                        onChange={(e) => handleOnChange(e, i1, i2)}
                        variant={item.disabled ? "red" : "green"}
                        disabled={item.disabled}
                        twMergeClassName={twMerge(`text-center `)}
                      />
                    ) : (
                      <></>
                    )}
                  </td>
                </>
              ))}
            </tr>

            {i1 === data.length - 1 && (
              <>
                <tr>
                  <td className="p-2 text-[12px] font-bold border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    Other
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Currency
                      name=""
                      id=""
                      variant="green"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 text-[12px] font-bold text-center border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Currency
                      name=""
                      id=""
                      variant="green"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Percentage
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Percentage
                      name=""
                      id=""
                      variant="green"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      type="date"
                      name=""
                      id=""
                      variant="green"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      name=""
                      id=""
                      disabled="disabled"
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="p-2 text-[12px] font-bold border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    TOTAL
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Currency
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 text-[12px] font-bold text-center border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]"></td>
                </tr>
                {/* <tr>
                  <td colSpan={7}>
                    <Button
                      onClick={addRow}
                      name="Add?"
                      className="mt-4 mb-2"
                    />
                  </td>
                </tr> */}
              </>
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="How much life insurance do I need?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Life Insurance" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="number"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-2 pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>
                          <td className="p-2">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_low"
                              nominalField="nominal_post_retirement_capital_growth_low"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_medium"
                              nominalField="nominal_post_retirement_capital_growth_medium"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_high"
                              nominalField="nominal_post_retirement_capital_growth_high"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2 text-[12px] font-bold">Annual costs</td>
                          <td className="p-2">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-[12px]">Low</th>
                        <th className="text-[12px]">Medium</th>
                        <th className="text-[12px]">High</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="p-2 text-[12px] font-bold">Net growth pre-retirement</td>
                      <td className="p-2">
                        <Percentage
                          name="netGrowthLow"
                          id="netGrowthLow"
                          disabled
                          value={formData.net_growth_low}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="p-2">
                        <Percentage
                          name="netGrowthMedium"
                          id="netGrowthMedium"
                          disabled
                          value={formData.net_growth_medium}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="p-2">
                        <Percentage
                          name="netGrowthHigh"
                          id="netGrowthHigh"
                          disabled
                          value={formData.net_growth_high}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Currency
                      label="Annual pre-tax income"
                      name="annual_pre_tax_income"
                      id="annual_pre_tax_income"
                      value={formData.annual_pre_tax_income}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <PercentageSwitch
                      label={capitalizeFirstLetter(formData.primary_representation) + " growth in income"}
                      primaryRepresentation={formData.primary_representation}
                      realField="real_growth_in_income"
                      nominalField="nominal_growth_in_income"
                      formData={formData}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Percentage
                      label="Target income on life event (% of pre-retirement)"
                      name=""
                      id=""
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Input
                      label="Target support period post event"
                      name=""
                      id=""
                      type="number"
                      onChange={handleChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Percentage
                      label="Net financial asset growth post event"
                      name=""
                      id=""
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">
                    {formData.primary_representation === "nominal"
                      ? "Contribution & income growth map"
                      : "Finanacial assets & contributions"}
                  </h3>
                </div>
                <div className="scrollbar overflow-x-scroll">
                  <table className="table-auto w-full">
                    <thead className="bg-[#ffffff]">
                      <tr className="whitespace-nowrap bg-[#f1f1f1]">
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Financial assets
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Capital
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Contribution (pm)
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Percentage change in contribution
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Estimated net real growth*
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Terminal contribution date
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Years remaining
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <FinancialAssetsRow
                        data={data}
                        // addRow={addRow}
                        // removeRow={removeRow}
                        handleOnChange={handleOnChange}
                      />
                    </tbody>
                  </table>
                </div>
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                </div>
                <div className="flex items-center gap-2">
                  <div>
                    <Currency
                      disabled
                      label="Current required Sum assured"
                      onChange={handleCurrencyChange}
                      value={formData.target_income}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <p className="text-xs font-bold mt-5">increasing annually by inflation</p>
                </div>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">Placeholder text</p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                        className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default LifeInsurance;
