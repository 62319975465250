import { InputHTMLAttributes } from "react";

interface InputElementProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  disabled?: boolean;
  selectedOption: String;
}

const RadioButton = ({ label, id, disabled = false, selectedOption, ...rest }: InputElementProps) => {
  return (
    <div className="mb-4">
      <input
        type="radio"
        id={id}
        disabled={disabled}
        {...rest}
        className="hidden"
      />
      <label
        htmlFor={id}
        className={`${selectedOption === label ? "bg-[#243665] text-white hover:bg-[#243665] " : "bg-white text-[#243665] hover:text-gray-600 hover:bg-gray-200"} inline-flex items-center justify-between w-full p-5   border border-[#243665] rounded-lg cursor-pointer `}
      >
        <div className="block">
          <div className="w-full text-lg font-semibold">{label}</div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3.5 w-3.5"
          viewBox="0 0 16 16"
          fill="currentColor"
        >
          <circle
            data-name="ellipse"
            cx="8"
            cy="8"
            r="8"
          ></circle>
        </svg>
      </label>
    </div>
  );
};

export default RadioButton;
