const helpers = () => {
  const convertChartSeries = (chartSeries) => {
    return chartSeries.map((obj) => ({
      name: obj.name,
      data: Object.entries(obj.data).map(([key, value]) => ({
        x: key,
        y: value.toFixed(2),
      })),
    }));
  };

  const chartOptionsDefault = {
    chart: {
      type: "line",
      height: 500,
      width: null,
      toolbar: {
        show: false,
      },
      tooltip: {
        style: {
          fontFamily: "Nunito Sans",
          fontWeight: "bold",
        },
      },
      stacked: false,
      zoom: {
        enabled: true,
      },
      fontFamily: "Nunito Sans",
      fontWeight: "bold",
      foreColor: "#373d3f",
    },

    plotOptions: {
      bar: {
        horizontal: false,
      },
    },

    colors: [
      "#008FFB",
      "#00E396",
      "#feb019",
      "#ff455f",
      "#775dd0",
      "#80effe",
      "#0077B5",
      "#ff6384",
      "#c9cbcf",
      "#0057ff",
      "#00a9f4",
      "#2ccdc9",
      "#5e72e4",
    ],

    series: [],

    dataLabels: {
      enabled: false,
    },

    title: {
      text: "",
    },

    yaxis: {
      labels: {
        formatter: function (value) {
          return "R" + value.toLocaleString("fr");
        },
      },
    },

    xaxis: {
      labels: {
        rotate: -90,
      },
      categories: [],
    },

    grid: {
      show: true,
    },

    markers: {
      size: 4,
      colors: ["transparent"],
      strokeColors: "#fff",
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },

    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
  };

  const preventEnterPress = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault();
      return;
    }
  };

  return {
    convertChartSeries,
    chartOptionsDefault,
    preventEnterPress,
  };
};

export default helpers;
