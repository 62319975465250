import { Route, Routes } from "react-router-dom";
/* Index */
import StandardIndex from "@pages/Standard";
/* Additional */
import Budgeting from "@pages/Standard/Additional/Budgeting";
import LifeInsurance from "@pages/Standard/Additional/LifeInsurance";
import SolarEnergy from "@pages/Standard/Additional/SolarEnergy";
import TwoPotRetirement from "@pages/Standard/Additional/TwoPotRetirement";
/* Lifestyle */
import BuyVsRent from "@pages/Standard/Lifestyle/BuyVsRent";
import EducationCost from "@pages/Standard/Lifestyle/EducationCost";
import LoanAffordability from "@pages/Standard/Lifestyle/LoanAffordability";
import VehicleCost from "@pages/Standard/Lifestyle/VehicleCost";
/* Retirement */
import MoneyGrowth from "@pages/Standard/Retirement/MoneyGrowth";
import MoneyLast from "@pages/Standard/Retirement/MoneyLast";
import RetirementNeed from "@pages/Standard/Retirement/RetirementNeed";
/* WealthCreation */
import BonusImpact from "@pages/Standard/WealthCreation/BonusImpact";
import GoalInvesting from "@pages/Standard/WealthCreation/GoalInvesting";
import TargetedInvestments from "@pages/Standard/WealthCreation/TargetedInvestments";
import TaxBenefits from "@pages/Standard/WealthCreation/TaxBenefits";
import GapAnalysis from "../pages/Standard/Retirement/GapAnalysis";

function StandardRoutes ()
{
  return (

    <Routes>

      <Route
        path="standard"
        element={ <StandardIndex /> } />

      <Route path="/standard/retirement">
        <Route
          path="retirement-need"
          element={ <RetirementNeed /> } />
        <Route
          path="money-growth"
          element={ <MoneyGrowth /> } />
        <Route
          path="money-last"
          element={ <MoneyLast /> } />
        <Route
          path="gap-analysis"
          element={ <GapAnalysis /> } />
      </Route>

      <Route path="/standard/lifestyle">
        <Route
          path="vehicle-cost"
          element={ <VehicleCost /> } />
        <Route
          path="buy-vs-rent"
          element={ <BuyVsRent /> } />
        <Route
          path="education-cost"
          element={ <EducationCost /> } />
        <Route
          path="loan-affordability"
          element={ <LoanAffordability /> } />
      </Route>

      <Route path="/standard/wealth-creation">
        <Route
          path="goal-investing"
          element={ <GoalInvesting /> } />
        <Route
          path="bonus-impact"
          element={ <BonusImpact /> } />
        <Route
          path="tax-benefits"
          element={ <TaxBenefits /> } />
        <Route
          path="targeted-investments"
          element={ <TargetedInvestments /> } />
      </Route>

      <Route path="/standard/additional">
        <Route
          path="budgeting"
          element={ <Budgeting /> } />
        <Route
          path="solar-energy"
          element={ <SolarEnergy /> } />
        <Route
          path="two-pot-retirement"
          element={ <TwoPotRetirement /> } />
        <Route
          path="life-insurance"
          element={ <LifeInsurance /> } />
      </Route>


    </Routes>
  );
}

export default StandardRoutes;
