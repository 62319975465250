import createAxiosInstance from "@api/Api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import growthRateHandler from "@services/algorithms/growthRateHandler";

const useFinancialSettings = () => {
  const api = createAxiosInstance("user/financial-settings");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [currencies, setCurrencies] = useState([]);
  const [taxDomiciles, setTaxDomiciles] = useState([]);
  const [primaryRepresentations, setPrimaryRepresentations] = useState([]);

  const [formData, setFormData] = useState({
    currency_id: "",
    tax_domicile_id: "",
    primary_representation_id: 1,
    projected_inflation_rate: "",
    pre_retirement_costs: "",
    post_retirement_costs: "",
    real_pre_retirement_capital_growth_low: "",
    real_pre_retirement_capital_growth_medium: "",
    real_pre_retirement_capital_growth_high: "",
    real_post_retirement_capital_growth_low: "",
    real_post_retirement_capital_growth_medium: "",
    real_post_retirement_capital_growth_high: "",
    nominal_pre_retirement_capital_growth_low: "",
    nominal_pre_retirement_capital_growth_medium: "",
    nominal_pre_retirement_capital_growth_high: "",
    nominal_post_retirement_capital_growth_low: "",
    nominal_post_retirement_capital_growth_medium: "",
    nominal_post_retirement_capital_growth_high: "",
    default_financial_settings: false,
  });

  const { calculateNominalGrowthRate, calculateRealGrowthRate, handleNominalChange, handleRealChange } =
    growthRateHandler(formData, setFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCurrencyChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(saveFinancialSettings(formData), {
      loading: "Saving...",
      success: (data) => data.message,
      error: "Could not save.",
    });
  };

  const handleCheckboxChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      default_financial_settings: e.target.checked,
    }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    const integerValue = parseInt(value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: integerValue,
    }));
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nominal_pre_retirement_capital_growth_low: calculateNominalGrowthRate(
        formData.real_pre_retirement_capital_growth_low,
        formData.projected_inflation_rate
      ),
      nominal_pre_retirement_capital_growth_medium: calculateNominalGrowthRate(
        formData.real_pre_retirement_capital_growth_medium,
        formData.projected_inflation_rate
      ),
      nominal_pre_retirement_capital_growth_high: calculateNominalGrowthRate(
        formData.real_pre_retirement_capital_growth_high,
        formData.projected_inflation_rate
      ),
      nominal_post_retirement_capital_growth_low: calculateNominalGrowthRate(
        formData.real_post_retirement_capital_growth_low,
        formData.projected_inflation_rate
      ),
      nominal_post_retirement_capital_growth_medium: calculateNominalGrowthRate(
        formData.real_post_retirement_capital_growth_medium,
        formData.projected_inflation_rate
      ),
      nominal_post_retirement_capital_growth_high: calculateNominalGrowthRate(
        formData.real_post_retirement_capital_growth_high,
        formData.projected_inflation_rate
      ),
    }));
  }, [formData.projected_inflation_rate]);

  const getFinancialSettings = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(``, formData);

      setFormData((prevFormData) => ({
        ...prevFormData,
        currency_id: data.financial_settings.currency_id || "",
        tax_domicile_id: data.financial_settings.tax_domicile_id || "",
        primary_representation_id: data.financial_settings.primary_representation_id || "",
        projected_inflation_rate: data.financial_settings.projected_inflation_rate || "",
        default_financial_settings: data.default_financial_settings ? true : false,
        pre_retirement_costs: data.financial_settings.pre_retirement_costs || "",
        post_retirement_costs: data.financial_settings.post_retirement_costs || "",
        real_pre_retirement_capital_growth_low: data.financial_settings.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium:
          data.financial_settings.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.financial_settings.real_pre_retirement_capital_growth_high || "",
        real_post_retirement_capital_growth_low: data.financial_settings.real_post_retirement_capital_growth_low || "",
        real_post_retirement_capital_growth_medium:
          data.financial_settings.real_post_retirement_capital_growth_medium || "",
        real_post_retirement_capital_growth_high:
          data.financial_settings.real_post_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low:
          data.financial_settings.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium:
          data.financial_settings.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high:
          data.financial_settings.nominal_pre_retirement_capital_growth_high || "",
        nominal_post_retirement_capital_growth_low:
          data.financial_settings.nominal_post_retirement_capital_growth_low || "",
        nominal_post_retirement_capital_growth_medium:
          data.financial_settings.nominal_post_retirement_capital_growth_medium || "",
        nominal_post_retirement_capital_growth_high:
          data.financial_settings.nominal_post_retirement_capital_growth_high || "",
      }));

      setCurrencies(data.currencies);
      setTaxDomiciles(data.taxDomiciles);
      setPrimaryRepresentations(data.primaryRepresentations);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveFinancialSettings = async (formData) => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put(``, formData);
      return Promise.resolve(data);
    } catch (error) {
      setErrors(error.response.data.errors);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFinancialSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    errors,
    formData,
    currencies,
    taxDomiciles,
    primaryRepresentations,
    handleChange,
    handleCurrencyChange,
    handleSubmit,
    handleCheckboxChange,
    handleSelectChange,
    getFinancialSettings,
    saveFinancialSettings,
    handleNominalChange,
    handleRealChange,
  };
};

export default useFinancialSettings;
