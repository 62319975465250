import Card from "@/components/Element/Card";
import helpers from "@utils/helpers";
import { useState } from "react";
import Chart from "react-apexcharts";

const CompoundInterest = () => {
  const [chartSeries, setChartSeries] = useState([]);
  const { chartOptionsDefault } = helpers();
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  return (
    <div className="max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-scroll overflow-x-hidden pb-4">
      <div className="mx-auto text-center pt-4">
        <h2 className="text-lg tracking-tight font-bold text-gray-900 text-center mt-2 mb-6">
          What is the difference between simple and compound interest?
        </h2>
      </div>
      <div className="grid grid-cols-5 gap-6">
        <div className="col-span-3">
          <div className="flex flex-col p-2 mx-auto w-full text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
            <p className="mb-2 text-sm font-medium">
              <span className="font-bold">Simple interest</span> is calculated on the original value of the capital only
              e.g.
            </p>
            <table className="table table-auto text-sm font-semibold ml-2">
              <tr>
                <td className="pb-2">Capital amount</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>R 100,000 (“C”)
                </td>
              </tr>
              <tr>
                <td className="pb-2">Interest rate</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>10% (“r”)
                </td>
              </tr>
              <tr>
                <td className="pb-2">Value after 1 year</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>Capital + growth
                </td>
              </tr>
              <tr>
                <td className="pb-2"></td>
                <td className="pb-2">
                  <span className="mr-5"></span>
                  <u>R100,000 + 10% * (R100,000)</u> = <u>R110,000</u>
                </td>
              </tr>
              <tr>
                <td className="pb-2">Value after 2 years</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>
                  <u>R110,000 + 10% * (R100,000)</u> = <u>R120,000</u>
                </td>
              </tr>
              <tr>
                <td className="pb-2">Value after 10 years:</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>
                  <u>R120,000 + 10% * (R110,000)</u> = <u>R130,000</u>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex items-center h-full">
            <div className="text-sm">
              <p className="font-bold mb-2">
                <u>General formula</u>
              </p>
              <p>
                Value after n years: <u>C * (1 + r) * n</u>
              </p>
              <p className="my-4 text-sm font-medium">
                Interest is <u>not</u> paid on the interest earned, only on the original capital.
              </p>
            </div>
          </div>
        </div>

        <div className="col-span-3">
          <div className="flex flex-col p-2 mx-auto w-full text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
            <p className="mb-2 text-sm font-medium">
              <span className="font-bold">Compound interest </span> is calculated on both the original value of the
              capital and the interest received e.g.
            </p>
            <table className="table table-auto text-sm font-semibold ml-2">
              <tr>
                <td className="pb-2">Capital amount</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>R 100,000 (“C”)
                </td>
              </tr>
              <tr>
                <td className="pb-2">Interest rate</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>10% (“r”)
                </td>
              </tr>
              <tr>
                <td className="pb-2">Value after 1 year</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>Capital + growth
                </td>
              </tr>
              <tr>
                <td className="pb-2"></td>
                <td className="pb-2">
                  <span className="mr-5"></span>
                  <u>R100,000 + 10% * (R100,000)</u> = <u>R110,000</u>
                </td>
              </tr>
              <tr>
                <td className="pb-2">Value after 2 years</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>
                  <u>R110,000 + 10% * (R110,000)</u> = <u>R121,000</u>
                </td>
              </tr>
              <tr>
                <td className="pb-2">Value after 10 years:</td>
                <td className="pb-2">
                  <span className="mr-4">:</span>
                  <u>R121,000 + 10% * (R121,000)</u> = <u>R133,100</u>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex items-center h-full">
            <div className="text-sm">
              <p className="font-bold mb-2">
                <u>General formula</u>
              </p>
              <p>
                Value after n years: <u>C * (1 + r) * n</u>
              </p>
              <p className="my-4 text-sm font-medium">
                interest is paid on both the original capital AND on the interest earned.
              </p>
            </div>
          </div>
        </div>

        <div className="col-span-5">
          <div className="flex flex-col p-2 mx-auto w-full text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
            <p className="mb-2 text-sm font-medium">
              The <span className="font-bold">“Rule of 72” </span> is a quick approximate way to calculate how many
              years it will take for one’s capital to double at a given <span className="font-bold">compound </span>{" "}
              interest rate.
            </p>
            <p className="mb-2 text-sm font-medium">
              The <u>approximation</u> is given as : <span className="font-bold">72 / interest rate </span>
              in percentage.
            </p>
            <p className="mb-2 text-sm font-medium">By way of example:</p>
            <p className="mb-2 ml-2 text-sm font-medium">
              <span className="mr-6">Interest rate : 10%</span> Years to double capital : 72 / 10 = 7.2 years (actual
              7.27)
            </p>
            <p className="mb-2 ml-2 text-sm font-medium">
              <span className="mr-6">Interest rate : 6%</span> Years to double capital : 72 / 6 = 12.0 years (actual
              11.90)
            </p>
            <p className="mb-2 ml-2 text-sm font-medium">
              <span className="mr-6">Interest rate : 5%</span> Years to double capital : 72 / 5 = 14.4 years (actual
              14.21)
            </p>
            <p className="mb-2 text-sm font-medium">
              One can also reverse this and state the problem as “what rate of interest do I need to earn for my money
              to double after n years?”
            </p>
            <p className="mb-2 ml-2 text-sm font-medium">
              Years to double capital : 10 Required interest rate : 72 / 10 = 7.2% p.a. (actual 7.18%)
            </p>
            <p className="ml-2 text-sm font-medium">
              Years to double capital : 15 Required interest rate : 72 / 15 = 4.8% p.a. (actual 4.73%)
            </p>
          </div>
        </div>
      </div>

      <div className="mx-auto mb-6 mt-10 text-center">
        <h2 className="text-lg tracking-tight font-bold text-gray-900 text-center mt-2 mb-6">
          All investment returns are shown using compound interest?
        </h2>
      </div>
      <div>
        <Card
          variant="white"
          className="h-full"
        >
          <p className="mr-8 font-bold text-[16px] text-center mb-4">
            Time taken for money to double at different interest rates
          </p>
          <div className="">
            {chartSeries && (
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
                width="100%"
                height="100%"
                className="min-h-[40vh_!important]"
              />
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CompoundInterest;
