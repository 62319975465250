import { Children, useState } from "react";
import { useNavigate } from "react-router-dom";

type VerticalTabsProps = {
  children: React.ReactNode;
  tabIndex?: number;
};

const VerticalTabs = (props: VerticalTabsProps) => {
  const { children, tabIndex } = props;
  const navigate = useNavigate();
  const [tab, setTab] = useState(tabIndex ? tabIndex - 1 : 0);
  const childrenList = Children.toArray(children);
  const tabContent = childrenList[tab];

  const tabs = childrenList.map((child, index) => {
    const tabtitle = (child as any).props.tabtitle ?? index;
    return (
      <li
        key={tabtitle}
        onClick={() => {
          setTab(index);
          navigate(`?tab=${index + 1}`);
        }}
      >
        <div
          className={`${tab === index ? "bg-gray-500 text-white hover:bg-gray-500 hover:text-white" : "bg-gray-50 text-gray-900 border-gray-300 hover:bg-gray-500 hover:text-white"} border-gray-500 w-max min-w-full p-3  border  rounded-lg transition-all duration-100 cursor-pointer`}
        >
          <div className="flex items-center justify-between">
            <p className="font-bold text-xs">{tabtitle}</p>
          </div>
        </div>
      </li>
    );
  });

  return (
    <div className="flex h-full">
      <ol className="h-full space-y-4 pl-4 py-4">{tabs}</ol>
      <div className="w-full pl-4 pr-[6px]">{tabContent}</div>
    </div>
  );
};

export default VerticalTabs;
