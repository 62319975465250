import { BiSolidBriefcase } from 'react-icons/bi'
import { BsChatQuoteFill } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'

const Links = () => {
  return (
    <>
      <div className="flex items-center justify-center mt-8">
        <div className="w-full">
          <div className="grid grid-cols-1 gap-y-2">
            <div className="w-full inline-flex items-center justify-center p-5 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 cursor-pointer">
              <BiSolidBriefcase className="w-5 h-5 me-3" />
              <span className="w-full">
                I need to talk to a financial advisor
              </span>
              <FaArrowRight ht className="w-4 h-4 ms-2" />
            </div>

            <div className="w-full inline-flex items-center justify-center p-5 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 cursor-pointer">
              <BsChatQuoteFill className="w-5 h-5 me-3" />
              <span className="w-full">
                I need to get a life insurance quote
              </span>
              <FaArrowRight ht className="w-4 h-4 ms-2" />
            </div>

            <div className="w-full inline-flex items-center justify-center p-5 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 cursor-pointer">
              <BsChatQuoteFill className="w-5 h-5 me-3" />
              <span className="w-full">
                I need to get a short-term insurance quote
              </span>
              <FaArrowRight ht className="w-4 h-4 ms-2" />
            </div>

            <div className="w-full inline-flex items-center justify-center p-5 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 cursor-pointer">
              <BsChatQuoteFill className="w-5 h-5 me-3" />
              <span className="w-full">
                I need to compare my costs to others
              </span>
              <FaArrowRight ht className="w-4 h-4 ms-2" />
            </div>

            <div className="w-full inline-flex items-center justify-center p-5 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 cursor-pointer">
              <BsChatQuoteFill className="w-5 h-5 me-3" />
              <span className="w-full">
                Show me the latest expert commentary
              </span>
              <FaArrowRight ht className="w-4 h-4 ms-2" />
            </div>

            <div className="w-full inline-flex items-center justify-center p-5 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 cursor-pointer">
              <BsChatQuoteFill className="w-5 h-5 me-3" />
              <span className="w-full">
                Show me other system options & pricings
              </span>
              <FaArrowRight ht className="w-4 h-4 ms-2" />
            </div>

            <div className="w-full inline-flex items-center justify-center p-5 text-base font-bold text-gray-700 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 cursor-pointer">
              <BsChatQuoteFill className="w-5 h-5 me-3" />
              <span className="w-full">I need to....</span>
              <FaArrowRight ht className="w-4 h-4 ms-2" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Links
