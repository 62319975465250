import { type PropsWithChildren, useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import appContext from "@context/app-context";

export const api = axios.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  withCredentials: false,
});

function AppState({ children }: PropsWithChildren) {
  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function startApp() {
      await getToken();
    }
    startApp();
  });

  async function getToken() {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }

  async function login(email: string, password: string, remember?: boolean) {
    setIsLoading(true);
    try {
      const { data } = await api.post("/login", {
        email,
        password,
        remember,
      });

      setIsLoading(false);
      setToken(data.token);
      localStorage.setItem("token", data.token);
      navigate("/home");
    } catch (error) {
      handleAuthError(error);
    }
  }

  async function register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    termsAccepted?: boolean
  ) {
    setIsLoading(true);
    try {
      await api.post("/register", {
        email,
        password,
        name: firstName,
        surname: lastName,
        terms_accepted: termsAccepted,
      });
      setIsLoading(false);
      login(email, password);
    } catch (error) {
      handleAuthError(error);
    }
  }

  async function logout() {
    setIsLoading(true);
    try {
      await api.post("/logout");
      setUser(null);
      setToken("");
      localStorage.removeItem("token");
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      handleAuthError(error);
    }
  }

  async function forgotPassword(email: string) {
    setIsLoading(true);
    try {
      const { data } = await api.post("/forgot-password", { email });
      setIsLoading(false);
      toast.success(data.message);
    } catch (error) {
      handleAuthError(error);
    }
  }

  async function resetPassword(password: string, password_confirmation: any, token: any) {
    setIsLoading(true);
    try {
      const { data } = await api.post("/reset-password", {
        password: password,
        password_confirmation: password_confirmation,
        token: token,
      });
      setIsLoading(false);
      navigate("/");
      toast.success(data.message);
    } catch (error) {
      handleAuthError(error);
    }
  }

  function handleAuthError(error: any) {
    setIsLoading(false);
    if (error.response && error.response.data) {
      toast.error(error.response.data.error || "An error occurred");
    } else {
      toast.error("An error occurred");
    }
    if (error.response && error.response.status === 401) {
      setUser(null);
      setToken("");
      localStorage.removeItem("token");
    }
  }

  return (
    <appContext.Provider
      value={{
        user,
        token,
        login,
        register,
        logout,
        forgotPassword,
        resetPassword,
        isLoading,
      }}
    >
      {children}
    </appContext.Provider>
  );
}

export default AppState;
