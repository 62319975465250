import useHome from '@api/Home/useHome'
import { useState } from 'react'
import Costings from './Costings'
import DontShowAgain from './DontShowAgain'
import WhichSystemIsForYou from './WhichSystemIsForYou'

const Stepper = (props) => {
  const { closeModal } = props
  const { saveWelcomeHide } = useHome()
  const [step, setStep] = useState(1)

  const handleNext = () => {
    setStep(step + 1)
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  return (
    <div className="flex items-center justify-center">
      <div style={{ width: '-webkit-fill-available' }}>
        <div className="flex p-6 w-[80%] mx-auto">
          <ol className="flex items-center w-full text-[16px] text-center text-gray-500">
            <li className="flex md:w-full items-center text-[#243665] sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10">
              <span
                className={`${step <= 3 && 'text-[#243665] font-bold'} min-w-[210px] flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200`}
              >
                {step === 1 ? (
                  <span className="me-2 text-[#243665] font-bold">1.</span>
                ) : (
                  <svg
                    className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                )}
                Which system is for you?
              </span>
            </li>
            <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10">
              <span
                className={`${step > 1 && 'text-[#243665] font-bold'} flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200`}
              >
                {step < 3 ? (
                  <span
                    className={`${step > 1 ? 'text-[#243665] font-bold' : ''} me-2`}
                  >
                    2.
                  </span>
                ) : (
                  <svg
                    className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                )}
                <span className={`${step > 1 ? 'text-[#243665]' : ''}`}>
                  Costings
                </span>
              </span>
            </li>
            <li className="flex items-center">
              {step < 3 ? (
                <span className="">
                  <span className="me-2">3.</span>
                  Done
                </span>
              ) : (
                <span
                  className={`${step === 3 && 'font-bold'} text-[#243665] flex items-center`}
                >
                  <svg
                    className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  Done
                </span>
              )}
            </li>
          </ol>
        </div>
        <div className="bg-[#f1f1f1] p-6 rounded-lg shadow-md w-full mx-auto">
          {step === 1 ? (
            <WhichSystemIsForYou />
          ) : step === 2 ? (
            <Costings />
          ) : (
            <DontShowAgain />
          )}
          <div
            className={`flex mt-6 ${step === 1 ? 'justify-end' : 'justify-between'}`}
          >
            {step > 1 && (
              <button
                className="bg-gray-300 text-gray-700 hover:opacity-75 min-w-[100px] font-medium rounded-lg text-[12px] px-5 py-2.5 text-center"
                onClick={handleBack}
              >
                Back
              </button>
            )}
            {step < 3 && (
              <button
                className="text-white bg-[#243665] hover:opacity-75 min-w-[100px] font-medium rounded-lg text-[12px] px-5 py-2.5 text-center"
                onClick={handleNext}
              >
                Next
              </button>
            )}

            {step === 3 && (
              <button
                className="text-white bg-[#243665] hover:opacity-75 min-w-[100px] font-medium rounded-lg text-[12px] px-5 py-2.5 text-center"
                onClick={() => {
                  saveWelcomeHide()
                  closeModal()
                }}
              >
                Proceed
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stepper
