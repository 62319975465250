import createAxiosInstance from "@api/Api";
import calculations from "@services/algorithms/calculations";
import growthRateHandler from "@services/algorithms/growthRateHandler";
import numbers from "@services/algorithms/numbers";
import helpers from "@utils/helpers";
import { useEffect, useState } from "react";

const useCommentaryTool = () => {
  const api = createAxiosInstance("standard/retirement/money-growth");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementDate, calculateRetirementLongevity } =
    numbers();
  const { calculateTargetIncome, calculateRetirementFundValue, calculateMultiple, calculateContributionOfIncome } =
    calculations();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState({
    // Miscellaneous
    growth_rate: "Low",
    primary_representation: "",
    // Generic inputs
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity: 0,
    // Growth & cost assumptions
    real_pre_retirement_capital_growth_low: 0,
    real_pre_retirement_capital_growth_medium: 0,
    real_pre_retirement_capital_growth_high: 0,
    nominal_pre_retirement_capital_growth_low: 0,
    nominal_pre_retirement_capital_growth_medium: 0,
    nominal_pre_retirement_capital_growth_high: 0,
    pre_retirement_costs: 0,
    real_growth_low: 0,
    real_growth_medium: 0,
    real_growth_high: 0,
    nominal_growth_low: 0,
    nominal_growth_medium: 0,
    nominal_growth_high: 0,
    // Smart tool specific inputs
    annual_pre_tax_income: 0,
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    current_accumulated_retirement_fund_value: 0,
    current_monthly_contribution: 0,
    pre_retirement_income_percentage: 0,
    contribution_as_percentage_of_income: 0,
    // Output
    target_income: 0,
    projected_multiple_low: 0,
    projected_multiple_medium: 0,
    projected_multiple_high: 0,
  });

  const { calculateNominalGrowthRate, calculateRealGrowthRate, handleNominalChange, handleRealChange } =
    growthRateHandler(formData, setFormData);

  useEffect(() => {
    if (formData.date_of_birth && formData.retirement_age) {
      const birthDate = formData.date_of_birth;
      const retirementAge = formData.retirement_age;
      const effectiveDate = formData.effective_date;

      setFormData((prevFormData) => ({
        ...prevFormData,
        current_age: calculateCurrentAge(birthDate, effectiveDate),
        years_to_retirement: calculateYearsToRetirement(birthDate, retirementAge, effectiveDate),
        projected_retirement_date: calculateRetirementDate(birthDate, retirementAge),
        retirement_longevity: calculateRetirementLongevity(retirementAge, formData.life_expectancy),
      }));
    }
  }, [formData.date_of_birth, formData.retirement_age, formData.life_expectancy, formData.effective_date]);

  useEffect(() => {
    const calculateAndSetValues = () => {
      // Calculate target_income
      const realTargetIncome = calculateTargetIncome(
        formData.annual_pre_tax_income,
        formData.real_growth_in_income,
        formData.years_to_retirement,
        formData.pre_retirement_income_percentage
      );
      const nominalTargetIncome = calculateTargetIncome(
        formData.annual_pre_tax_income,
        formData.nominal_growth_in_income,
        formData.years_to_retirement,
        formData.pre_retirement_income_percentage
      );
      const targetIncome = formData.primary_representation === "real" ? realTargetIncome : nominalTargetIncome;

      // Calculate net growth rates
      const realGrowthLow = calculateRealGrowthRate(
        formData.real_pre_retirement_capital_growth_low,
        formData.pre_retirement_costs
      );
      const realGrowthMedium = calculateRealGrowthRate(
        formData.real_pre_retirement_capital_growth_medium,
        formData.pre_retirement_costs
      );
      const realGrowthHigh = calculateRealGrowthRate(
        formData.real_pre_retirement_capital_growth_high,
        formData.pre_retirement_costs
      );
      const nominalGrowthLow = calculateRealGrowthRate(
        formData.nominal_pre_retirement_capital_growth_low,
        formData.pre_retirement_costs
      );
      const nominalGrowthMedium = calculateRealGrowthRate(
        formData.nominal_pre_retirement_capital_growth_medium,
        formData.pre_retirement_costs
      );
      const nominalGrowthHigh = calculateRealGrowthRate(
        formData.nominal_pre_retirement_capital_growth_high,
        formData.pre_retirement_costs
      );

      const projectedValueLow = calculateRetirementFundValue(
        realGrowthLow,
        formData.real_growth_in_income,
        formData.current_monthly_contribution,
        formData.current_accumulated_retirement_fund_value,
        formData.years_to_retirement
      );

      const projectedValueMedium = calculateRetirementFundValue(
        realGrowthMedium,
        formData.real_growth_in_income,
        formData.current_monthly_contribution,
        formData.current_accumulated_retirement_fund_value,
        formData.years_to_retirement
      );
      const projectedValueHigh = calculateRetirementFundValue(
        realGrowthHigh,
        formData.real_growth_in_income,
        formData.current_monthly_contribution,
        formData.current_accumulated_retirement_fund_value,
        formData.years_to_retirement
      );

      // Calculate projected multiples
      const projectedMultipleLow = calculateMultiple(projectedValueLow, realTargetIncome);
      const projectedMultipleMedium = calculateMultiple(projectedValueMedium, realTargetIncome);
      const projectedMultipleHigh = calculateMultiple(projectedValueHigh, realTargetIncome);

      const contributionOfIncome = calculateContributionOfIncome(
        formData.current_monthly_contribution,
        formData.annual_pre_tax_income
      );

      // Set form data with calculated values
      setFormData((prevFormData) => ({
        ...prevFormData,
        target_income: targetIncome,
        real_growth_low: realGrowthLow,
        real_growth_medium: realGrowthMedium,
        real_growth_high: realGrowthHigh,
        nominal_growth_low: nominalGrowthLow,
        nominal_growth_medium: nominalGrowthMedium,
        nominal_growth_high: nominalGrowthHigh,
        projected_multiple_low: projectedMultipleLow,
        projected_multiple_medium: projectedMultipleMedium,
        projected_multiple_high: projectedMultipleHigh,
        projected_value_low: projectedValueLow,
        projected_value_medium: projectedValueMedium,
        projected_value_high: projectedValueHigh,
        contribution_as_percentage_of_income: contributionOfIncome,
      }));
    };

    calculateAndSetValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.annual_pre_tax_income,
    formData.real_growth_in_income,
    formData.years_to_retirement,
    formData.current_monthly_contribution,
    formData.pre_retirement_income_percentage,
    formData.primary_representation,
    formData.real_pre_retirement_capital_growth_low,
    formData.nominal_pre_retirement_capital_growth_low,
    formData.real_pre_retirement_capital_growth_medium,
    formData.nominal_pre_retirement_capital_growth_medium,
    formData.real_pre_retirement_capital_growth_high,
    formData.nominal_pre_retirement_capital_growth_high,
    formData.pre_retirement_costs,
    formData.retirement_longevity,
    formData.projected_multiple_low,
    formData.projected_multiple_medium,
    formData.projected_multiple_high,
  ]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nominal_pre_retirement_capital_growth_low: calculateNominalGrowthRate(
        formData.real_pre_retirement_capital_growth_low,
        formData.projected_inflation_rate
      ),
      nominal_pre_retirement_capital_growth_medium: calculateNominalGrowthRate(
        formData.real_pre_retirement_capital_growth_medium,
        formData.projected_inflation_rate
      ),
      nominal_pre_retirement_capital_growth_high: calculateNominalGrowthRate(
        formData.real_pre_retirement_capital_growth_high,
        formData.projected_inflation_rate
      ),
      nominal_growth_in_income: calculateNominalGrowthRate(
        formData.real_growth_in_income,
        formData.projected_inflation_rate
      ),
    }));
  }, [formData.projected_inflation_rate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: parseInt(value) });
  };

  const handleCurrencyChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePrimaryRepresentationChange = (value) => {
    setFormData({ ...formData, primary_representation: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateOutput(formData);
  };

  const calculateOutput = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData) => {
    const years = Object.values(chartData.years);

    const annualContributions = Object.values(chartData.annual_contributions).map((value) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const cumulativeCapital = Object.values(chartData.cumulative_capital).map((value) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Cumulative Contribution",
        type: "column",
        color: "#ed7d31",
        data: annualContributions,
      },
      {
        name: "Cumulative Capital",
        type: "line",
        color: "#a5a5a5",
        data: cumulativeCapital,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation: data.ufs.primary_representation || "",
        growth_rate: data.ufs.growth_rate || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        current_age: data.ufs.current_age || "",
        years_to_retirement:
          calculateYearsToRetirement(data.ufs.date_of_birth, data.ufs.retirement_age, data.ufs.effective_date) || "",
        life_expectancy: data.ufs.life_expectancy || "",
        retirement_longevity: calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || "",
        pre_retirement_costs: data.ufs.pre_retirement_costs || "",
        real_pre_retirement_capital_growth_low: data.ufs.real_pre_retirement_capital_growth_low || "",
        real_pre_retirement_capital_growth_medium: data.ufs.real_pre_retirement_capital_growth_medium || "",
        real_pre_retirement_capital_growth_high: data.ufs.real_pre_retirement_capital_growth_high || "",
        nominal_pre_retirement_capital_growth_low: data.ufs.nominal_pre_retirement_capital_growth_low || "",
        nominal_pre_retirement_capital_growth_medium: data.ufs.nominal_pre_retirement_capital_growth_medium || "",
        nominal_pre_retirement_capital_growth_high: data.ufs.nominal_pre_retirement_capital_growth_high || "",
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || "",
        real_growth_in_income: data.ufs.real_growth_in_income || "",
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || "",
        pre_retirement_income_percentage: data.ufs.pre_retirement_income_percentage || "",
        current_accumulated_retirement_fund_value: data.ufs.current_accumulated_retrement_fund_value || "",
        current_monthly_contribution: data.ufs.current_monthly_contribution || "",
        contribution_as_percentage_of_income: data.ufs.contribution_as_percentage_of_income || "",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    handleChange,
    handleNumberChange,
    handleCurrencyChange,
    handleSubmit,
    handlePrimaryRepresentationChange,
    chartSeries,
    chartOptions,
    setChartSeries,
    handleNominalChange,
    handleRealChange,
  };
};

export default useCommentaryTool;
