import VerticalTabs from "@/components/Element/VerticalTabs";
import CommentaryLayout from "@/layouts/CommentaryLayout";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CompoundInterest from "./compoundInterest";
import ImpactOfCosts from "./impactOfCosts";
import InvestmentExpectations from "./investmentExpectations";
import InvestmentTerms from "./investmentTerms";

const TerminologyAndExpectationsIndex = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getTabIndex = searchParams.get("tab");

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo={"/commentary"}
      pageTitle={
        getTabIndex === "1"
          ? "Investment terms"
          : getTabIndex === "2"
            ? "Compound interest & “the rule of 72”"
            : getTabIndex === "3"
              ? "The impact of costs on investment returns"
              : getTabIndex === "4"
                ? "Investment expectations – professional commentary"
                : "Terminology And Expectations"
      }
    >
      <>
        <VerticalTabs tabIndex={getTabIndex}>
          <div
            tabtitle="Investment Terms"
            className="h-full"
          >
            <InvestmentTerms />
          </div>
          <div
            tabtitle="Compound interest & “the rule of 72”"
            className="h-full"
          >
            <CompoundInterest />
          </div>
          <div
            tabtitle="The impact of costs on investment returns"
            className="h-full"
          >
            <ImpactOfCosts />
          </div>
          <div
            tabtitle="Investment expectations – professional commentary"
            className="h-full"
          >
            <InvestmentExpectations />
          </div>
        </VerticalTabs>
      </>
    </CommentaryLayout>
  );
};

export default TerminologyAndExpectationsIndex;
