import image from "@/assets/bg-image.jpg";
import Navbar from "@/components/Layout/Navbar";
import Modal from "@/components/Modals/Modal";
import HelpModal from "@/components/Modals/HelpModal";
import StandardOption from "@/components/Modals/StandardOption";
import { useState } from "react";

const StandardToolLayout = ({ children, pageTitle, modalContent, showBackButton, showHelpPopup }) => {
  // Help modal
  const [helpModal, setHelpModal] = useState(showHelpPopup === false ? true : false);
  const ToggleHelpModal = () => setHelpModal(!helpModal);
  const closeHelpModal = () => setHelpModal(false);

  // Modal
  const [modal, setModal] = useState(false);
  const ToggleModal = () => setModal(!modal);
  const closeModal = () => setModal(false);

  return (
    <div>
      <Navbar
        pageTitle={pageTitle}
        modalContent={modalContent}
        toggleHelpModal={ToggleHelpModal}
        toggleModal={ToggleModal}
        showBackButton={showBackButton}
      />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-screen pt-[70px] relative position-fixed"
      >
        {children}
        <HelpModal
          show={helpModal}
          closeHelpModal={closeHelpModal}
          modalContent={modalContent}
        />
        <Modal
          show={modal}
          closeModal={closeModal}
          modalContent={<StandardOption />}
        />
      </div>
    </div>
  );
};

export default StandardToolLayout;
