import { createContext } from "react";

export interface AppContext {
  user: any;
  token: any;
  login: Function;
  register: Function;
  logout: Function;
  forgotPassword: Function;
  resetPassword: Function;
  isLoading: boolean;
}

const appContext = createContext<AppContext | undefined>(undefined);

export default appContext;
