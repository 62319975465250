import usePasswordInformation from "@api/User/usePasswordInformation";
import usePasswords from "@services/algorithms/usePasswords";
import Button from "@form/Button";
import Password from "@form/Password";
import Error from "@form/Error";

const PasswordReset = () => {
  const { togglePasswordVisibility, showPassword } = usePasswords();
  const { formData, handleChange, handleSubmit, errors, loading } = usePasswordInformation();

  return (
    <>
      <h3 className="mb-4 text-xl font-semibold ">Password information</h3>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-2">
            <Password
              label="Current password"
              showPassword={showPassword}
              name="currentPassword"
              id="currentPassword"
              required
              value={formData.currentPassword}
              onChange={handleChange}
              togglePasswordVisibility={togglePasswordVisibility}
            />
            <Error error={errors && errors.current_password} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Password
              label="New password"
              showPassword={showPassword}
              name="password"
              id="password"
              required
              value={formData.password}
              onChange={handleChange}
              togglePasswordVisibility={togglePasswordVisibility}
            />
            <Error error={errors && errors.password} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Password
              label="Confirm password"
              showPassword={showPassword}
              name="confirmPassword"
              id="confirmPassword"
              required
              value={formData.confirmPassword}
              onChange={handleChange}
              togglePasswordVisibility={togglePasswordVisibility}
            />
            <Error error={errors && errors.password_confirmation} />
          </div>
          <div className="col-span-6 sm:col-full">
            <Button
              type="submit"
              name="Save"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default PasswordReset;
