const calculations = () => {
  const formatNumberBreaks = (number: number) => {
    if (number === undefined || number === null) {
      return 0;
    }
    return number.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const formatNumber = (number: number) => {
    let formattedNumber = number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return parseFloat(formattedNumber);
  };

  const calculateTargetIncome = (
    annualIncome: number,
    realGrowthInIncome: number | any,
    yearsToRetirement: number | any,
    targetIncomeOnRetirement: number | any
  ) => {
    const growthRate = realGrowthInIncome / 100;
    const targetIncomePercentage = targetIncomeOnRetirement / 100;
    const growthFactor = Math.pow(1 + growthRate, yearsToRetirement);

    const retirementIncome = annualIncome * growthFactor * targetIncomePercentage;

    return parseInt(retirementIncome.toFixed(0));
  };

  const calculateRequiredMultiple = (netAboveInflation: number | any, retirementLongevity: number | any) => {
    const netAboveInflationValue = parseFloat(netAboveInflation) / 100;

    const one = 1 - Math.pow(1 / (1 + netAboveInflationValue), retirementLongevity);
    const two = 1 - 1 / (1 + netAboveInflationValue);

    const expressionResult = one / two;

    return formatNumber(expressionResult);
  };

  const parseNumber = (value: string): number | any => {
    const formatValue = String(value).replaceAll(",", "");
    const parsedValue = parseFloat(formatValue);

    if (!isNaN(parsedValue)) {
      return parsedValue;
    }

    return 0;
  };

  const calculateMultiple = (retirementFundValue: string, preTaxSalary: string) => {
    const fundValue = parseNumber(retirementFundValue);
    const salary = parseNumber(preTaxSalary);
    const result = fundValue / salary;
    return parseFloat(result.toFixed(2));
  };

  // const calculateMultiple = (retirementFundValue: string, preTaxSalary: string) => {
  //   const fundValue = parseFloat(retirementFundValue?.toString().replace(/,/g, ""));
  //   const salary = parseFloat(preTaxSalary?.toString().replace(/,/g, ""));
  //   return parseFloat(fundValue / salary).toFixed(2);
  // };

  const calculateRequiredValue = (requiredMultiple: string, adjustedPreTaxSalary: string) => {
    requiredMultiple = requiredMultiple?.toString().replace(/,/g, "");
    adjustedPreTaxSalary = adjustedPreTaxSalary?.toString().replace(/,/g, "");

    const requiredMultipleValue = parseFloat(requiredMultiple);
    const adjustedPreTaxSalaryValue = parseFloat(adjustedPreTaxSalary);

    return requiredMultipleValue * adjustedPreTaxSalaryValue;
  };

  const yearsToDepletion = (growth: number, incomeAtRetirement: number) => {
    const rate = growth / 100;
    const income = incomeAtRetirement;

    const numerator = Math.log(1 - income * (1 - 1 / (1 + rate)));
    const denominator = Math.log(1 / (1 + rate));

    return parseFloat((numerator / denominator).toFixed(2));
  };

  function calculateRetirementFundValue(
    netAboveInflation: number,
    realGrowthIncome: number,
    currentMonthlyContribution: number,
    currentAccumulatedRetirementFund: number,
    yearsToRetirement: number
  ) {
    const netAboveInflationDecimal = netAboveInflation / 100;
    const realGrowthIncomeDecimal = realGrowthIncome / 100;
    const years = yearsToRetirement;

    const numerator =
      currentMonthlyContribution *
      12 *
      ((Math.pow(1 + netAboveInflationDecimal, years) - Math.pow(1 + realGrowthIncomeDecimal, years)) /
        (netAboveInflationDecimal - realGrowthIncomeDecimal));
    const denominator = currentAccumulatedRetirementFund * Math.pow(1 + netAboveInflationDecimal, years);

    return numerator + denominator;
  }

  function calculateContributionOfIncome(monthlyContribution: number, annualIncome: number) {
    const contribution = parseFloat(monthlyContribution?.toString().replace(/,/g, ""));
    const income = parseFloat(annualIncome?.toString().replace(/,/g, ""));

    const result = ((contribution * 12) / income) * 100;

    return parseFloat(result.toFixed(2));
  }

  const calculateDifference = (a: number, b: number, amount = 2) => {
    const first = parseFloat(a?.toString().replace(/,/g, ""));
    const second = parseFloat(b?.toString().replace(/,/g, ""));

    const result = first - second;

    return parseFloat(result.toFixed(amount));
  };

  function calculateAnnualFinance(interestRate: number, termYears: number, financeAmount: number) {
    const monthlyInterestRate = interestRate / 100;
    const totalPayments = termYears;

    const monthlyPayment =
      (-financeAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments));
    return parseFloat(monthlyPayment.toFixed(2));
  }

  function calculateAnnualWithdrawal(projectedValue: number, requiredValue: number, targetIncome: number) {
    const annualWithdrawel = (projectedValue / requiredValue) * targetIncome;
    return parseInt(annualWithdrawel.toFixed(0));
  }

  function calculateImpactOnLivingStandard(projectedValueLow: number, requiredValueLow: number) {
    const result = (projectedValueLow / requiredValueLow - 1) * 100;
    return Math.round(result * 100) / 100;
  }

  return {
    formatNumber,
    formatNumberBreaks,
    calculateTargetIncome,
    calculateRequiredMultiple,
    calculateMultiple,
    calculateRequiredValue,
    yearsToDepletion,
    calculateRetirementFundValue,
    calculateContributionOfIncome,
    calculateDifference,
    calculateAnnualFinance,
    calculateAnnualWithdrawal,
    calculateImpactOnLivingStandard,
  };
};

export default calculations;
