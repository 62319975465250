import { Route, Routes } from "react-router-dom";
import { ForgotPassword } from "@/pages/Auth/ForgotPassword/ForgotPassword";
import { Login } from "@/pages/Auth/Login/Login";
import { Register } from "@/pages/Auth/Register/Register";
import { ResetPassword } from "@/pages/Auth/ResetPassword/ResetPassword";


const AuthRoutes = () => (
  <Routes>
    <Route
      index
      path="/"
      element={<Login />}
    />
    <Route
      path="/register"
      element={<Register />}
    />
    <Route
      path="/forgot-password"
      element={<ForgotPassword />}
    />
    <Route
      path="/reset-password/:token"
      element={<ResetPassword />}
    />
  </Routes>
);

export default AuthRoutes;
