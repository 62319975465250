import AnalysisType from "@/components/Form/AnalysisType";
import Currency from "@/components/Form/Currency";
import GrowthRate from "@/components/Form/GrowthRate";
import words from "@/services/algorithms/words";
import useGoalInvestingTool from "@api/Standard/WealthCreation/useGoalInvestingTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import Button from "@form/Button";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";

const GoalInvesting = () => {
  const { capitalizeFirstLetter } = words();
  const { chartOptionsDefault } = helpers();
  const { isVisible, toggleVisibility } = useVisibility();
  const {
    loading,
    formData,
    handleChange,
    handleCurrencyChange,
    handleSubmit,
    chartSeries,
    handlePrimaryRepresentationChange,
  } = useGoalInvestingTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("goal-investing-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to assist clients in identifying how much they need to put aside on a monthly basis
              over a set horizon to achieve a target outcome. Inputs required are:
              <ul className="pl-4">
                <li className="my-1">a) Specific: horizon, initial capital, target capital (real or nominal)</li>
                <li className="my-1">
                  b) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is
              <ul className="pl-4">
                <li className="my-1">
                  a) Table of monthly contributions (savings) required over full period on a “flat” or “annually
                  increasing by inflation basis” for the 3 different growth environments
                </li>
                <li className="my-1">
                  b) Chart shows:
                  <ul className="pl-4">
                    <li className="my-1">i) Value of cumulative contributions</li>
                    <li className="my-1">ii) Value of accumulated capital over horizon</li>
                    <li className="my-1">iii) Scale is generally monthly due to monthly nature of contributions.</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("goal-investing-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="How much do I need to invest to reach my goal?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Goal Investing" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="number"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="growthLow"
                              id="growthLow"
                              value={formData.growthLow}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="growthMedium"
                              id="growthMedium"
                              value={formData.growthMedium}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="growthHigh"
                              id="growthHigh"
                              value={formData.growthHigh}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="annualCosthLow"
                              id="annualCosthLow"
                              value={formData.annualCosthLow}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="annualCostMedium"
                              id="annualCostMedium"
                              value={formData.annualCostMedium}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="annualCosthHigh"
                              id="annualCosthHigh"
                              value={formData.annualCosthHigh}
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-[12px]">Low</th>
                        <th className="text-[12px]">Medium</th>
                        <th className="text-[12px]">High</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="py-[5px] text-[12px] font-bold">Net growth pre-retirement</td>
                      <td className="py-[5px]">
                        <Percentage
                          name="netGrowthLow"
                          id="netGrowthLow"
                          value={formData.netGrowthLow}
                          onChange={handleChange}
                          variant="red"
                          disabled="disabled"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </td>
                      <td className="py-[5px]">
                        <Percentage
                          name="netGrowthMedium"
                          id="netGrowthMedium"
                          value={formData.netGrowthMedium}
                          onChange={handleChange}
                          variant="red"
                          disabled="disabled"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </td>
                      <td className="py-[5px]">
                        <Percentage
                          name="netGrowthHigh"
                          id="netGrowthHigh"
                          value={formData.netGrowthHigh}
                          onChange={handleChange}
                          variant="red"
                          disabled="disabled"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      min="12"
                      max="120"
                      step="12"
                      label="Horizon (months)"
                      name="horizonMonths"
                      id="horizonMonths"
                      type="number"
                      value={formData.horizonMonths}
                      onChange={handleChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Input
                      min="1"
                      max="10"
                      step="1"
                      label="Horizon (years)"
                      name="horizonYears"
                      id="horizonYears"
                      type="number"
                      value={formData.horizonYears}
                      onChange={handleChange}
                      variant="red"
                      disabled="disabled"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Input
                      label="Target date"
                      name="targetDate"
                      id="targetDate"
                      type="date"
                      value={formData.targetDate}
                      onChange={handleChange}
                      variant="red"
                      disabled="disabled"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      min="1"
                      label="Initial capital"
                      name="initialCapital"
                      id="initialCapital"
                      type="number"
                      value={formData.initialCapital}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      min="0.01"
                      step="0.01"
                      label={`Target capital -
                      (${formData.primary_representation})`}
                      name="targetCapital"
                      id="targetCapital"
                      type="number"
                      value={formData.targetCapital}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows the amount that needs to be saved per month to achieve your target at
                    different growth rates. The first row assumes a flat payment, the second, an amount that increases
                    every year by prevailing inflation rate.
                  </p>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Description</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Monthly savings required - no annual increase
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.savingsRequiredNoIncreaseLow}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.savingsRequiredNoIncreaseMedium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        R{formData.savingsRequiredNoIncreaseHigh}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Monthly savings required - CPI annual increase
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R{formData.savingsRequiredIncreaseLow}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R{formData.savingsRequiredIncreaseMedium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R{formData.savingsRequiredIncreaseHigh}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                Retirement - {formData.primary_representation} withdrawals & residual capital
              </p>

              {loading ? (
                <ChartLoading />
              ) : (
                <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                  {chartSeries && (
                    <Chart
                      options={chartOptionsDefault}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default GoalInvesting;
