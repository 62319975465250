import image from "@assets/bg-image.jpg";
import AdditionalSettings from "@components/User/AdditionalSettings";
import EmailArticles from "@components/User/EmailArticles";
import FinancialSettings from "@components/User/FinancialSettings";
import GeneralInformation from "@components/User/GeneralInformation";
import PasswordReset from "@components/User/PasswordReset";
import PersonalInformation from "@components/User/PersonalInformation";
import PageTitle from "@elements/PageTitle";
import DashboardLayout from "@layouts/DashboardLayout";
import { useState } from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";

const MyProfile = () => {
  const [tabs, setTabs] = useState("personalInformation");

  return (
    <DashboardLayout showBackButton>
      <PageTitle title="My Profile" />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-full relative"
      >
        <div className="sm:hidden">
          <select
            id="tabs"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-[12px] block w-full p-2.5"
          >
            <option>General Information</option>
            <option>Financial Settings</option>
            <option>Additional Settings</option>
            <option>Password Information</option>
          </select>
        </div>
        <ul className="hidden text-md text-center rounded-lg shadow sm:flex fixed w-full z-10">
          <li
            className="w-full"
            onClick={() => {
              setTabs("personalInformation");
            }}
          >
            <span
              className={`${tabs === "personalInformation" ? "bg-gray-200 font-bold" : "bg-white"} hover:bg-gray-200 hover:font-bold inline-block w-full p-4 text-gray-900 cursor-pointer`}
            >
              Personal Information
            </span>
          </li>
          <li
            className="w-full"
            onClick={() => {
              setTabs("generalInformation");
            }}
          >
            <span
              className={`${tabs === "generalInformation" ? "bg-gray-200 font-bold" : "bg-white"} hover:bg-gray-200 hover:font-bold inline-block w-full p-4 text-gray-900 cursor-pointer`}
            >
              General Information
            </span>
          </li>
          <li
            className="w-full"
            onClick={() => {
              setTabs("financialSettings");
            }}
          >
            <span
              className={`${tabs === "financialSettings" ? "bg-gray-200 font-bold" : "bg-white"} hover:bg-gray-200 hover:font-bold inline-block w-full p-4 border-s-0 border-gray-200 cursor-pointer`}
            >
              Financial Settings
            </span>
          </li>
          <li
            className="w-full"
            onClick={() => {
              setTabs("additionalSettings");
            }}
          >
            <span
              className={`${tabs === "additionalSettings" ? "bg-gray-200 font-bold" : "bg-white"} hover:bg-gray-200 hover:font-bold inline-block w-full p-4 border-s-0 border-gray-200 cursor-pointer`}
            >
              Additional Settings
            </span>
          </li>
          <li
            className="w-full"
            onClick={() => {
              setTabs("passwordInformation");
            }}
          >
            <span
              className={`${tabs === "passwordInformation" ? "bg-gray-200 font-bold" : "bg-white"} hover:bg-gray-200 hover:font-bold inline-block w-full p-4 border-s-0 border-gray-200 cursor-pointer`}
            >
              Password Information
            </span>
          </li>
        </ul>
        <div className="w-full min-h-[calc(100vh-70px)] pt-[55px]">
          <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4">
            {tabs === "personalInformation" ? (
              <div className="col-span-1">
                <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2">
                  <PersonalInformation />
                </div>
              </div>
            ) : tabs === "generalInformation" ? (
              <div className="col-span-1">
                <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2">
                  <GeneralInformation />
                </div>
              </div>
            ) : tabs === "financialSettings" ? (
              <div className="col-span-1">
                <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2">
                  <FinancialSettings />
                </div>
              </div>
            ) : tabs === "additionalSettings" ? (
              <div className="grid grid-cols-1 px-4 md:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6 xl:mb-0">
                    <div className="flow-root">
                      <AdditionalSettings />
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:p-6 xl:mb-0 min-h-[calc(100%-1rem)]">
                    <div className="flow-root">
                      <EmailArticles />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              tabs === "passwordInformation" && (
                <div className="col-span-1">
                  <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 sm:p-6 ">
                    <PasswordReset />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default MyProfile;
