import { useState, useEffect } from "react";
import createAxiosInstance from "@api/Api";
import toast from "react-hot-toast";
import numbers from "@services/algorithms/numbers";

const usePersonalInformation = () => {
  const api = createAxiosInstance("user/personal-information");
  const { isValidSAIDNumber, generateDateOfBirthFromID } = numbers();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [idError, setIdError] = useState(null);
  const [genders, setGenders] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    id_number: "",
    mobile_number: "",
    email: "",
    address: "",
    gender_id: "",
    date_of_birth: "",
  });

  useEffect(() => {
    if (formData.date_of_birth && formData.retirement_age) {
      const birthDate = new Date(formData.date_of_birth);
      const currentDate = new Date();
      const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
      const birthMonth = birthDate.getMonth();
      const currentMonth = currentDate.getMonth();
      const currentAge =
        currentMonth < birthMonth || (currentMonth === birthMonth && currentDate.getDate() < birthDate.getDate())
          ? ageDiff - 1
          : ageDiff;

      setFormData((prevFormData) => ({
        ...prevFormData,
        current_age: currentAge,
      }));

      const retirementAge = parseInt(formData.retirement_age);
      const projectedRetirementYear = birthDate.getFullYear() + retirementAge;
      const lastDayOfMonth = new Date(projectedRetirementYear, birthMonth + 1, 0).getDate();
      const projectedRetirementDate = new Date(projectedRetirementYear, birthMonth, lastDayOfMonth);

      const millisecondsDifference = projectedRetirementDate.getTime() - currentDate.getTime();

      const yearsToRetirement = millisecondsDifference / (1000 * 60 * 60 * 24 * 365.25);

      setFormData((prevFormData) => ({
        ...prevFormData,
        years_to_retirement: yearsToRetirement.toFixed(2),
        projected_retirement_date: projectedRetirementDate.toISOString().split("T")[0],
      }));
    }
  }, [formData.date_of_birth, formData.retirement_age]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIDChange = (e) => {
    const { name, value } = e.target;

    if (isValidSAIDNumber(value)) {
      const dob = generateDateOfBirthFromID(value).toISOString().split("T")[0];
      setIdError(null);
      setFormData({ ...formData, date_of_birth: dob, [name]: value });
    } else {
      setIdError(["Invalid ID number"]);
      setFormData({ ...formData, date_of_birth: "", [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(savePersonalInformation(formData), {
      loading: "Saving...",
      success: (data) => data.message,
      error: "Could not save.",
    });
  };

  const getPersonalInformation = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(``, formData);

      setFormData({
        name: data.user.name || "",
        surname: data.user.surname || "",
        id_number: data.user.id_number || "",
        mobile_number: data.user.mobile_number || "",
        email: data.user.email || "",
        address: data.user.address || "",
        gender_id: data.user.gender_id || "",
        date_of_birth: data.user.date_of_birth || "",
      });
      setGenders(data.genders);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const savePersonalInformation = async (formData) => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put(``, formData);
      return Promise.resolve(data);
    } catch (error) {
      setErrors(error.response.data.errors);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonalInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    errors,
    idError,
    formData,
    genders,
    handleChange,
    handleIDChange,
    handleSubmit,
    getPersonalInformation,
    savePersonalInformation,
  };
};

export default usePersonalInformation;
