import useGapAnalysisTool from "@api/Standard/Retirement/useGapAnalysisTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import PercentageSwitch from "@form/PercentageSwitch";
import StandardToolLayout from "@layouts/StandardToolLayout";
import calculations from "@services/algorithms/calculations";
import words from "@services/algorithms/words";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";

const GapAnalysis = () => {
  const { isVisible, toggleVisibility } = useVisibility();
  const { capitalizeFirstLetter } = words();
  const { preventEnterPress } = helpers();
  const { formatNumberBreaks } = calculations();

  const {
    loading,
    formData,
    handleChange,
    handleSubmit,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
    chartSeries,
    chartOptions,
    handleNominalChange,
    handleRealChange,
  } = useGapAnalysisTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("gap-analysis-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is an extension of “How long will my money last in retirement?” and combines elements of
              pre-retirement contributions, increases and returns & post-retirement withdrawals and returns to determine
              the projected “gap” between pre- and post-retirement standards of living.
            </li>
            <li>
              The tool demonstrates 3 mechanisms that can close the gap in addition to the impact of differential asset
              growth rates.
            </li>
            <li>
              3) Inputs are as per the other retirement tools as follows:
              <ul className="pl-4">
                <li className="my-1">
                  a) Specific inputs: annual income, annual real growth in income, contributions, annual real growth in
                  contributions, current capital value of retirement products (standard assumption is that contributions
                  increase in line with income)
                </li>
                <li className="my-1">
                  b) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li>
              Outputs are delivered in three separate representations:
              <ul className="pl-4">
                <li className="my-1">
                  a) Representation #1
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Projected size of the retirement pot under the 3 pre-retirement net growth environments and
                      associated multiple of the final income.
                    </li>
                    <li className="my-1">
                      ii) Required pot size at retirement to support target standard of living (SOL) in retirement at
                      differential post-retirement growth rates and associated multiples
                      <ul className="pl-4">
                        <li className="my-1">iii) Impact of difference on projected SOL in retirement</li>
                        iv) 3 actions to fully close the gap viz.
                        <li className="my-1">• How much longer will I need to work?</li>
                        <li className="my-1">• How much more must I contribute per month?</li>
                        <li className="my-1">• How much capital must I inject?</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="my-1">
                  b) Representation #2
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Interactive representation of combinations of activities / scenarios as indicated above but
                      with adjusted levels – multiple selections
                    </li>
                    <li className="my-1">
                      ii) Client can input own variables in the 3 categories indicated above, or a combination thereof,
                      to identify trade-offs.
                    </li>
                    <li className="my-1">
                      iii) Table will demonstrate impact on projected SOL in retirement relative to current.
                    </li>
                    <li className="my-1">
                      iv) This option relates to the impact of adjusting annual withdrawals to achieve required life
                      expectancy - chart contrasts the client’s current situation with the impact of changes.
                    </li>
                  </ul>
                </li>
                <li className="my-1">
                  c) Representation #3
                  <ul className="pl-4">
                    <li className="my-1">i) As per above but…</li>
                    <li className="my-1">
                      ii) This option relates to the impact of maintaining target SOL and as much longevity as possible
                      - chart contrasts the client’s current situation with the impact of changes
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("gap-analysis-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  const GrowthPreRetirementRow = () => {
    return (
      <tr>
        <td className="py-[5px] text-[12px] font-bold">Net growth pre-retirement</td>
        {formData.primary_representation == "real" ? (
          <>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="real_pre_net_retirement_growth_low"
                id="real_pre_net_retirement_growth_low"
                value={formData.real_pre_net_retirement_growth_low}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="real_pre_net_retirement_growth_medium"
                id="real_pre_net_retirement_growth_medium"
                value={formData.real_pre_net_retirement_growth_medium}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="real_pre_net_retirement_growth_high"
                id="real_pre_net_retirement_growth_high"
                value={formData.real_pre_net_retirement_growth_high}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
          </>
        ) : (
          <>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="nominal_pre_net_retirement_growth_low"
                id="nominal_pre_net_retirement_growth_low"
                value={formData.nominal_pre_net_retirement_growth_low}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="nominal_pre_net_retirement_growth_medium"
                id="nominal_pre_net_retirement_growth_medium"
                value={formData.nominal_pre_net_retirement_growth_medium}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="nominal_pre_net_retirement_growth_high"
                id="nominal_pre_net_retirement_growth_high"
                value={formData.nominal_pre_net_retirement_growth_high}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
          </>
        )}
      </tr>
    );
  };

  const GrowthPostRetirementRow = () => {
    return (
      <tr>
        <td className="py-[5px] text-[12px] font-bold">Net growth post-retirement</td>
        {formData.primary_representation == "real" ? (
          <>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="real_post_net_retirement_growth_low"
                id="real_post_net_retirement_growth_low"
                value={formData.real_post_net_retirement_growth_low}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="real_post_net_retirement_growth_medium"
                id="real_post_net_retirement_growth_medium"
                value={formData.real_post_net_retirement_growth_medium}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="real_post_net_retirement_growth_high"
                id="real_post_net_retirement_growth_high"
                value={formData.real_post_net_retirement_growth_high}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
          </>
        ) : (
          <>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="nominal_post_net_retirement_growth_low"
                id="nominal_post_net_retirement_growth_low"
                value={formData.nominal_post_net_retirement_growth_low}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="nominal_post_net_retirement_growth_medium"
                id="nominal_post_net_retirement_growth_medium"
                value={formData.nominal_post_net_retirement_growth_medium}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
            <td className="py-[5px]">
              <Percentage
                disabled
                name="nominal_post_net_retirement_growth_high"
                id="nominal_post_net_retirement_growth_high"
                value={formData.nominal_post_net_retirement_growth_high}
                variant="red"
                twMergeClassName={twMerge(`text-center`)}
              />
            </td>
          </>
        )}
      </tr>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Retirement funding gap analysis"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Retirement Funding Gap Analysis" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          required
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          disabled
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          disabled
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          disabled
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="text"
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          disabled
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>

                          {formData.primary_representation == "real" ? (
                            <>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_pre_retirement_capital_growth_low"
                                  id="real_pre_retirement_capital_growth_low"
                                  value={formData.real_pre_retirement_capital_growth_low}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_pre_retirement_capital_growth_medium"
                                  id="real_pre_retirement_capital_growth_medium"
                                  value={formData.real_pre_retirement_capital_growth_medium}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_pre_retirement_capital_growth_high"
                                  id="real_pre_retirement_capital_growth_high"
                                  value={formData.real_pre_retirement_capital_growth_high}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_pre_retirement_capital_growth_low"
                                  id="nominal_pre_retirement_capital_growth_low"
                                  value={formData.nominal_pre_retirement_capital_growth_low}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_pre_retirement_capital_growth_medium"
                                  id="nominal_pre_retirement_capital_growth_medium"
                                  value={formData.nominal_pre_retirement_capital_growth_medium}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_pre_retirement_capital_growth_high"
                                  id="nominal_pre_retirement_capital_growth_high"
                                  value={formData.nominal_pre_retirement_capital_growth_high}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="pre_retirement_costs"
                              id="pre_retirement_costs"
                              value={formData.pre_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        {GrowthPreRetirementRow()}
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] normal-case font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth post-retirement
                          </td>

                          {formData.primary_representation == "real" ? (
                            <>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_post_retirement_capital_growth_low"
                                  id="real_post_retirement_capital_growth_low"
                                  value={formData.real_post_retirement_capital_growth_low}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_post_retirement_capital_growth_medium"
                                  id="real_post_retirement_capital_growth_medium"
                                  value={formData.real_post_retirement_capital_growth_medium}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_post_retirement_capital_growth_high"
                                  id="real_post_retirement_capital_growth_high"
                                  value={formData.real_post_retirement_capital_growth_high}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_post_retirement_capital_growth_low"
                                  id="nominal_post_retirement_capital_growth_low"
                                  value={formData.nominal_post_retirement_capital_growth_low}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_post_retirement_capital_growth_medium"
                                  id="nominal_post_retirement_capital_growth_medium"
                                  value={formData.nominal_post_retirement_capital_growth_medium}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_post_retirement_capital_growth_high"
                                  id="nominal_post_retirement_capital_growth_high"
                                  value={formData.nominal_post_retirement_capital_growth_high}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        {GrowthPostRetirementRow()}
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        {GrowthPreRetirementRow()}
                        {GrowthPostRetirementRow()}
                      </tbody>
                    </>
                  )}
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Currency
                      label="Annual pre-tax income"
                      name="annual_pre_tax_income"
                      id="annual_pre_tax_income"
                      value={formData.annual_pre_tax_income}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    {formData.primary_representation == "real" ? (
                      <>
                        <Percentage
                          label="Real growth in income"
                          name="real_growth_in_income"
                          id="real_growth_in_income"
                          value={formData.real_growth_in_income}
                          onChange={handleRealChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    ) : (
                      <>
                        <Percentage
                          label="Nominal growth in income"
                          name="nominal_growth_in_income"
                          id="nominal_growth_in_income"
                          value={formData.nominal_growth_in_income}
                          onChange={handleNominalChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    )}
                  </div>

                  <div className="col-span-1">
                    <Currency
                      label="Current accumulated retirement fund value"
                      name="current_accumulated_retrement_fund_value"
                      id="current_accumulated_retrement_fund_value"
                      value={formData.current_accumulated_retirement_fund_value}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Currency
                      label="Current monthly contribution"
                      name="current_monthly_contribution"
                      id="current_monthly_contribution"
                      value={formData.current_monthly_contribution}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Percentage
                      label="Target income on retirement (% of pre-retirement)"
                      name="pre_retirement_income_percentage"
                      id="pre_retirement_income_percentage"
                      value={formData.pre_retirement_income_percentage}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>

                  <div className="col-span-1">
                    <Percentage
                      label="Contribution as a % of income"
                      name="contribution_as_percentage_of_income"
                      id="contribution_as_percentage_of_income"
                      value={formData.contribution_as_percentage_of_income}
                      onChange={handleChange}
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                </div>
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    <Currency
                      disabled
                      label="Target income at retirement:"
                      onChange={handleCurrencyChange}
                      value={formData.target_income}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div></div>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] text-left font-semibold p-2">
                        Gap analysis
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Projected value at retirement
                      </td>
                      {formData.primary_representation == "real" ? (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_projected_value_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_projected_value_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_projected_value_high)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_projected_value_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_projected_value_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_projected_value_high)}
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Projected multiple of target income
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.projected_multiple_low === "Infinity" || formData.projected_multiple_low === "NaN"
                          ? "0.00"
                          : formData.projected_multiple_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.projected_multiple_medium === "Infinity" ||
                        formData.projected_multiple_medium === "NaN"
                          ? "0.00"
                          : formData.projected_multiple_medium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.projected_multiple_high === "Infinity" || formData.projected_multiple_high === "NaN"
                          ? "0.00"
                          : formData.projected_multiple_high}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Years to full depletion at target income
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.years_to_full_depletion_at_target_income_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.years_to_full_depletion_at_target_income_medium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.years_to_full_depletion_at_target_income_high}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Sustainable annual withdrawals
                      </td>
                      {formData.primary_representation == "real" ? (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_annual_withdrawal_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_annual_withdrawal_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_annual_withdrawal_high)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_annual_withdrawal_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_annual_withdrawal_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_annual_withdrawal_high)}
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Required value at retirement
                      </td>
                      {formData.primary_representation == "real" ? (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_required_value_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_required_value_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_required_value_high)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_required_value_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_required_value_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_required_value_high)}
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Required multiple of target income
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.required_multiple_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.required_multiple_medium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        {formData.required_multiple_high}
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Difference In value
                      </td>
                      {formData.primary_representation == "real" ? (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_value_difference_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_value_difference_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.real_value_difference_high)}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_value_difference_low)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_value_difference_medium)}
                          </td>
                          <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                            R{formatNumberBreaks(formData.nominal_value_difference_high)}
                          </td>
                        </>
                      )}
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Impact on standard of living in retirement
                      </td>
                      <td
                        className={`bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ${formData.impact_on_living_standard_low < 0 ? "text-[red]" : ""}`}
                      >
                        {formData.impact_on_living_standard_low}%
                      </td>
                      <td
                        className={`bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ${formData.impact_on_living_standard_medium < 0 ? "text-[red]" : ""}`}
                      >
                        {formData.impact_on_living_standard_medium}%
                      </td>
                      <td
                        className={`bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ${formData.impact_on_living_standard_high < 0 ? "text-[red]" : ""}`}
                      >
                        {formData.impact_on_living_standard_high}%
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] text-left font-semibold p-2">
                        Gap analysis
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        How many years must I defer retirement?
                      </td>
                      <td className="bg-[#f5d496] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 "></td>
                      <td className="bg-[#f5d496] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        -
                      </td>
                      <td className="bg-[#f5d496] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        -
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        How much more must I contribute monthly?
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        R
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        How much capital do I need to inject?
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-semibold p-2 ">
                        R
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="mt-4 text-[12px] font-bold">
                  ** These are the individual adjustments you would need to make to "close the gap"
                </p>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center`}>
                  <h3 className="text-[16px] font-semibold">Target adjustments</h3>
                </div>

                <div className="grid grid-cols-2 gap-3 mt-4">
                  <div className="col-span-1">
                    <Currency
                      label="Increase monthly contribution"
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      label="Inject capital"
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Input
                      label="Defer retirement by"
                      onChange={handleChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      disabled
                      label="Adjusted target income"
                      onChange={handleCurrencyChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Currency
                      label="Adjusted retirement longevity"
                      onChange={handleCurrencyChange}
                      variant="red"
                      disabled
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
                <table className="table-auto w-full border border-[#999] mt-4">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] text-center font-semibold p-2">
                        Select to chart
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] text-left font-semibold p-2">
                        Gap analysis
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center"></td>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Adjusted projected value at retirement
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={2}
                        className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center"
                      >
                        <input
                          id="adjusted_sustainable_income"
                          type="radio"
                          value="adjusted_sustainable_income"
                          name="set"
                          checked="checked"
                          onChange={handleChange}
                          className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
                        />
                      </td>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Adjusted sustainable annual income in retirement
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">
                        R
                      </td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Change in sustainable income
                      </td>
                      <td className="border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">R</td>
                      <td className="border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">R</td>
                      <td className="border-b border-[#999] border-r text-center text-[12px] font-bold p-2 ">R</td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={2}
                        className="border-b border-r border-[#999] text-[12px] font-semibold p-2 text-center"
                      >
                        <input
                          id="years_to_depletion"
                          type="radio"
                          value="years_to_depletion"
                          name="set"
                          onChange={handleChange}
                          className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
                        />
                      </td>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Years to depletion at target retirement income
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 "></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 "></td>
                      <td className="bg-[#f2e1c3] border-b border-[#999] border-r text-center text-[12px] font-bold p-2 "></td>
                    </tr>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2">
                        Change in years to depletion
                      </td>
                      <td className="border-b border-[#999] border-r text-center text-[12px] font-bold p-2 "></td>
                      <td className="border-b border-[#999] border-r text-center text-[12px] font-bold p-2 "></td>
                      <td className="border-b border-[#999] border-r text-center text-[12px] font-bold p-2 "></td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-4 text-[12px] font-bold">
                  NB: Deferring the date of retirement implies a higher target income BUT a reduced period in
                  retirement.
                </p>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="font-bold text-[16px] text-center mb-2">
                {capitalizeFirstLetter(formData.primary_representation)} gap analysis: years to depletion of capital
              </p>
              <p className="font-bold text-[12px] text-center mb-4">
                <i>Current capital @ current target income VS adjusted capital @ adjusted target income</i>
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <>
                  <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                    {chartSeries && (
                      <Chart
                        options={chartOptions}
                        series={chartSeries}
                        type="line"
                        width="100%"
                        height="100%"
                        className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                      />
                    )}
                  </div>
                </>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default GapAnalysis;
