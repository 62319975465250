import React from "react";
import RadioButton from "../RadioButton";

interface IOption {
  label: string;
  name?: string;
  disabled?: boolean;
}

interface RenderOptionsProps {
  options: IOption[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedOption: String;
}

const RenderOptions = ({ options, onChange, selectedOption }: RenderOptionsProps) => {
  return options.map(({ label, name, disabled }, index) => {
    const optionId = `radio-option-${label.replace(/\s+/g, "")}`;

    return (
      <RadioButton
        value={label}
        label={label}
        key={index}
        id={optionId}
        name={name}
        disabled={disabled}
        defaultChecked={index === 0}
        onChange={onChange}
        selectedOption={selectedOption}
      />
    );
  });
};

interface RadioButtonGroupProps {
  label?: string;
  options: IOption[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedOption: String;
}

const RadioButtonGroup = ({ label, options, onChange, selectedOption }: RadioButtonGroupProps) => (
  <fieldset>
    <legend>{label}</legend>
    <RenderOptions
      options={options}
      onChange={onChange}
      selectedOption={selectedOption}
    />
  </fieldset>
);

export default RadioButtonGroup;
