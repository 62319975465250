import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";

export const cardVariants = cva("rounded-md shadow-sm bg-[#fff] border h-full", {
  variants: {
    variant: {
      white: "bg-[#fff] border-gray-200 shadow-sm",
      red: "bg-[#ff000020] border-[#ff000090]",
      green: "bg-[#00800040] border-[#00800090]",
      orange: "bg-[#F2B443] border-[#ffa700]",
    },
  },
});

export default function Card({ className = null, variant, children, ...props }) {
  return (
    <>
      <div className={`bg-white rounded-md shadow-sm ${className ?? ""}`}>
        <div className={cn(cardVariants({ variant }))}>
          <div className={(cn(cardVariants({ variant })), `p-4 rounded-md h-full shadow-sm`)}>{children}</div>
        </div>
      </div>
    </>
  );
}
