import { useState, useContext } from "react";
import appContext from "@context/app-context";
import toast from "react-hot-toast";

const useResetPassword = () => {
  const { resetPassword } = useContext(appContext);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    token: "",
  });
  const [validationError, setValidationError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (token) => {
    if (formData.password !== formData.confirmPassword) {
      toast.error("Password and confirm password don't match");
      return;
    }

    setValidationError("");
    resetPassword(formData.password, formData.confirmPassword, token);
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    validationError,
  };
};

export default useResetPassword;
