import VerticalTabs from "@/components/Element/VerticalTabs";
import CommentaryLayout from "@/layouts/CommentaryLayout";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DomesticUnitTrusts from "./DomesticUnitTrusts";
import InternationalUnitTrusts from "./InternationalUnitTrusts";
import InvestmentLinks from "./InvestmentLinks";
import MarketMetrics from "./MarketMetrics";

const PerformanceTablesAndLinksIndex = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getTabIndex = searchParams.get("tab");

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo={"/commentary"}
      pageTitle={
        getTabIndex === "1"
          ? "Market metrics"
          : getTabIndex === "2"
            ? "Select domestic unit trusts"
            : getTabIndex === "3"
              ? "Select international unit trusts"
              : "Investment links"
      }
    >
      <>
        <VerticalTabs tabIndex={getTabIndex}>
          <div
            tabtitle="Market metrics"
            className="h-full"
          >
            <MarketMetrics />
          </div>
          <div
            tabtitle="Select domestic unit trusts"
            className="h-full"
          >
            <DomesticUnitTrusts />
          </div>
          <div
            tabtitle="Select international unit trusts"
            className="h-full"
          >
            <InternationalUnitTrusts />
          </div>
          <div
            tabtitle="Investment links"
            className="h-full"
          >
            <InvestmentLinks />
          </div>
        </VerticalTabs>
      </>
    </CommentaryLayout>
  );
};

export default PerformanceTablesAndLinksIndex;
