import createAxiosInstance from '@api/Api'
import { useEffect, useState } from 'react'
import calculations from '@services/algorithms/calculations'
import helpers from '@utils/helpers'

const useVehicleCostTool = () => {
  const api = createAxiosInstance('standard/lifestyle/vehicle-cost')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const { chartOptionsDefault } = helpers()
  const { calculateDifference, calculateAnnualFinance } = calculations()
  const [chartSeries, setChartSeries] = useState([])
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault)

  const [formData, setFormData] = useState({
    primary_representation: '',
    growth_rate: 'Low',
    projected_inflation_rate: '',
    effective_date: '',

    v1_price: 500000,
    v2_price: 400000,
    price_difference: 0,
    v1_trv: 100000,
    v2_trv: 100000,
    trv_difference: 0,
    v1_fin_amount: 400000,
    v2_fin_amount: 300000,
    fin_amount_difference: 0,
    v1_term: 5,
    v2_term: 5,
    term_difference: 0,
    v1_interest: 10.5,
    v2_interest: 10.5,
    interest_difference: 0,
    v1_ann_fin_amount: 0,
    v2_ann_fin_amount: 0,
    ann_fin_amount_difference: 0,
    v1_ann_ins: -14500,
    v2_ann_ins: -11500,
    ann_ins_difference: 0,
    v1_ann_maintenance: -5000,
    v2_ann_maintenance: -4500,
    ann_maintenance_difference: 0,
    v1_ann_cost: -14500,
    v2_ann_cost: -12500,
    ann_cost_difference: 0,
    v1_tar_value: 1.9,
    v2_tar_value: 0.95,
    v1_rep_cycle: 5,
    v2_rep_cycle: 10,
    v1_ann_veh_dep: -11,
    v2_ann_veh_dep: -9,
    future_years_hence: 25,

    proj_ret_own_cost: 0,
    forward_term: 0,
  })

  useEffect(() => {
    const calculateAndSetValues = () => {
      const priceDifference = calculateDifference(
        formData.v1_price,
        formData.v2_price,
        0
      )
      const trvDifference = calculateDifference(
        formData.v1_trv,
        formData.v2_trv,
        0
      )
      const finAmountDifference = calculateDifference(
        formData.v1_fin_amount,
        formData.v2_fin_amount,
        0
      )
      const termDifference = calculateDifference(
        formData.v1_term,
        formData.v2_term,
        0
      )
      const interestDifference = calculateDifference(
        formData.v1_interest,
        formData.v2_interest,
        0
      )
      const v1AnnualFinance = calculateAnnualFinance(
        formData.v1_interest,
        formData.v1_term,
        formData.v1_fin_amount
      )
      const v2AnnualFinance = calculateAnnualFinance(
        formData.v2_interest,
        formData.v2_term,
        formData.v2_fin_amount
      )
      const annualFinanceDifference = calculateDifference(
        v1AnnualFinance,
        v2AnnualFinance,
        0
      )
      const annInsDifference = calculateDifference(
        formData.v1_ann_ins,
        formData.v2_ann_ins,
        0
      )
      const annMaintenanceDifference = calculateDifference(
        formData.v1_ann_maintenance,
        formData.v2_ann_maintenance,
        0
      )
      const annCostDifference = calculateDifference(
        formData.v1_ann_cost,
        formData.v2_ann_cost,
        0
      )

      // Set form data with calculated values
      setFormData((prevFormData) => ({
        ...prevFormData,
        price_difference: priceDifference,
        trv_difference: trvDifference,
        fin_amount_difference: finAmountDifference,
        term_difference: termDifference,
        interest_difference: interestDifference,
        v1_ann_fin_amount: v1AnnualFinance,
        v2_ann_fin_amount: v2AnnualFinance,
        ann_fin_amount_difference: annualFinanceDifference,
        ann_ins_difference: annInsDifference,
        ann_maintenance_difference: annMaintenanceDifference,
        ann_cost_difference: annCostDifference,
      }))
    }

    calculateAndSetValues()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.v1_price,
    formData.v2_price,
    formData.price_difference,
    formData.v1_trv,
    formData.v2_trv,
    formData.trv_difference,
    formData.v1_fin_amount,
    formData.v2_fin_amount,
    formData.fin_amount_difference,
    formData.v1_term,
    formData.v2_term,
    formData.term_difference,
    formData.v1_interest,
    formData.v2_interest,
    formData.interest_difference,
    formData.v1_ann_fin_amount,
    formData.v2_ann_fin_amount,
    formData.ann_fin_amount_difference,
    formData.v1_ann_ins,
    formData.v2_ann_ins,
    formData.ann_ins_difference,
    formData.v1_ann_maintenance,
    formData.v2_ann_maintenance,
    formData.ann_maintenance_difference,
    formData.v1_ann_cost,
    formData.v2_ann_cost,
    formData.ann_cost_difference,
  ])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleCurrencyChange = (value, name) => {
    setFormData({ ...formData, [name]: value })
  }

  const handlePrimaryRepresentationChange = (value) => {
    setFormData({ ...formData, primary_representation: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    calculateOutput(formData)
  }

  const calculateOutput = async (formData) => {
    setLoading(true)
    setError(null)
    try {
      const { data } = await api.post('', formData)

      const series = convertChartSeries(data)

      setChartSeries(series)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  const convertChartSeries = (chartData) => {
    const tableComparison = Object.values(
      chartData.table_comparison[
        'Opportunity cost (' + formData.growth_rate.toLowerCase() + ')'
      ]
    ).map((value) => {
      const parsedValue = parseFloat(value.toFixed(2))
      return parsedValue < 0 ? Math.abs(parsedValue) : parsedValue
    })

    const tableV1 = Object.values(chartData.table_v1['Vehicle valuation']).map(
      (value) => parseFloat(value.toFixed(2))
    )

    const tableV2 = Object.values(chartData.table_v2['Vehicle valuation']).map(
      (value) => parseFloat(value.toFixed(2))
    )
    return [
      {
        name: 'Cumulative opportunity cost',
        type: 'line',
        data: tableComparison,
      },
      {
        name: 'Vehicle #1 value',
        type: 'line',
        data: tableV1,
      },
      {
        name: 'Vehicle #2 value',
        type: 'line',
        data: tableV2,
      },
    ]
  }

  const getStoredData = async () => {
    setLoading(true)
    try {
      const { data } = await api.get(`get-stored-data`)

      setFormData({
        ...formData,
        primary_representation_id: data.ufs.primary_representation_id || '',
        primary_representation: data.ufs.primary_representation || '',
        projected_inflation_rate: data.ufs.projected_inflation_rate || '',
        effective_date: data.ufs.effective_date,
        real_post_retirement_capital_growth_low:
          data.ufs.real_post_retirement_capital_growth_low || '',
        real_post_retirement_capital_growth_medium:
          data.ufs.real_post_retirement_capital_growth_medium || '',
        real_post_retirement_capital_growth_high:
          data.ufs.real_post_retirement_capital_growth_high || '',
        nominal_post_retirement_capital_growth_low:
          data.ufs.nominal_post_retirement_capital_growth_low || '',
        nominal_post_retirement_capital_growth_medium:
          data.ufs.nominal_post_retirement_capital_growth_medium || '',
        nominal_post_retirement_capital_growth_high:
          data.ufs.nominal_post_retirement_capital_growth_high || '',
      })
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getStoredData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    loading,
    error,
    formData,
    handleChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
    handleSubmit,
    chartSeries,
    setChartSeries,
    chartOptions,
    setChartOptions,
  }
}

export default useVehicleCostTool
