import { useState, useEffect } from "react";
import createAxiosInstance from "@api/Api";
import calculations from "@services/algorithms/calculations";
import helpers from "@utils/helpers";
import numbers from "@services/algorithms/numbers";
import growthRateHandler from "@services/algorithms/growthRateHandler";

const useMoneyLastTool = () => {
  const api = createAxiosInstance("standard/retirement/money-last");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const {
    calculateCurrentAge,
    calculateYearsToRetirement,
    calculateRetirementDate,
    calculateRetirementLongevity,
    calculateRetirementIncome,
  } = numbers();
  const { calculateMultiple, yearsToDepletion } = calculations();

  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState({
    // Miscellaneous
    growth_rate: "Low",
    primary_representation: "",
    // Generic inputs
    effective_date: "",
    projected_inflation_rate: 0,
    date_of_birth: "",
    retirement_date: "",
    retirement_age: 0,
    current_age: 0,
    years_to_retirement: 0,
    life_expectancy: 0,
    retirement_longevity: 0,
    // Growth & cost assumptions
    real_post_retirement_capital_growth_low: 0,
    real_post_retirement_capital_growth_medium: 0,
    real_post_retirement_capital_growth_high: 0,
    nominal_post_retirement_capital_growth_low: 0,
    nominal_post_retirement_capital_growth_medium: 0,
    nominal_post_retirement_capital_growth_high: 0,
    post_retirement_costs: 0,
    real_growth_low: 0,
    real_growth_medium: 0,
    real_growth_high: 0,
    nominal_growth_low: 0,
    nominal_growth_medium: 0,
    nominal_growth_high: 0,
    // Smart tool specific inputs
    annual_pre_tax_income: 0,
    real_growth_in_income: 0,
    nominal_growth_in_income: 0,
    post_retirement_income_percentage: 0,
    nominal_projected_retirement_fund_value: 0,
    real_projected_retirement_fund_value: 0,
    // Output
    real_target_income_at_retirement: 0,
    nominal_target_income_at_retirement: 0,
    multiple_on_retirement: 0,
    full_depletion_growth_rate_low: 0,
    full_depletion_growth_rate_medium: 0,
    full_depletion_growth_rate_high: 0,
  });

  const {
    calculateNominalGrowthRate,
    calculateRealGrowthRate,
    handleNominalChange,
    handleRealChange,
    calculateFutureValue,
    calculatePresentValue,
    handleFutureChange,
    handlePresentChange,
  } = growthRateHandler(formData, setFormData);

  useEffect(() => {
    if (formData.date_of_birth && formData.retirement_age) {
      const birthDate = formData.date_of_birth;
      const retirementAge = formData.retirement_age;
      const effectiveDate = formData.effective_date;

      setFormData((prevFormData) => ({
        ...prevFormData,
        current_age: calculateCurrentAge(birthDate, effectiveDate),
        years_to_retirement: calculateYearsToRetirement(effectiveDate, formData.retirement_date),
        projected_retirement_date: calculateRetirementDate(birthDate, retirementAge),
        retirement_longevity: calculateRetirementLongevity(retirementAge, formData.life_expectancy),
      }));
    }
  }, [formData.date_of_birth, formData.retirement_age, formData.life_expectancy, formData.effective_date]);

  useEffect(() => {
    const calculateAndSetValues = () => {
      // Calculate adjusted_pre_tax_salary
      const realTargetIncome = calculateRetirementIncome(
        formData.annual_pre_tax_income,
        formData.effective_date,
        formData.retirement_date,
        formData.real_growth_in_income,
        formData.post_retirement_income_percentage
      );
      const nominalTargetIncome = calculateRetirementIncome(
        formData.annual_pre_tax_income,
        formData.effective_date,
        formData.retirement_date,
        formData.nominal_growth_in_income,
        formData.post_retirement_income_percentage
      );

      // Calculate net growth rates
      const realGrowthLow = calculateRealGrowthRate(
        formData.real_post_retirement_capital_growth_low,
        formData.post_retirement_costs
      );
      const realGrowthMedium = calculateRealGrowthRate(
        formData.real_post_retirement_capital_growth_medium,
        formData.post_retirement_costs
      );
      const realGrowthHigh = calculateRealGrowthRate(
        formData.real_post_retirement_capital_growth_high,
        formData.post_retirement_costs
      );
      const nominalGrowthLow = calculateRealGrowthRate(
        formData.nominal_post_retirement_capital_growth_low,
        formData.post_retirement_costs
      );
      const nominalGrowthMedium = calculateRealGrowthRate(
        formData.nominal_post_retirement_capital_growth_medium,
        formData.post_retirement_costs
      );
      const nominalGrowthHigh = calculateRealGrowthRate(
        formData.nominal_post_retirement_capital_growth_high,
        formData.post_retirement_costs
      );

      // Calculate required multiples
      const multipleOnRetirement = calculateMultiple(formData.real_projected_retirement_fund_value, realTargetIncome);

      // Calculate years to depletion
      const fullDepletionGrowthRateLow = yearsToDepletion(realGrowthLow, multipleOnRetirement);
      const fullDepletionGrowthRateMedium = yearsToDepletion(realGrowthMedium, multipleOnRetirement);
      const fullDepletionGrowthRateHigh = yearsToDepletion(realGrowthHigh, multipleOnRetirement);

      // Set form data with calculated values
      setFormData((prevFormData) => ({
        ...prevFormData,
        real_target_income_at_retirement: realTargetIncome,
        nominal_target_income_at_retirement: nominalTargetIncome,
        multiple_on_retirement: multipleOnRetirement,
        full_depletion_growth_rate_low: fullDepletionGrowthRateLow,
        full_depletion_growth_rate_medium: fullDepletionGrowthRateMedium,
        full_depletion_growth_rate_high: fullDepletionGrowthRateHigh,
        real_growth_low: realGrowthLow,
        real_growth_medium: realGrowthMedium,
        real_growth_high: realGrowthHigh,
        nominal_growth_low: nominalGrowthLow,
        nominal_growth_medium: nominalGrowthMedium,
        nominal_growth_high: nominalGrowthHigh,
      }));
    };

    setTimeout(() => {
      calculateAndSetValues();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData.primary_representation,
    formData.real_post_retirement_capital_growth_low,
    formData.nominal_post_retirement_capital_growth_low,
    formData.real_post_retirement_capital_growth_medium,
    formData.nominal_post_retirement_capital_growth_medium,
    formData.real_post_retirement_capital_growth_high,
    formData.nominal_post_retirement_capital_growth_high,
    formData.post_retirement_costs,
    formData.real_projected_retirement_fund_value,
    formData.nominal_post_retirement_capital_growth_high,
    formData.post_retirement_income_percentage,
  ]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      nominal_post_retirement_capital_growth_low: calculateNominalGrowthRate(
        formData.real_post_retirement_capital_growth_low,
        formData.projected_inflation_rate
      ),
      nominal_post_retirement_capital_growth_medium: calculateNominalGrowthRate(
        formData.real_post_retirement_capital_growth_medium,
        formData.projected_inflation_rate
      ),
      nominal_post_retirement_capital_growth_high: calculateNominalGrowthRate(
        formData.real_post_retirement_capital_growth_high,
        formData.projected_inflation_rate
      ),
      nominal_growth_in_income: calculateNominalGrowthRate(
        formData.real_growth_in_income,
        formData.projected_inflation_rate
      ),
    }));
  }, [formData.projected_inflation_rate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: parseInt(value) });
  };

  const handleCurrencyChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handlePrimaryRepresentationChange = (value) => {
    setFormData({ ...formData, primary_representation: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateOutput(formData);
  };

  const calculateOutput = async (formData) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: labels,
        },
      }));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`get-stored-data`);

      setFormData({
        ...formData,
        primary_representation: data.ufs.primary_representation || "",
        growth_rate: data.ufs.growth_rate || "",
        effective_date: data.ufs.effective_date || "",
        projected_inflation_rate: data.ufs.projected_inflation_rate || "",
        date_of_birth: data.ufs.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        current_age: calculateCurrentAge(data.ufs.date_of_birth, data.ufs.effective_date) || "",
        retirement_date: data.ufs.retirement_date || "",
        years_to_retirement: calculateYearsToRetirement(data.ufs.effective_date, data.ufs.retirement_date) || "",
        life_expectancy: data.ufs.life_expectancy || "",
        retirement_longevity: calculateRetirementLongevity(data.ufs.retirement_age, data.ufs.life_expectancy) || "",
        post_retirement_costs: data.ufs.post_retirement_costs || "",
        real_post_retirement_capital_growth_low: data.ufs.real_post_retirement_capital_growth_low || "",
        real_post_retirement_capital_growth_medium: data.ufs.real_post_retirement_capital_growth_medium || "",
        real_post_retirement_capital_growth_high: data.ufs.real_post_retirement_capital_growth_high || "",
        nominal_post_retirement_capital_growth_low: data.ufs.nominal_post_retirement_capital_growth_low || "",
        nominal_post_retirement_capital_growth_medium: data.ufs.nominal_post_retirement_capital_growth_medium || "",
        nominal_post_retirement_capital_growth_high: data.ufs.nominal_post_retirement_capital_growth_high || "",
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || "",
        real_growth_in_income: data.ufs.real_growth_in_income || "",
        nominal_growth_in_income: data.ufs.nominal_growth_in_income || "",
        post_retirement_income_percentage: data.ufs.post_retirement_income_percentage || "",
        nominal_projected_retirement_fund_value:
          calculateFutureValue(
            data.ufs.real_projected_retirement_fund_value,
            data.ufs.projected_inflation_rate,
            data.ufs.years_to_retirement
          ) || "",
        real_projected_retirement_fund_value: data.ufs.real_projected_retirement_fund_value || "",
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const convertChartSeries = (chartData) => {
    const years = Object.values(chartData.years);

    const annualWithdrawal = Object.values(chartData.annual_withdrawals).map((value) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const residualValue = Object.values(chartData.residual_values).map((value) =>
      parseFloat(value.replace(/,/g, "")).toFixed(0)
    );

    const series = [
      {
        name: "Annual withdrawals",
        type: "column",
        color: "#ed7d31",
        data: annualWithdrawal,
      },
      {
        name: "Residual capital",
        type: "line",
        color: "#a5a5a5",
        data: residualValue,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    formData,
    handleChange,
    handleNumberChange,
    handleCurrencyChange,
    handleSubmit,
    handlePrimaryRepresentationChange,
    chartSeries,
    setChartSeries,
    chartOptions,
    setChartOptions,
    handleNominalChange,
    handleRealChange,
    handleFutureChange,
    handlePresentChange,
  };
};

export default useMoneyLastTool;
