import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";
import { MdOutlineCommentsDisabled } from "react-icons/md";

export default function Select({
  label,
  defaultItem = null,
  id,
  name,
  value,
  onChange,
  items,
  required,
  placeholder,
  twMergeClassName = null,
  variant,
  disabled = false,
  ...props
}) {
  const inputVariants = cva(
    ` ${twMergeClassName ?? ""} font-bold shadow-sm border border-gray-300 sm:text-[12px] rounded-md h-[30px] focus:ring-primary-500 focus:border-primary-500 block w-full p-1`,
    {
      variants: {
        variant: {
          white: `bg-gray-50 border border-gray-300 text-gray-900 ${disabled ? "cursor-not-allowed px-6" : ""}`,
          red: `border border-gray-300 text-white icon-white ${disabled ? "bg-[#85322f] cursor-not-allowed px-6" : "bg-[#a96f6d]"}`,
          green: `border border-gray-300 text-white icon-white ${disabled ? "bg-[#008308] cursor-not-allowed px-6" : "bg-[#4ca852]"}`,
          orange: "bg-[#f2e1c3] border border-gray-300 text-black",
        },
      },
    }
  );

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="block mb-1.5 text-[12px] font-bold text-gray-900"
        >
          {label} {required ? " *" : ""}
        </label>
      )}
      <div className="relative w-full">
        <select
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          required={required}
          disabled={disabled}
          className={cn(inputVariants({ variant }))}
        >
          {defaultItem === null ? <></> : <option value="">{defaultItem}</option>}
          {items?.map((item) => (
            <option
              key={item.id}
              value={item.id}
            >
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
