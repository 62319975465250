import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  useEffect(() => {
    logout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);
  return <></>;
};

export default Logout;
