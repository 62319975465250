const RulesOfLife = () => {
  return (
    <div className="text-sm max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-scroll overflow-x-hidden py-4">
      <p className="font-medium">
        <span className="font-bold">1. Pay yourself first: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>As soon as you get paid, put money into savings. Automating this is even better.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">2. Keep a 6-month emergency fund: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>If you have multiple streams of incomes, you can go as low as 3 months.</p>
        </li>
        <li>
          <p>If starting out on your own, you could need as much as 12 months.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">3. Budget using the 50 / 30 / 20 rule: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>50% for needs</p>
        </li>
        <li>
          <p>30% for wants</p>
        </li>
        <li>
          <p>20% towards saving/investing</p>
        </li>
        <li>
          <p>This is the bare minimum!</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">4. Divide your bonus into thirds: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>1/3 for fun</p>
        </li>
        <li>
          <p>1/3 for retirement</p>
        </li>
        <li>
          <p>1/3 for debt paydown (add to retirement if only low-interest debt)</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">
          5. Put all, or large percentage, of your raises into savings and investments:{" "}
        </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>This helps avoids lifestyle inflation and moves up your retirement date.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">6. Avoid high-interest debt: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>If you have any, use the avalanche or snowball method to pay it off (Google them).</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">7. Always take an employer retirement contribution: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>Many employers will match a percentage of your paycheck.</p>
        </li>
        <li>
          <p>This money is getting an immediate 100% return.</p>
        </li>
        <li>
          <p>If you turn this down, it’s the same as turning down a raise.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">8. Your home payment: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>Mortgage interest & insurance should cost less than 25% of your monthly income.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">9. When buying a car use the 20 / 4 / 10 Rule: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>20% down payment</p>
        </li>
        <li>
          <p>4 years loan</p>
        </li>
        <li>
          <p>{`< 10 % of your monthly income`}</p>
        </li>
        <li>
          <p>You can also go to buy older vehicles with cash.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">9. When buying a car use the 20 / 4 / 10 Rule: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>20% down payment</p>
        </li>
        <li>
          <p>4 years loan</p>
        </li>
        <li>
          <p>{`< 10 % of your monthly income`}</p>
        </li>
        <li>
          <p>You can also go to buy older vehicles with cash.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">10. You should save at least 15%: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>Save at least 15% of the income for the retirement.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">11. Your age subtracted from 100: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>It represents the percentage of stocks you have in your portfolio.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">12. The stock market: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>
            {`It has the highest long-term average return of all major assets (>12% p.a. over 25 years) but with the
            highest “risk”`}
          </p>
        </li>
        <li>
          <p>
            Generally, long-term investment strategies need to include stocks to achieve sufficient returns above
            inflation.
          </p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">13. The rule of 72: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>It tells you how long it will take your investment to double</p>
        </li>
        <li>
          <p>{`Example: The stock market returns 10%, so 72 / 10 = 7.2 years to double your money`}</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">14. The 4 % rule: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>
            It says you can safely withdraw 4% of your starting investment balance each year (adjust for inflation in
            subsequent years) and not run out of money.
          </p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">15. Your Net Worth: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>It should be equal to (your age * pre-tax income) / 10</p>
        </li>
        <li>
          <p>{`Example: If you are 35 years old and ZAR 100,000 in annual income, then your net worth should be at least ZAR 350,000 (35 x 100,000 / 10).`}</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">16. Have at least five times… </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>You should have at least 5x your gross salary in term life insurance pre-retirement.</p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">17. Before spending money: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>
            You should wait 24 hours and ask: “do I still want it?” If you do, go ahead and buy it. This will save you
            from a lot of impulse purchases.
          </p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">18. Save for retirement first: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>
            First save for your retirement, then your children’s education – do not sacrifice your future for theirs.
          </p>
        </li>
      </ol>

      <p className="font-medium">
        <span className="font-bold">19. Value Time: </span>
      </p>
      <ol className="ml-8 space-y-2 mb-4 my-2 font-medium list-disc">
        <li>
          <p>Value time over money and experience over things</p>
        </li>
      </ol>

      <p className="font-bold text-right">
        <i>Source: various</i>
      </p>
    </div>
  );
};

export default RulesOfLife;
