import useResetPassword from "@api/Auth/useResetPassword";
import appContext from "@context/app-context";
import Logo from "@elements/Logo";
import PageTitle from "@elements/PageTitle";
import Button from "@form/Button";
import Input from "@form/Input";
import AuthLayout from "@layouts/AuthLayout";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const { isLoading, forgotPassword } = useContext(appContext);
  const { formData, handleChange } = useResetPassword();

  const navigate = useNavigate();

  return (
    <AuthLayout>
      <PageTitle title="Forgot Password" />
      <div className="w-full h-screen">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:min-h-screen pt:mt-0">
          <div className="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow">
            <a
              href="/"
              className="flex items-center justify-center mb-8 lg:mb-10"
            >
              <Logo className="mr-4 h-24" />
            </a>
            <h2 className="text-2xl font-bold text-gray-900 text-center">Please enter your email</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                forgotPassword(formData.email);
              }}
              className="mt-8 space-y-6"
              action="#"
            >
              <div>
                <Input
                  label="Your email"
                  name="email"
                  id="email"
                  type="email"
                  placeholder="name@email.com"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  variant="white"
                />
              </div>
              <Button
                type="submit"
                name="Submit"
                loading={isLoading}
              />
              <div className="text-[12px] font-medium text-gray-500">
                Already have an account?{" "}
                <div
                  onClick={() => {
                    navigate("/");
                  }}
                  className="text-primary-700 hover:underline cursor-pointer inline-block"
                >
                  Sign in
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};
