import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";
import CurrencyInput from "react-currency-input-field";
import { MdOutlineCommentsDisabled } from "react-icons/md";

export default function Percentage(props) {
  const {
    label,
    name,
    id,
    value,
    placeholder,
    required = false,
    disabled = false,
    onChange,
    twMergeClassName = null,
    variant,
  } = props;

  const inputVariants = cva(
    `${twMergeClassName ?? ""} focus:ring-primary-500 focus:border-primary-500 p-1 h-[30px] rounded-md sm:text-[12px] block w-full sm:min-w-[70px] font-bold `,
    {
      variants: {
        variant: {
          white: `bg-gray-50 border border-gray-300 text-gray-900 ${disabled ? "cursor-not-allowed px-6" : ""}`,
          red: `border border-gray-300 text-white icon-white ${disabled ? "bg-[#85322f] cursor-not-allowed px-6" : "bg-[#a96f6d]"}`,
          green: `border border-gray-300 text-white icon-white ${disabled ? "bg-[#008308] cursor-not-allowed px-6" : "bg-[#4ca852]"}`,
          orange: "bg-[#f2e1c3] border border-gray-300 text-black",
        },
      },
    }
  );

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="block mb-1.5 text-[12px] font-bold text-gray-900 "
        >
          {label}
        </label>
      )}
      <div className="relative w-full">
        <CurrencyInput
          name={name}
          id={id}
          className={cn(inputVariants({ variant }))}
          placeholder={placeholder}
          value={value === "Infinity" ? "0" : value}
          disabled={disabled}
          required={required}
          decimalsLimit={2}
          decimalScale={2}
          suffix={" %"}
          onValueChange={onChange}
          decimalSeparator="."
          groupSeparator=" "
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        {disabled && (
          <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
            <MdOutlineCommentsDisabled className={`h-[12px] ${variant !== "white" ? "text-white" : ""}`} />
          </div>
        )}
      </div>
    </>
  );
}
