import createAxiosInstance from '@api/Api';
import helpers from '@utils/helpers';
import { useEffect, useReducer } from 'react';

const useEducationCostTool = () => {
  const api = createAxiosInstance('standard/lifestyle/education-cost');
  const { chartOptionsDefault } = helpers();

  const initialState = {
    loading: false,
    error: null,
    chartSeries: [],
    chartOptions: chartOptionsDefault,
    formData: {
      amount: '',
      education_inflation: '0.09',
      effective_rate_cost: '0.0091743119266055',
      investment_growth: '0.1',
      monthly_investment_return: '0.0797414042890376',
      current_savings_pm: '600',
      effective_rate_savings: '0.0476190476190476',
      years_to_save: '17',
      current_value: '100000',
      existing_savings: 6894.83815214387,
      cpi: '0.02', // Added CPI value under global_parameters
      projected_savings_years: '25', // Added Projected Savings Years under global_parameters
      annual_amount_required_post_check: '5000', // Example value for Annual Amount Required Post Check
      investment_return: '0.07', // Example value for Investment Return
      phases: [
        {
          dependant: 'Pre-primary',
          age_at_start_of_phase: 3,
          include: 'Yes',
          amount: 25000,
          input: 3,
          start_year: 2023,
          start_age: 3,
        },
        {
          dependant: 'Primary - 1st stage',
          age_at_start_of_phase: 6,
          include: 'Yes',
          amount: 32000,
          input: 3,
          start_year: 2026,
          start_age: 6,
        },
        {
          dependant: 'Primary - 2nd stage',
          age_at_start_of_phase: 9,
          include: 'No',
          amount: 45000,
          input: 4,
          start_year: 2029,
          start_age: 9,
        },
        {
          dependant: 'Secondary - initial stage',
          age_at_start_of_phase: 13,
          include: 'Yes',
          amount: 62000,
          input: 2,
          start_year: 2033,
          start_age: 13,
        },
        {
          dependant: 'Secondary - final stage',
          age_at_start_of_phase: 15,
          include: 'Yes',
          amount: 68000,
          input: 3,
          start_year: 2035,
          start_age: 15,
        },
        {
          dependant: 'Tertiary - bachelor stage',
          age_at_start_of_phase: 18,
          include: 'No',
          amount: 87375,
          input: 3,
          start_year: 2038,
          start_age: 18,
        },
        {
          dependant: 'Tertiary - postgraduate stage',
          age_at_start_of_phase: 21,
          include: 'No',
          amount: 100000,
          input: 2,
          start_year: 2041,
          start_age: 21,
        },
      ],
    },
  };

  const actionTypes = {
    SET_LOADING: 'SET_LOADING',
    SET_AMOUNT: 'SET_AMOUNT',
    SET_ERROR: 'SET_ERROR',
    SET_FORM_DATA: 'SET_FORM_DATA',
    SET_CHART_SERIES: 'SET_CHART_SERIES',
    SET_CHART_OPTIONS: 'SET_CHART_OPTIONS',
    UPDATE_PHASE: 'UPDATE_PHASE',
  };

  const setLoading = (loading) => ({
    type: actionTypes.SET_LOADING,
    payload: loading,
  });
  const setError = (error) => ({ type: actionTypes.SET_ERROR, payload: error });
  const setFormData = (data) => ({
    type: actionTypes.SET_FORM_DATA,
    payload: data,
  });
  const setChartSeries = (series) => ({
    type: actionTypes.SET_CHART_SERIES,
    payload: series,
  });
  const setChartOptions = (options) => ({
    type: actionTypes.SET_CHART_OPTIONS,
    payload: options,
  });

  const reducer = (state, action) => {
    const handlers = {
      [actionTypes.SET_LOADING]: () => ({ ...state, loading: action.payload }),
      [actionTypes.SET_ERROR]: () => ({ ...state, error: action.payload }),
      [actionTypes.SET_FORM_DATA]: () => ({
        ...state,
        formData: { ...state.formData, ...action.payload },
      }),
      [actionTypes.SET_CHART_SERIES]: () => ({
        ...state,
        chartSeries: action.payload,
      }),
      [actionTypes.SET_CHART_OPTIONS]: () => ({
        ...state,
        chartOptions: { ...state.chartOptions, ...action.payload },
      }),
      [actionTypes.SET_AMOUNT]: () => {
        const totalAmount = state.formData.phases
                            .filter((phase) => phase.include === 'Yes')
                            .reduce((sum, phase) => sum + phase.amount, 0);
        return {
          ...state,
          formData: {
            ...state.formData,
            amount: totalAmount,
          }
        }

      },
      [actionTypes.UPDATE_PHASE]: () => {
        const updatedPhases = state.formData.phases.map((phase) => {
          if (phase.dependant === action.payload.dependant) {
            return { ...phase, ...action.payload.values };
          }
          return phase;
        });
        const updatedState = {
          ...state,
          formData: { ...state.formData, phases: updatedPhases },
        };
        const totalAmount = updatedState.formData.phases
                            .filter((phase) => phase.include === 'Yes')
                            .reduce((sum, phase) => sum + phase.amount, 0);
        return {
          ...updatedState,
          formData: {
            ...updatedState.formData,
            amount: totalAmount
          },
        };
      },
    };

    return handlers[action.type] ? handlers[action.type]() : state;
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setFormData({ [name]: value }));
  };

  const handlePrimaryRepresentationChange = (value) => {
    dispatch(setFormData({ primary_representation: value }));
  };

  const handleDependantPhaseChange = (e) => {
    const { name, value } = e.target;
    const [phase, key] = name.split('__');
    dispatch({
      type: actionTypes.UPDATE_PHASE,
      payload: {
        dependant: phase,
        values: {
          [key]: value || 0,
        },
      },
    });
  };

  const handleDependantPhaseCurrencyChange = (value, name) => {
    const [phase, key] = name.split('__');
    dispatch({
      type: actionTypes.UPDATE_PHASE,
      payload: {
        dependant: phase,
        values: {
          [key]: value,
        },
      },
    });

  };

  const handleCurrencyChange = (value, name) => {
    dispatch(setFormData({ [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(setError(null));
    try {
      const { data } = await api.post('', state.formData);

      const { series, labels } = convertChartSeries(data);

      console.log(series);
      console.log(labels);

      dispatch(setChartSeries(series));
      dispatch(
        setChartOptions({
          xaxis: { categories: labels },
        })
      );
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const convertChartSeries = (chartData) => {
    const years = Object.values(chartData['year']['data']);

    const series = [
      chartData['end_fund_value'],
      chartData['education_cost_pre_primary'],
      chartData['education_cost_primary_1st_stage'],
      chartData['education_cost_primary_2nd_stage'],
      chartData['education_cost_secondary_initial_stage'],
      chartData['education_cost_secondary_final_stage'],
      chartData['education_cost_tertiary_bachelor_stage'],
      chartData['education_cost_tertiary_postgraduate_stage'],
    ];

    const labels = years.map((year, index) =>
      index === 0 ? 'Inception' : `Year ${year}`
    );

    return { series, labels };
  };

  const getStoredData = async () => {
    dispatch(setLoading(true));
    try {
      const { data } = await api.get(`get-stored-data`);
      dispatch(
        setFormData({
          primary_representation_id: data.ufs.primary_representation_id || '',
          primary_representation: data.ufs.primary_representation || '',
          projected_inflation_rate: data.ufs.projected_inflation_rate || '',
          effective_date: data.ufs.effective_date || '',
        })
      );
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...state,
    handleChange,
    handleCurrencyChange,
    handleDependantPhaseChange,
    handleDependantPhaseCurrencyChange,
    handlePrimaryRepresentationChange,
    handleSubmit,
  };
};

export default useEducationCostTool;
