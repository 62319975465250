import useAdditionalSettings from "@api/User/useAdditionalSettings";
import CheckboxFull from "@form/CheckboxFull";
import Button from "@form/Button";

const AdditionalSettings = () => {
  const { preferences, userPreferences, handleCheckboxChange, handleSubmit, loading } = useAdditionalSettings();

  return (
    <>
      <h3 className="text-xl font-semibold mb-3">Additional settings</h3>
      <form onSubmit={handleSubmit}>
        <div className="divide-y divide-gray-200">
          {preferences.map((preference) => (
            <div
              className="flex items-center justify-between py-4"
              key={preference.id}
            >
              <CheckboxFull
                id={preference.id}
                name={preference.name}
                array={userPreferences}
                slug={preference.slug}
                classTarget="additional-settings"
                handleCheckboxChange={handleCheckboxChange}
              />
            </div>
          ))}
        </div>
        <div className="mt-6">
          <Button
            type="submit"
            name="Save"
            loading={loading}
          />
        </div>
      </form>
    </>
  );
};

export default AdditionalSettings;
