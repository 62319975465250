import createAxiosInstance from "@api/Api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const useHome = () => {
  const api = createAxiosInstance("user");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const closeModal = () => setModal(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const closeWelcomeModal = () => setWelcomeModal(false);

  const navigate = useNavigate();

  const smartToolClick = async () => {
    try {
      const { data } = await api.get(`minimum-data-check`);

      if (data.completed) {
        navigate("/standard");
      } else {
        toggleModal();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const welcomeHideCheck = async () => {
    try {
      const { data } = await api.get(`welcome/hide-check`);

      if (!data.welcome_hide) {
        setWelcomeModal(true);
      } else {
        setWelcomeModal(false);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const saveWelcomeHide = async () => {
    const hideWelcome = document.getElementById("welcome-hide").checked;

    setLoading(true);
    try {
      const { data } = await api.put(`welcome/update`, {
        welcome_hide: hideWelcome,
      });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    welcomeHideCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    smartToolClick,
    saveWelcomeHide,
    modal,
    closeModal,
    toggleModal,
    welcomeModal,
    closeWelcomeModal,
  };
};

export default useHome;
