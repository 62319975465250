import Card from "@/components/Element/Card";
import SelectFundSource from "@/components/Form/SelectFundSourceToggle";
import useLoanAffordabilityTool from "@api/Standard/Lifestyle/useLoanAffordabilityTool";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import PercentageSwitch from "@form/PercentageSwitch";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import useVisibility from "@utils/useVisibility";
import { Fragment, JSXElementConstructor, ReactElement, ReactNode, ReactPortal, useState } from "react";
import Chart from "react-apexcharts";
import { IoCloseCircleOutline } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

const { capitalizeFirstLetter } = words();
function LoanAffordability() {
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    formData,
    handleSubmit,
    handleChange,
    chartSeries,
    chartOptions,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
  } = useLoanAffordabilityTool();

  const [showHelpPopup, setShowHelpPopup] = useState<boolean>(
    localStorage.getItem("loan-affordability-help-popup") === "true"
  );

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to demonstrate the impact of taking out a loan / multiple loans on your retirement
              or investments capital over time, as well as the reduction in your current disposable income. Inputs
              required are:
              <ul className="pl-4">
                <li className="my-1">
                  a) Your loan details including outstanding amounts, outstanding terms, interest rates and payments. We
                  use the payments (made with after-tax money) to determine the impact on disposable income.
                </li>
                <li className="my-1">b) Specific inputs include annual income and tax rate (default available)</li>
                <li className="my-1">
                  c) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              The long-term impact of these payments is calculated as follows:
              <ul className="pl-4">
                <li className="my-1">
                  a) If the total of the after-tax loan repayment had been invested, this would have generated
                  investment capital over time. We calculate the projected amounts at different growth rates to
                  retirement. This represents a reduction in final <strong>investment</strong> capital.
                </li>
                <li className="my-1">
                  b) If the total of the equivalent pre-tax loan repayment had been added to retirement contributions,
                  this would have generated additional retirement capital over time. We calculate the projected amounts
                  at different growth rates to retirement. This represents a reduction in final{" "}
                  <strong>retirement</strong> capital.
                </li>
                <li className="my-1">c) Note that the difference in the pot sizes is largely a function of tax.</li>
              </ul>
            </li>
            <li className="mb-2">
              The chart shows the overall reduction in the loans outstanding, the cumulative loan repayments as against
              lines showing the impact of investing the monies at different growth rates – note how small amounts in the
              beginning can become significant amounts over time as growth rate compound.
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("loan-affordability-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  const [data, setData] = useState([
    [
      {
        label: "Loan #",
        name: `description`,
        inputType: "description",
      },
      {
        value: "",
        name: "outstanding_loan0",
        inputType: "currency",
      },
      {
        value: "",
        name: "outstanding_term0",
        inputType: "input",
      },
      {
        value: "",
        name: "completion_date0",
        inputType: "date",
        disabled: true,
      },
      {
        value: "",
        name: "interest_rate0",
        inputType: "percentage",
      },
      {
        value: "",
        name: "after_tax_monthly_payments0",
        inputType: "currency",
        disabled: true,
      },
      {
        value: "",
        name: "pre_tax_equivalent0",
        inputType: "currency",
        disabled: true,
      },
    ],
  ]);

  const handleOnChange = (e: { target: { value: string | undefined } }, row: number, col: string | number) => {
    const newData = data.map((d, i) => {
      if (i === row) {
        d[col as any].value = e.target.value;
      }

      return d;
    });
    setData(newData);
  };

  const addRow = () => {
    setData([
      ...data,
      [
        {
          label: "Loan #",
          name: `description`,
          inputType: "description",
        },
        {
          value: "",
          name: `outstanding_loan${data.length}`,
          inputType: "currency",
        },
        {
          value: "",
          name: `outstanding_term${data.length}`,
          inputType: "input",
        },
        {
          value: "",
          name: `completion_date${data.length}`,
          inputType: "date",
          disabled: true,
        },
        {
          value: "",
          name: `interest_rate${data.length}`,
          inputType: "percentage",
        },
        {
          value: "",
          name: `after_tax_monthly_payments${data.length}`,
          inputType: "currency",
          disabled: true,
        },
        {
          value: "",
          name: `pre_tax_equivalent${data.length}`,
          inputType: "currency",
          disabled: true,
        },
      ],
    ]);
  };

  const removeRow = (index: number) => {
    const _data = [...data];
    _data.splice(index, 1);
    setData(_data);
  };

  const LoanRow = ({ data, handleOnChange, addRow, removeRow }: any) => {
    return (
      <>
        {data.map((items: any[], i1: number) => (
          <Fragment key={i1}>
            <tr>
              {items.map(
                (
                  item: {
                    name: string | undefined;
                    inputType: string | undefined;
                    disabled: boolean | undefined;
                    label:
                      | string
                      | number
                      | boolean
                      | ReactElement<any, string | JSXElementConstructor<any>>
                      | Iterable<ReactNode>
                      | ReactPortal
                      | null
                      | undefined;
                    value: string | undefined;
                  },
                  i2: number
                ) => (
                  <Fragment key={i2}>
                    <td className="text-center text-[12px] font-medium p-2 border-[#999] border-b-[1px] border-l-[1px] border-r-[1px]">
                      {item.inputType === "description" ? (
                        <div className="flex items-center leading-[normal] font-bold">
                          {data.length > 1 && (
                            <IoCloseCircleOutline
                              onClick={() => removeRow(i1)}
                              color="red"
                              className="mr-1 cursor-pointer"
                            />
                          )}
                          {item.label}
                          {i1 + 1}
                        </div>
                      ) : item.inputType === "input" ? (
                        <Input
                          type="number"
                          name=""
                          onChange={(e) => handleOnChange(e, i1, i2)}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      ) : item.inputType === "currency" ? (
                        <Currency
                          name=""
                          onChange={(e: any) => handleOnChange(e, i1, i2)}
                          variant={item.disabled ? "red" : "green"}
                          disabled={item.disabled}
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      ) : item.inputType === "percentage" ? (
                        <Percentage
                          name=""
                          onChange={(e: any) => handleOnChange(e, i1, i2)}
                          variant="green"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      ) : item.inputType === "date" ? (
                        <Input
                          type="date"
                          name=""
                          onChange={(e: any) => handleOnChange(e, i1, i2)}
                          variant={item.disabled ? "red" : "green"}
                          disabled={item.disabled}
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      ) : (
                        <></>
                      )}
                    </td>
                  </Fragment>
                )
              )}
            </tr>

            {i1 === data.length - 1 && (
              <>
                <tr>
                  <td className="p-2 text-[12px] font-bold border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    Total
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Currency
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 text-[12px] font-bold text-center border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Input
                      name=""
                      id=""
                      type="date"
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]" />
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Currency
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                  <td className="p-2 border-[#999] border-b-[1px] border-t-[1px] border-r-[1px] border-l-[1px]">
                    <Currency
                      name=""
                      id=""
                      disabled
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <Button
                      onClick={addRow}
                      name="Add"
                      className="mt-4 mb-2"
                    />
                  </td>
                </tr>
              </>
            )}
          </Fragment>
        ))}
      </>
    );
  };

  const handleFundSourceChange = () => {
    console.log("clicked");
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Affordability calculator & the impact of loans"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Loan Affordability" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center gap-x-[50px]">
                <div className="flex items-center justify-center">
                  <AnalysisType
                    primaryRepresentation={formData?.primary_representation}
                    handleChange={handlePrimaryRepresentationChange}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <SelectFundSource
                    fundSource={formData?.primary_representation}
                    handleChange={handleFundSourceChange}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <Button
                    type="submit"
                    name="Calculate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="number"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="number"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="p-2 pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth pre-retirement
                          </td>
                          <td className="p-2">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_low"
                              nominalField="nominal_post_retirement_capital_growth_low"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_medium"
                              nominalField="nominal_post_retirement_capital_growth_medium"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <PercentageSwitch
                              primaryRepresentation={formData.primary_representation}
                              realField="real_post_retirement_capital_growth_high"
                              nominalField="nominal_post_retirement_capital_growth_high"
                              formData={formData}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="p-2 text-[12px] font-bold">Annual costs</td>
                          <td className="p-2">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                          <td className="p-2">
                            <Percentage
                              name="investment_costs"
                              id="investment_costs"
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center `)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-[12px]">Low</th>
                        <th className="text-[12px]">Medium</th>
                        <th className="text-[12px]">High</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="p-2 text-[12px] font-bold">Net growth pre-retirement</td>
                      <td className="p-2">
                        <Percentage
                          name="netGrowthLow"
                          id="netGrowthLow"
                          disabled
                          value={formData.net_growth_low}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="p-2">
                        <Percentage
                          name="netGrowthMedium"
                          id="netGrowthMedium"
                          disabled
                          value={formData.net_growth_medium}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                      <td className="p-2">
                        <Percentage
                          name="netGrowthHigh"
                          id="netGrowthHigh"
                          disabled
                          value={formData.net_growth_high}
                          variant="red"
                          twMergeClassName={twMerge(`text-center `)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Currency
                      label="Annual pre-tax income"
                      name=""
                      id=""
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </div>
                  <div className="col-span-1">
                    <Percentage
                      label="Tax rate"
                      name="tax_rate"
                      id="tax_rate"
                      type="date"
                      onChange={handleCurrencyChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center `)}
                    />
                  </div>
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="scrollbar overflow-x-scroll">
                  <table className="table-auto w-full">
                    <thead className="bg-[#ffffff]">
                      <tr className="whitespace-nowrap bg-[#f1f1f1]">
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Description
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Outstanding loan
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Outstanding term (months)
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Completion date
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Interest rate
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          After-tax monthly payments
                        </th>
                        <th className="text-[12px] font-semibold p-2 border-r-[1px] border-l-[1px] border-b-[1px] border-t-[1px] border-[#999]">
                          Pre-tax equivalent
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <LoanRow
                        data={data}
                        addRow={addRow}
                        removeRow={removeRow}
                        handleOnChange={handleOnChange}
                      />
                    </tbody>
                  </table>
                </div>
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="align-middle text-[12px] text-left font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Description of outcomes at different growth rates
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Low
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Medium
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle text-[12px] font-bold p-2 border-b border-l border-r border-solid border-[#999]">
                        If you paid for the loans by reducing your retirement contributions
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999]"></td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999]"></td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999]"></td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        * Reduction in final retirement pot ({formData.primary_representation})
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        0
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        0
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        0
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-bold p-2 border-b border-l border-r border-solid border-[#999]">
                        If you paid for the loans by reducing your investment contributions
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999]"></td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999]"></td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999]"></td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        * Reduction in final investment pot ({formData.primary_representation})
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        0
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        0
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        0
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-4 text-xs font-medium">
                  * NB : note that retirement contributions are made with pre-tax monies, investments with after-tax
                  monies; both will be taxed on withdrawal but at different rates'
                </p>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[18px] text-center mb-2">
                {capitalizeFirstLetter(formData.primary_representation)} opportunity cost of loans - Reduction in
                capital at retirement
              </p>
              <p className="mr-8 font-medium text-[16px] text-center mb-4">
                Repayments sourced from reduced investment funding
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="max-h-[calc(100vh-370px)] h-[calc(100%-43px)]">
                  {chartSeries && (
                    <Chart
                      options={chartOptions as any}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      className="max-h-[calc(100vh-370px)_!important] h-[calc(100%-43px)]"
                      fontFamily="Helvetica, Arial, sans-serif"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
}

export default LoanAffordability;
