import useHome from "@api/Home/useHome";
import image from "@assets/bg-image.jpg";
import HexShapes from "@components/Layout/HexShapes";
import Links from "@components/Links";
import Costings from "@components/Welcome/Costings";
import Stepper from "@components/Welcome/Stepper";
import PageTitle from "@elements/PageTitle";
import Button from "@form/Button";
import DashboardLayout from "@layouts/DashboardLayout";
import Modal from "@modals/Modal";
import StandardOption from "@modals/StandardOption";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { twMerge } from "tailwind-merge";

const Home = () => {
  const { welcomeModal, closeWelcomeModal, smartToolClick, modal, closeModal } = useHome();
  const navigate = useNavigate();
  const closeLinksModal = () => setLinksModal(false);
  const [linksModal, setLinksModal] = useState(false);
  const [showCostingsModal, setShowCostingsModal] = useState(false);
  const closeCostingsModal = () => setShowCostingsModal(false);

  const showCostings = () => {
    setShowCostingsModal(true);
  };

  return (
    <DashboardLayout>
      <PageTitle title="Home" />
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-cover bg-center bg-no-repeat w-full h-full relative"
      >
        <div className="w-full min-h-[calc(100vh-70px)]">
          <div className="relative w-full min-h-[calc(100vh-70px)]">
            <div className="m-0 absolute top-[10%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-full max-w-[1000px] flex justify-between">
              <Button
                type="button"
                name="My Profile"
                className={twMerge(
                  "min-w-[150px] border-2 border-[#243665] bg-white text-[#243665_!important] font-[bold_!important] text-[20px]"
                )}
                onClick={() => {
                  navigate("/my-profile");
                }}
              />
              <Button
                type="button"
                name="Links"
                className={twMerge(
                  "min-w-[150px] border-2 border-[#243665] bg-white text-[#243665_!important] font-[bold_!important] text-[20px]"
                )}
                onClick={() => {
                  setLinksModal(true);
                }}
              />
            </div>
          </div>
          <div className="m-0 absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]">
            <HexShapes
              parentClassName={twMerge("flex items-center justify-center")}
              showCostings={showCostings}
              smartToolClick={smartToolClick}
            />
          </div>
        </div>
      </div>
      <Modal
        show={welcomeModal}
        closeModal={closeWelcomeModal}
        modalContent={<Stepper closeModal={closeWelcomeModal} />}
      />

      <Modal
        show={modal}
        closeModal={closeModal}
        modalContent={<StandardOption />}
      />

      <Modal
        show={linksModal}
        closeModal={closeLinksModal}
        modalContainerPadding={twMerge("py-[50px] w-[60vw_!important] pb-[1rem]")}
        modalContent={<Links />}
      />

      <Modal
        show={showCostingsModal}
        closeModal={closeCostingsModal}
        modalContent={<Costings />}
      />
    </DashboardLayout>
  );
};

export default Home;
