import { useEffect, useState } from "react";
import helpers from "@/services/utils/helpers";
import createAxiosInstance from "@/services/api/Api";

function useLoanAffordabilityTool() {
  const api = createAxiosInstance("standard/lifestyle/loan-affordability");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chartOptionsDefault } = helpers();
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState(chartOptionsDefault);

  const [formData, setFormData] = useState({
    date_of_birth: "",
    retirement_age: "",
    life_expectancy: "",
    current_age: "",
    years_to_retirement: "",
    retirement_longevity: "",
    growth_rate: "Low",
    annual_pre_tax_income: "",
    projected_inflation_rate: "",
    real_post_retirement_capital_growth_low: "",
    real_post_retirement_capital_growth_medium: "",
    real_post_retirement_capital_growth_high: "",
    nominal_post_retirement_capital_growth_low: "",
    nominal_post_retirement_capital_growth_medium: "",
    nominal_post_retirement_capital_growth_high: "",
    effective_date: "",
    primary_representation: "real",
    net_growth_low: 0,
    net_growth_medium: 0,
    net_growth_high: 0,
    loan_values: [],
    loan_terms: [],
    loan_interest_rates: [],
    loan_payments: [],
    marginal_tax_rate: 0,
  });

  const convertChartSeries = (chartData: any) => {
    const years = Object.values(chartData.years);

    const cumulativeTotalPayments = Object.values(chartData.cumulative_total_payments).map((value) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const outstandingLoans = Object.values(chartData.outstanding_loans).map((value: string | unknown) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const lowGrowth = Object.values(chartData.low_growth).map((value: string | unknown): string =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const mediumGrowth = Object.values(chartData.medium_growth).map((value: string | unknown) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );
    const highGrowth = Object.values(chartData.high_growth).map((value: string | unknown) =>
      parseFloat(String(value).replaceAll(",", "")).toFixed(0)
    );

    const series = [
      {
        name: "Cumulative Total Payments",
        type: "line",
        data: cumulativeTotalPayments,
      },
      {
        name: "Outstanding Loans",
        type: "line",
        data: outstandingLoans,
      },
      {
        name: "Low Growth",
        type: "line",
        data: lowGrowth,
      },
      {
        name: "Medium Growth",
        type: "line",
        data: mediumGrowth,
      },
      {
        name: "High Growth",
        type: "line",
        data: highGrowth,
      },
    ];

    const labels = years.map((year, index) => {
      if (index === 0) {
        return "Inception";
      }
      return "Year " + year;
    });

    return { series, labels };
  };

  function handleCurrencyChange(value: string, name: string) {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  function handlePrimaryRepresentationChange(value: string) {
    setFormData((prev) => ({
      ...prev,
      primary_representation: value,
    }));
  }

  function handleChange(e: { target: { name: string; value: any } }) {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    calculateOutput(formData);
  };

  const calculateOutput = async (formData: any) => {
    setLoading(true);
    setError(null);
    try {
      const { data } = await api.post("", formData);

      const { series, labels } = convertChartSeries(data.data);

      setChartSeries(series as any);

      setChartOptions((prevOptions: any) => {
        return {
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: labels,
          },
        };
      });
    } catch (error) {
      setError(error as any);
    } finally {
      setLoading(false);
    }
  };

  const getStoredData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("get-stored-data");
      setFormData((prev) => ({
        ...prev,
        ...data.ufs,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    loading,
    formData,
    handleSubmit,
    handleChange,
    chartSeries,
    chartOptions,
    setChartSeries,
    setChartOptions,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
  };
}

export default useLoanAffordabilityTool;
