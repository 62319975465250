import usePersonalInformation from "@api/User/usePersonalInformation";
import Button from "@form/Button";
import Error from "@form/Error";
import Input from "@form/Input";
import Select from "@form/Select";

const PersonalInformation = () => {
  const { formData, handleChange, handleIDChange, handleSubmit, idError, errors, genders, loading } =
    usePersonalInformation();

  return (
    <>
      <h3 className="mb-4 text-xl font-semibold">Personal information</h3>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="First Name"
              name="name"
              id="name"
              required
              value={formData.name}
              onChange={handleChange}
              variant="white"
            />
            <Error error={errors && errors.name} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Last Name"
              name="surname"
              id="surname"
              value={formData.surname}
              placeholder=""
              required
              onChange={handleChange}
              variant="white"
            />
            <Error error={errors && errors.surname} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Identification Number"
              name="id_number"
              id="id_number"
              required
              value={formData.id_number}
              onChange={handleIDChange}
              variant="white"
            />
            <Error error={idError} />
            <Error error={errors && errors.id_number} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Contact / Cellphone Number"
              type="number"
              name="mobile_number"
              id="mobile_number"
              required
              value={formData.mobile_number}
              onChange={handleChange}
              variant="white"
            />
            <Error error={errors && errors.mobile_number} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Email Address"
              type="email"
              name="email"
              id="email"
              required
              value={formData.email}
              onChange={handleChange}
              variant="white"
            />
            <Error error={errors && errors.email} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Physical Address"
              name="address"
              id="address"
              required
              value={formData.address}
              onChange={handleChange}
              variant="white"
            />
            <Error error={errors && errors.address} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Select
              label="Biological gender (used for life expectancy)"
              defaultItem="Select Gender"
              id="gender_id"
              name="gender_id"
              value={formData.gender_id}
              onChange={handleChange}
              items={genders}
              variant="white"
            />
            <Error error={errors && errors.gender_id} />
          </div>
          <div className="col-span-6 sm:col-span-2">
            <Input
              label="Date of birth"
              type="date"
              name="date_of_birth"
              id="date_of_birth"
              required
              readOnly={true}
              disabled
              value={formData.date_of_birth}
              onChange={handleChange}
              variant="white"
            />
            <Error error={errors && errors.date_of_birth} />
          </div>
          <div className="col-span-6 sm:col-full">
            <Button
              type="submit"
              name="Save"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default PersonalInformation;
