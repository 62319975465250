import logo from '@assets/logo.svg'

export default function Logo(props) {
  const { className } = props

  return (
    <>
      <img src={logo} alt="Future You Logo" className={className} />
    </>
  )
}
