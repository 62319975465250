export default function WhichSystemIsForYou() {
  return (
    <>
      <div>
        <h2 className="text-2xl tracking-tight font-extrabold text-gray-900 text-center">
          Which system is for you?
        </h2>
        <p className="mb-6 mt-3 text-md">
          Future You is designed to give you objective insights into your
          financial status and provide simple tools to promote a healthier
          financial lifestyle. We focus on primary elements, identifying how
          close you are to achieving your goals and changes you can make to
          become more financially secure.
        </p>
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          <div className="flex flex-col p-6 mx-auto w-full text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
            <h3 className="mb-4 text-2xl font-semibold">Standard</h3>
            <p className="text-md text-left">
              16 standalone tools, delivering simple answers to complex
              questions. Additional: access to curated links & blogs, tables and
              tips. Life advice on your journey to financial security.
            </p>
          </div>
          <div className="flex flex-col p-6 mx-auto w-full text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
            <h3 className="mb-4 text-2xl font-semibold">Premium</h3>
            <p className="text-md text-left">
              Maps the major elements of your current and projected income,
              current and projected wealth creation and preservation, and
              expected consumption circumstances through 11 major categories
              (including taxation) to grant you a full understanding of your
              financial future. Highlights future concerns. Application of
              expert default forecasts for purposes of validation.
            </p>
          </div>
          <div className="flex flex-col p-6 mx-auto w-full text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
            <h3 className="mb-4 text-2xl font-semibold">Advice</h3>
            <p className="text-md text-left">
              Access professional, algorithmic advice on how to invest,
              determining the level of insurance required and building a plan of
              action. All advice is supported with historic representations and
              forward expectations and can be related directly to your projected
              future (premium version).
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
