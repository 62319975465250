import Tooltip from "@components/Element/Tooltip";
import { cn } from "@utils/cn";
import { cva } from "class-variance-authority";
import { FaInfoCircle } from "react-icons/fa";
import { MdOutlineCommentsDisabled } from "react-icons/md";

type Props = {
  label?: string;
  type?: string;
  name?: string;
  id?: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  twMergeClassName?: string;
  tooltipText?: string;
  variant?: "white" | "red" | "green" | "orange";
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function Input({
  label,
  type = "text",
  name,
  id,
  value,
  placeholder,
  required = false,
  disabled = false,
  readOnly = false,
  min,
  max,
  step,
  twMergeClassName,
  tooltipText,
  variant = "white",
  onChange,
}: Props) {
  const inputVariants = cva(
    `${twMergeClassName ?? ""} focus:ring-primary-500 focus:border-primary-500 p-1 h-[30px] rounded-md sm:text-[12px] block w-full sm:min-w-[70px] font-bold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none cursor-text`,
    {
      variants: {
        variant: {
          white: `bg-gray-50 border border-gray-300 text-gray-900 ${disabled ? "cursor-not-allowed px-6" : ""}`,
          red: `border border-gray-300 text-white icon-white ${disabled ? "bg-[#85322f] cursor-not-allowed px-6" : "bg-[#a96f6d]"}`,
          green: `border border-gray-300 text-white icon-white ${disabled ? "bg-[#008308] cursor-not-allowed px-6" : "bg-[#4ca852]"}`,
          orange: "bg-[#f2e1c3] border border-gray-300 text-black",
        },
      },
    }
  );

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className="flex items-center gap-2 mb-1.5 text-[12px] font-bold text-gray-900"
        >
          {label} {required ? " *" : ""}
          {tooltipText && (
            <Tooltip tooltipText={tooltipText}>
              <FaInfoCircle />
            </Tooltip>
          )}
        </label>
      )}
      <div className="relative w-full">
        <input
          id={id}
          min={min}
          max={max}
          step={step}
          type={type}
          name={name}
          value={value}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          placeholder={placeholder}
          className={cn(inputVariants({ variant }))}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        {disabled && (
          <div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
            <MdOutlineCommentsDisabled className={`h-[12px] ${variant !== "white" ? "text-white" : ""}`} />
          </div>
        )}
      </div>
    </>
  );
}
