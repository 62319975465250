import Spinner from "@elements/Spinner";

const Button = ({ type = "button", name, loading, className, onClick }) => {
  return (
    <button
      className={`text-white bg-[#243665] hover:opacity-75 min-w-[100px] font-medium rounded-lg px-5 py-2.5 text-center text-[12px] ${className}`}
      type={type}
      onClick={onClick}
    >
      {loading ? <Spinner spinnerSize={"xs"} /> : name}
    </button>
  );
};

export default Button;
