import { useState, useEffect } from "react";
import createAxiosInstance from "@api/Api";
import toast from "react-hot-toast";

const useAdditionalSettings = () => {
  const api = createAxiosInstance("user/preferences");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [preferences, setPreferences] = useState([]);
  const [userPreferences, setUserPreferences] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(saveAdditionalSettings(), {
      loading: "Saving...",
      success: (data) => data.message,
      error: "Could not save.",
    });
  };

  const handleCheckboxChange = () => {
    setUserPreferences(getSelectedCheckboxes);
  };

  const getSelectedCheckboxes = () => {
    const checkboxes = document.querySelectorAll(".additional-settings:checked");
    const selectedValues = Array.from(checkboxes).map((checkbox) => checkbox.value);
    return selectedValues;
  };

  const getAdditionalSettings = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(``);

      setPreferences(data.preferences);
      setUserPreferences(data.user_preferences);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveAdditionalSettings = async () => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put(``, {
        preferences: userPreferences,
      });
      return Promise.resolve(data);
    } catch (error) {
      setErrors(error.response.data.errors);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAdditionalSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    errors,
    preferences,
    userPreferences,
    handleCheckboxChange,
    handleSubmit,
    getAdditionalSettings,
    saveAdditionalSettings,
  };
};

export default useAdditionalSettings;
