import { useState, useEffect } from "react";
import createAxiosInstance from "@api/Api";
import toast from "react-hot-toast";
import numbers from "@services/algorithms/numbers";

const useGeneralInformation = () => {
  const api = createAxiosInstance("user/general-information");
  const { calculateCurrentAge, calculateYearsToRetirement, calculateRetirementDate } = numbers();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [employmentStatuses, setEmploymentStatuses] = useState([]);

  const [formData, setFormData] = useState({
    date_of_birth: "",
    current_age: "",
    effective_date: "",
    retirement_age: "",
    annual_pre_tax_income: "",
    annual_bonus: "",
    employment_status_id: "",
    years_to_retirement: "",
    retirement_date: "",
    projected_retirement_date: "",
    life_expectancy: "",
  });

  useEffect(() => {
    if (formData.date_of_birth && formData.retirement_age) {
      const birthDate = formData.date_of_birth;
      const retirementAge = formData.retirement_age;
      const effectiveDate = formData.effective_date;

      setFormData((prevFormData) => ({
        ...prevFormData,
        current_age: calculateCurrentAge(birthDate, effectiveDate),
        years_to_retirement: calculateYearsToRetirement(effectiveDate, formData.retirement_date),
        projected_retirement_date: calculateRetirementDate(birthDate, retirementAge),
      }));
    }
  }, [formData.date_of_birth, formData.retirement_age, formData.effective_date]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCurrencyChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.promise(saveGeneralInformation(formData), {
      loading: "Saving...",
      success: (data) => data.message,
      error: "Could not save.",
    });
  };

  const getGeneralInformation = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(``, formData);

      setFormData({
        effective_date: data.ufs.effective_date || "",
        retirement_date: data.ufs.retirement_date || "",
        address: data.user.address || "",
        gender_id: data.user.gender_id || "",
        date_of_birth: data.user.date_of_birth || "",
        retirement_age: data.ufs.retirement_age || "",
        annual_pre_tax_income: data.ufs.annual_pre_tax_income || "",
        annual_bonus: data.ufs.annual_bonus || "",
        employment_status_id: data.ufs.employment_status_id || "",
        life_expectancy: data.user.life_expectancy || "",
      });
      setEmploymentStatuses(data.employmentStatuses);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveGeneralInformation = async (formData) => {
    setLoading(true);
    setErrors(null);
    try {
      const { data } = await api.put(``, formData);
      return Promise.resolve(data);
    } catch (error) {
      setErrors(error.response.data.errors);
      return Promise.reject(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGeneralInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    errors,
    formData,
    employmentStatuses,
    handleChange,
    handleCurrencyChange,
    handleSubmit,
    getGeneralInformation,
    saveGeneralInformation,
  };
};

export default useGeneralInformation;
