import { useState } from 'react'

const useVisibility = () => {
  const [visibilities, setVisibilities] = useState({})

  const toggleVisibility = (key) => {
    setVisibilities((prevVisibilities) => ({
      ...prevVisibilities,
      [key]: !(prevVisibilities[key] ?? false),
    }))
  }

  const isVisible = (key) => {
    return visibilities[key] ?? false
  }

  return {
    isVisible,
    toggleVisibility,
  }
}

export default useVisibility
