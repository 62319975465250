import History from "@pages/Dashboard/History";
import Home from "@pages/Dashboard/Home/Home";
import Logout from "@pages/Dashboard/Logout/Logout";
import MyProfile from "@pages/Dashboard/User/MyProfile";
import { Route, Routes } from "react-router-dom";

const DashboardRoutes = () => (
  <Routes>
    <Route
      path="/home"
      element={<Home />}
    />
    <Route
      path="/my-profile"
      element={<MyProfile />}
    />
    <Route
      path="/history"
      element={<History />}
    />
    <Route
      path="/logout"
      element={<Logout />}
    />
  </Routes>
);

export default DashboardRoutes;
