const CheckboxFull = ({ id, name, array, slug, classTarget, handleCheckboxChange }) => {
  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className="text-lg font-semibold text-gray-900">{name}</div>
      </div>
      <label
        className="relative inline-flex items-center cursor-pointer"
        htmlFor={slug}
      >
        <input
          type="checkbox"
          name={slug}
          className={`sr-only peer ${classTarget}`}
          id={slug}
          value={id}
          defaultChecked={array && array.includes(id)}
          onChange={handleCheckboxChange}
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#243665]"></div>
      </label>
    </>
  );
};

export default CheckboxFull;
