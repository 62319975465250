import VerticalTabs from "@/components/Element/VerticalTabs";
import CommentaryLayout from "@/layouts/CommentaryLayout";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InvestmentApproach from "./InvestmentApproach";
import RecommendedReadings from "./RecommendedReadings";
import RulesOfLife from "./RulesOfLife";

const LifestyleTipsIndex = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getTabIndex = searchParams.get("tab");

  return (
    <CommentaryLayout
      showBackButton
      backButtonNavigateTo={"/commentary"}
      pageTitle={
        getTabIndex === "1" ? "Investment approach" : getTabIndex === "2" ? "19 rules for life" : "Recommended readings"
      }
    >
      <>
        <VerticalTabs tabIndex={getTabIndex}>
          <div
            tabtitle="Investment approach"
            className="h-full"
          >
            <InvestmentApproach />
          </div>
          <div
            tabtitle="19 rules for life"
            className="h-full"
          >
            <RulesOfLife />
          </div>
          <div
            tabtitle="Recommended readings"
            className="h-full"
          >
            <RecommendedReadings />
          </div>
        </VerticalTabs>
      </>
    </CommentaryLayout>
  );
};

export default LifestyleTipsIndex;
