import image from "@/assets/landing.png";

const AuthLayout = ({ children }) => {
  return (
    <>
      <div
        style={{ backgroundImage: `url('${image}')` }}
        className="bg-fixed bg-cover w-full h-screen fixed backdrop-brightness-50"
      />
      <div className="w-full h-screen fixed backdrop-brightness-50" />

      <div className="w-full min-h-[100vh] z-10 relative ">{children}</div>
    </>
  );
};

export default AuthLayout;
