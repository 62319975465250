import { Route, Routes } from "react-router-dom";
/* Index */
import Commentary from "@/pages/Commentary";
import LifestyleTipsIndex from "@/pages/Commentary/LifestyleTips";
import PerformanceTablesAndLinksIndex from "@/pages/Commentary/PerformanceTablesAndLinks";
import TerminologyAndExpectationsIndex from "@/pages/Commentary/TerminologyAndExpectations";

function CommentaryRoutes() {
  return (
    <Routes>
      <Route
        path="commentary"
        element={<Commentary />}
      />
      <Route
        path="/commentary/terminology-and-expectations"
        element={<TerminologyAndExpectationsIndex />}
      />
      <Route
        path="/commentary/lifestyle-tips"
        element={<LifestyleTipsIndex />}
      />
      <Route
        path="/commentary/performance-tables-and-links"
        element={<PerformanceTablesAndLinksIndex />}
      />
    </Routes>
  );
}

export default CommentaryRoutes;
