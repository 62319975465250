const SelectFundSource = ({ fundSource, handleChange }) => {
  return (
    <>
      <p className="mr-4 font-bold text-[16px]">Select from fund source:</p>
      <div className="flex items-center">
        <label className="relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1">
          <input
            type="checkbox"
            className="sr-only"
            checked={fundSource === "retirement"}
            onChange={() => handleChange("retirement")}
          />
          <span
            className={`rounded py-2 px-[18px] text-[12px] w-[100px] text-center hover:bg-[#ececec] hover:font-bold transition-all ${
              fundSource === "retirement" ? "bg-[#d6d6d6] font-bold" : "font-medium bg-[#f9f9f9]"
            }`}
          >
            Retirement
          </span>
        </label>
        <label className="relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1 ml-4">
          <input
            type="checkbox"
            className="sr-only"
            checked={fundSource === "investment"}
            onChange={() => handleChange("investment")}
          />
          <span
            className={`rounded py-2 px-[18px] text-[12px] w-[100px] text-center hover:bg-[#ececec] hover:font-bold transition-all ${
              fundSource === "investment" ? "bg-[#d6d6d6] font-bold" : "font-medium bg-[#f9f9f9]"
            }`}
          >
            Investment
          </span>
        </label>
      </div>
    </>
  );
};

export default SelectFundSource;
