import useMoneyLastTool from "@api/Standard/Retirement/useMoneyLastTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import CurrencySwitch from "@form/CurrencySwitch";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import PercentageSwitch from "@form/PercentageSwitch";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import helpers from "@utils/helpers";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";

const MoneyLast = () => {
  const { capitalizeFirstLetter } = words();
  const { preventEnterPress } = helpers();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    chartSeries,
    chartOptions,
    formData,
    handleSubmit,
    handleChange,
    handleNumberChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
    handleNominalChange,
    handleRealChange,
    handleFutureChange,
    handlePresentChange,
  } = useMoneyLastTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("money-last-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              This tool is designed to show you how long your specific projected retirement pot will last you in
              retirement. Inputs required are:
              <ul className="pl-4">
                <li className="my-1">
                  a) Your projected accumulated value at retirement (use “How much will my money grow to by retirement?”
                  to determine this amount or use other estimate)
                </li>
                <li className="my-1">
                  b) Your projected final income pre-retirement – this is a function of your current income, how much
                  you expect it to increase annually until retirement, and your time to retirement.
                </li>
                <li className="my-1">
                  c) Your target SOL in retirement – this is a percentage of your projected final income.
                </li>
                <li className="my-1">
                  d) The 3 standard net growth rates are used to estimate the performance of your capital.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output is represented by
              <ul className="pl-4">
                <li className="my-1">a) A multiple of your annual income at retirement</li>
                <li className="my-1">b) Years to depletion at each identified net growth rates</li>
              </ul>
            </li>
            <li className="mb-2">
              Chart is a choice of 6 representations (real & nominal at low/medium/high growth rates)
              <ul className="pl-4">
                <li className="my-1">
                  a) Real chart will have a histogram of flat withdrawals on x-axis; nominal will be increasing
                </li>
                <li className="my-1">
                  b) Real residual capital chart will decline to zero over depletion period; nominal may initially
                  increase and then decline to zero over the same time period.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("money-last-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="How long will my money last in retirement?"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Money Last" />
      <form
        onSubmit={handleSubmit}
        onKeyDown={preventEnterPress}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-7 gap-4 w-full p-4">
          <div className="col-span-7">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="max-h-[calc(100vh-170px)] h-full scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Date of birth"
                          name="date_of_birth"
                          id="date_of_birth"
                          type="date"
                          disabled
                          value={formData.date_of_birth}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement age"
                          name="retirement_age"
                          id="retirement_age"
                          type="number"
                          value={formData.retirement_age}
                          onChange={handleNumberChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Age at analysis date"
                          name="current_age"
                          id="current_age"
                          type="text"
                          disabled
                          value={formData.current_age}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Years to retirement"
                          name="years_to_retirement"
                          id="years_to_retirement"
                          type="text"
                          disabled
                          value={formData.years_to_retirement}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Life expectancy"
                          name="life_expectancy"
                          id="life_expectancy"
                          type="number"
                          disabled
                          value={formData.life_expectancy}
                          onChange={handleChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                      <div className="col-span-1">
                        <Input
                          label="Retirement longevity"
                          name="retirement_longevity"
                          id="retirement_longevity"
                          type="number"
                          value={formData.retirement_longevity}
                          onChange={handleNumberChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Growth & cost assumptions</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GrowthTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <table className="table-auto w-full">
                  {isVisible("GrowthTable") && (
                    <>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-[12px]">Low</th>
                          <th className="text-[12px]">Medium</th>
                          <th className="text-[12px]">High</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-[5px] pr-1 text-[12px] font-bold">
                            {capitalizeFirstLetter(formData.primary_representation)} growth post-retirement
                          </td>
                          {formData.primary_representation == "real" ? (
                            <>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_post_retirement_capital_growth_low"
                                  id="real_post_retirement_capital_growth_low"
                                  value={formData.real_post_retirement_capital_growth_low}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_post_retirement_capital_growth_medium"
                                  id="real_post_retirement_capital_growth_medium"
                                  value={formData.real_post_retirement_capital_growth_medium}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="real_post_retirement_capital_growth_high"
                                  id="real_post_retirement_capital_growth_high"
                                  value={formData.real_post_retirement_capital_growth_high}
                                  onChange={handleRealChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_post_retirement_capital_growth_low"
                                  id="nominal_post_retirement_capital_growth_low"
                                  value={formData.nominal_post_retirement_capital_growth_low}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_post_retirement_capital_growth_medium"
                                  id="nominal_post_retirement_capital_growth_medium"
                                  value={formData.nominal_post_retirement_capital_growth_medium}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                              <td className="py-[5px]">
                                <Percentage
                                  name="nominal_post_retirement_capital_growth_high"
                                  id="nominal_post_retirement_capital_growth_high"
                                  value={formData.nominal_post_retirement_capital_growth_high}
                                  onChange={handleNominalChange}
                                  variant="red"
                                  twMergeClassName={twMerge(`text-center`)}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                        <tr>
                          <td className="py-[5px] text-[12px] font-bold">Annual costs</td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="post_retirement_costs"
                              id="post_retirement_costs"
                              value={formData.post_retirement_costs}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </>
                  )}

                  {!isVisible("GrowthTable") && (
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-[12px]">Low</th>
                        <th className="text-[12px]">Medium</th>
                        <th className="text-[12px]">High</th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td className="py-[5px] text-[12px] font-bold">Net growth post-retirement</td>
                      {formData.primary_representation == "real" ? (
                        <>
                          <td className="py-[5px]">
                            <Percentage
                              name="real_growth_low"
                              id="real_growth_low"
                              value={formData.real_growth_low}
                              variant="red"
                              disabled
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="real_growth_medium"
                              id="real_growth_medium"
                              disabled
                              value={formData.real_growth_medium}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="real_growth_high"
                              id="real_growth_high"
                              disabled
                              value={formData.real_growth_high}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="py-[5px]">
                            <Percentage
                              name="nominal_growth_low"
                              id="nominal_growth_low"
                              value={formData.nominal_growth_low}
                              variant="red"
                              disabled
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="nominal_growth_medium"
                              id="nominal_growth_medium"
                              disabled
                              value={formData.nominal_growth_medium}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="py-[5px]">
                            <Percentage
                              name="nominal_growth_high"
                              id="nominal_growth_high"
                              disabled
                              value={formData.nominal_growth_high}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </table>
              </Card>

              <Card
                variant="white"
                className="mb-4"
              >
                <div className="mb-4">
                  <h3 className="text-[16px] font-semibold mb-2">Smart tool specific inputs</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  <div className="col-span-1">
                    <Currency
                      label="Annual pre-tax income"
                      name="annual_pre_tax_income"
                      id="annual_pre_tax_income"
                      value={formData.annual_pre_tax_income}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    {formData.primary_representation == "real" ? (
                      <>
                        <Percentage
                          label="Real growth in income"
                          name="real_growth_in_income"
                          id="real_growth_in_income"
                          value={formData.real_growth_in_income}
                          onChange={handleRealChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    ) : (
                      <>
                        <Percentage
                          label="Nominal growth in income"
                          name="nominal_growth_in_income"
                          id="nominal_growth_in_income"
                          value={formData.nominal_growth_in_income}
                          onChange={handleNominalChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-span-1">
                    <Percentage
                      label="Target income on retirement (% of pre-retirement)"
                      name="post_retirement_income_percentage"
                      id="post_retirement_income_percentage"
                      value={formData.post_retirement_income_percentage}
                      onChange={handleCurrencyChange}
                      variant="green"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                  <div className="col-span-1">
                    {formData.primary_representation == "real" ? (
                      <>
                        <Currency
                          label="Projected Real accumulated fund value at retirement"
                          name="real_projected_retirement_fund_value"
                          id="real_projected_retirement_fund_value"
                          value={formData.real_projected_retirement_fund_value}
                          onChange={handlePresentChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    ) : (
                      <>
                        <Currency
                          label="Projected Nominal accumulated fund value at retirement"
                          name="nominal_projected_retirement_fund_value"
                          id="nominal_projected_retirement_fund_value"
                          value={formData.nominal_projected_retirement_fund_value}
                          onChange={handleFutureChange}
                          variant="green"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Card>

              <Card variant="white">
                <div className=" mb-4">
                  <h3 className="text-[16px] font-semibold">Output</h3>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows you how long it will be until your retirement capital is fully depleted at
                    your currrent standard of living assuming varying growth rates in retirement
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-6 mb-6">
                  <div>
                    {formData.primary_representation == "real" ? (
                      <>
                        <Currency
                          label="Target income at retirement:"
                          value={formData.real_target_income_at_retirement}
                          variant="red"
                          disabled
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    ) : (
                      <>
                        <Currency
                          label="Target income at retirement:"
                          value={formData.nominal_target_income_at_retirement}
                          variant="red"
                          disabled
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <Input
                      label="Projected multiple:"
                      onChange={handleChange}
                      value={formData.multiple_on_retirement}
                      variant="red"
                      disabled
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Description</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Low</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">Medium</th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-b border-r border-[#999] text-[12px] font-semibold p-2 bg-[#ffffff]">
                        Years to full depletion at projected growth rate
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.full_depletion_growth_rate_low === "Infinity" ||
                        formData.full_depletion_growth_rate_low === "NaN"
                          ? "0.00"
                          : formData.full_depletion_growth_rate_low}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.full_depletion_growth_rate_medium === "Infinity" ||
                        formData.full_depletion_growth_rate_medium === "NaN"
                          ? "0.00"
                          : formData.full_depletion_growth_rate_medium}
                      </td>
                      <td className="bg-[#f2e1c3] border-b border-r border-[#999] text-center text-[12px] font-bold p-2 ">
                        {formData.full_depletion_growth_rate_high === "Infinity" ||
                        formData.full_depletion_growth_rate_high === "NaN"
                          ? "0.00"
                          : formData.full_depletion_growth_rate_high}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>

          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                Retirement - {formData.primary_representation} withdrawals & residual capital
              </p>
              {loading ? (
                <ChartLoading />
              ) : (
                <div className="max-h-[calc(100vh-290px)] h-[calc(100%-43px)]">
                  {chartSeries && (
                    <Chart
                      options={chartOptions}
                      series={chartSeries}
                      type="line"
                      width="100%"
                      height="100%"
                      className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                    />
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default MoneyLast;
