import useVehicleCostTool from "@api/Standard/Lifestyle/useVehicleCostTool";
import Card from "@components/Element/Card";
import ChartLoading from "@components/Element/ChartLoading";
import PageTitle from "@elements/PageTitle";
import VisibilityToggle from "@elements/VisibilityToggle";
import AnalysisType from "@form/AnalysisType";
import Button from "@form/Button";
import Currency from "@form/Currency";
import GrowthRate from "@form/GrowthRate";
import Input from "@form/Input";
import Percentage from "@form/Percentage";
import StandardToolLayout from "@layouts/StandardToolLayout";
import words from "@services/algorithms/words";
import useVisibility from "@utils/useVisibility";
import { useState } from "react";
import Chart from "react-apexcharts";
import { twMerge } from "tailwind-merge";

const VehicleCost = () => {
  const { capitalizeFirstLetter } = words();
  const { isVisible, toggleVisibility } = useVisibility();

  const {
    loading,
    formData,
    handleSubmit,
    handleChange,
    handleCurrencyChange,
    handlePrimaryRepresentationChange,
    chartSeries,
    chartOptions,
  } = useVehicleCostTool();

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("vehicle-cost-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              One of the significant “destroyers” of investment capital is vehicle ownership due to the fact that it
              tends to have more of a status than utility purpose, as well as it is a depreciating asset.
            </li>
            <li className="mb-2">
              This tool is designed to demonstrate the impact on a person’s accumulated capital if they had{" "}
              <strong>invested the difference</strong> in ownership costs between two vehicles.
            </li>
            <li className="mb-2">
              Inputs are:
              <ul className="pl-4">
                <li className="my-1">
                  a) Generic: projected inflation rate is shown for illustrative purposes; investment growth rates
                  (opportunity cost) as per standard defaults
                </li>
                <li className="my-1">
                  b) Additional (per car) will include:
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Purchase price, cost of accessories, trade-in value, finance amount, finance term, interest
                      rate
                    </li>
                    <li className="my-1">ii) Insurance costs, maintenance costs, running costs.</li>
                    <li className="my-1">
                      iii) Replacement cycle (years), replacement target (real cost of future replacement vehicle),
                      vehicle depreciation rate
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Output shows
              <ul className="pl-4">
                <li className="my-1">
                  a) Table of capital that could have been accumulated by selecting the lower valued vehicle over a
                  target period.
                </li>
                <li className="my-1">
                  b) Chart shows the value of the two comparative vehicles in real terms (RHS) compared to the capital
                  that could have been accumulated (LHS) by investing the difference in cost of ownership.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("vehicle-cost-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Opportunity cost of ownership of different vehicles"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Vehicle Cost" />
      <form
        onSubmit={handleSubmit}
        className="lg:w-[90%] mx-auto"
      >
        <div className="grid grid-cols-8 gap-4 w-full p-4">
          <div className="col-span-8">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <AnalysisType
                  primaryRepresentation={formData.primary_representation}
                  handleChange={handlePrimaryRepresentationChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <GrowthRate
                  riskLevel={formData.growth_rate}
                  handleChange={handleChange}
                />
              </div>
              <div className="flex items-center justify-center ml-10">
                <Button
                  type="submit"
                  name="Calculate"
                />
              </div>
            </div>
          </div>
          <div className="col-span-4">
            <div className="max-h-[calc(100vh-170px)] scrollbar overflow-y-scroll overflow-x-hidden">
              <Card
                variant="white"
                className="mb-4"
              >
                <div className={`flex items-center mb-4`}>
                  <h3 className="text-[16px] font-semibold">Generic inputs</h3>
                  <span className="ml-4 cursor-pointer">
                    <VisibilityToggle
                      keyName="GenericTable"
                      isVisible={isVisible}
                      toggleVisibility={toggleVisibility}
                    />
                  </span>
                </div>
                <div className="grid grid-cols-2 gap-3 items-end">
                  {isVisible("GenericTable") && (
                    <>
                      <div className="col-span-1">
                        <Percentage
                          label="CPI inflation"
                          name="projected_inflation_rate"
                          id="projected_inflation_rate"
                          value={formData.projected_inflation_rate}
                          onChange={handleCurrencyChange}
                          variant="red"
                          twMergeClassName={twMerge(`text-center`)}
                        />
                      </div>
                    </>
                  )}
                  <div className="col-span-1">
                    <Input
                      label="Date of analysis"
                      name="effective_date"
                      id="effective_date"
                      type="date"
                      value={formData.effective_date}
                      onChange={handleChange}
                      variant="red"
                      twMergeClassName={twMerge(`text-center`)}
                    />
                  </div>
                </div>
              </Card>

              <div className="col-span-4 mb-4">
                <div className="bg-transparent rounded-md">
                  <div className="p-4 bg-[#fff] rounded-t-md">
                    <h3 className="text-[16px] font-semibold">Smart tool specific inputs</h3>
                  </div>
                  <div className="bg-white">
                    <table className="table-auto w-full">
                      <thead className="bg-[#ffffff]">
                        <tr>
                          <th className="align-middle text-[12px] text-left font-bold p-2 border-r border-t border-solid border-[#999]">
                            Description
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-solid border-[#999]">
                            Vehicle #1
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-r border-t border-solid border-[#999]">
                            Vehicle #2
                          </th>
                          <th className="align-middle text-[12px] font-bold p-2 border-t border-solid border-[#999]">
                            Difference
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            Asset cost
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999]">
                            Purchase price
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Currency
                              name="v1_price"
                              id="v1_price"
                              value={formData.v1_price}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Currency
                              name="v2_price"
                              id="v2_price"
                              value={formData.v2_price}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999]">
                            <Currency
                              variant="red"
                              disabled
                              name="price_difference"
                              id="price_difference"
                              value={formData.price_difference}
                              onChange={handleCurrencyChange}
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-semibold p-2 border-r border-b border-solid border-[#999]">
                            Trade-in value
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Currency
                              name="v1_term"
                              id="v1_term"
                              value={formData.v1_term}
                              type="number"
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Currency
                              name="v2_term"
                              id="v2_term"
                              value={formData.v2_term}
                              type="number"
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999]">
                            <Input
                              name="term_difference"
                              id="term_difference"
                              value={formData.term_difference}
                              type="number"
                              onChange={handleChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            Finance amount
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Currency
                              name="v1_fin_amount"
                              id="v1_fin_amount"
                              value={formData.v1_fin_amount}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Currency
                              name="v2_fin_amount"
                              id="v2_fin_amount"
                              value={formData.v2_fin_amount}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                            <Currency
                              name="fin_amount_difference"
                              id="fin_amount_difference"
                              value={formData.fin_amount_difference}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            Term (years)
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Input
                              name=""
                              id=""
                              value={""}
                              type="number"
                              onChange={handleChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-b border-solid border-[#999]">
                            <Input
                              name=""
                              id=""
                              value={""}
                              type="number"
                              onChange={handleChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]"></td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            Interest rate
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Percentage
                              name="v1_interest"
                              id="v1_interest"
                              value={formData.v1_interest}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Percentage
                              name="v2_interest"
                              id="v2_interest"
                              value={formData.v2_interest}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]"></td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            Annual finance
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Currency
                              name="v1_ann_fin_amount"
                              id="v1_ann_fin_amount"
                              value={formData.v1_ann_fin_amount}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Currency
                              name="v2_ann_fin_amount"
                              id="v2_ann_fin_amount"
                              value={formData.v2_ann_fin_amount}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-solid border-[#999]">
                            <Currency
                              name="ann_fin_amount_difference"
                              id="ann_fin_amount_difference"
                              value={formData.ann_fin_amount_difference}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            Asset preservation & cost of ownership
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            Annual Insurance
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name="v1_ann_ins"
                              id="v1_ann_ins"
                              value={formData.v1_ann_ins}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name="v2_ann_ins"
                              id="v2_ann_ins"
                              value={formData.v2_ann_ins}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                            <Currency
                              name="ann_ins_difference"
                              id="ann_ins_difference"
                              value={formData.ann_ins_difference}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            Annual maintenance (service)
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name="v1_ann_maintenance"
                              id="v1_ann_maintenance"
                              value={formData.v1_ann_maintenance}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name="v2_ann_maintenance"
                              id="v2_ann_maintenance"
                              value={formData.v2_ann_maintenance}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                            <Currency
                              name="ann_maintenance_difference"
                              id="ann_maintenance_difference"
                              value={formData.ann_maintenance_difference}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            Annual running costs (fuel / tyres)
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name="v1_ann_cost"
                              id="v1_ann_cost"
                              value={formData.v1_ann_cost}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name="v2_ann_cost"
                              id="v2_ann_cost"
                              value={formData.v2_ann_cost}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                            <Currency
                              name="ann_cost_difference"
                              id="ann_cost_difference"
                              value={formData.ann_cost_difference}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                              allowNegativeValue={true}
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            Total
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name=""
                              id=""
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Currency
                              name=""
                              id=""
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                              allowNegativeValue={true}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-solid border-[#999]">
                            <Currency
                              name=""
                              id=""
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              twMergeClassName={twMerge(`text-center`)}
                              disabled
                              allowNegativeValue={true}
                            />
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            Percentage of initial value
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Percentage
                              name=""
                              id=""
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Percentage
                              name=""
                              id=""
                              value={""}
                              onChange={handleCurrencyChange}
                              variant="red"
                              disabled
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2"></td>
                        </tr>
                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            Asset replacement
                          </td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            Target value ({formData.primary_representation} annual escalation over initial)
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Percentage
                              name="v1_tar_value"
                              id="v1_tar_value"
                              value={formData.v1_tar_value}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-b border-r border-solid border-[#999]">
                            <Percentage
                              name="v2_tar_value"
                              id="v2_tar_value"
                              value={formData.v2_tar_value}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-bold p-2 border-b border-solid border-[#999]"></td>
                        </tr>
                        <tr className="bg-white">
                          <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            Replacement cycle (years)
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Input
                              name="v1_rep_cycle"
                              id="v1_rep_cycle"
                              value={formData.v1_rep_cycle}
                              type="number"
                              onChange={handleChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Input
                              name="v2_rep_cycle"
                              id="v2_rep_cycle"
                              value={formData.v2_rep_cycle}
                              type="number"
                              onChange={handleChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2"></td>
                        </tr>
                        <tr className="bg-white">
                          <td
                            colSpan={4}
                            className="text-[12px] font-bold p-2 bg-[#C3CBBE]"
                          >
                            Asset depreciation
                          </td>
                        </tr>
                        <tr className="bg-white rounded-md">
                          <td className="align-middle text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            Annual vehicle depreciation
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Percentage
                              name="v1_ann_veh_dep"
                              id="v1_ann_veh_dep"
                              value={formData.v1_ann_veh_dep}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-r border-solid border-[#999]">
                            <Percentage
                              name="v2_ann_veh_dep"
                              id="v2_ann_veh_dep"
                              value={formData.v2_ann_veh_dep}
                              onChange={handleCurrencyChange}
                              variant="green"
                              twMergeClassName={twMerge(`text-center`)}
                            />
                          </td>
                          <td className="align-middle text-center text-[12px] font-medium p-2 border-solid border-[#999]"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <Card variant="white">
                <div className=" mb-4">
                  <div className="grid grid-cols-2 gap-6 mb-4 items-center">
                    <div>
                      <h3 className="text-[16px] font-semibold">Output:</h3>
                    </div>
                    <div>
                      <Input
                        label="Evaluation date - number of years ahead"
                        onChange={handleChange}
                        variant="green"
                        twMergeClassName={twMerge(`text-center`)}
                      />
                    </div>
                  </div>
                  <p className="text-[12px] font-semibold mt-2">
                    The table below shows the "lost potential value" from buying & replacing a more, rather than less
                    expensive vehicle, by investing the difference at the stated growth rates below
                  </p>
                </div>

                <table className="table-auto w-full border border-[#999]">
                  <thead>
                    <tr className="bg-[#f2b443]">
                      <th className="align-middle text-[12px] text-left font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Net impact at opportunity cost growth rates
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Low
                      </th>
                      <th className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2b443]">
                        Medium
                      </th>
                      <th className="border-b border-r border-[#999] text-[12px] font-semibold p-2">High</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999]">
                        Net growth
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#85322f] text-white">
                        {formData.primary_representation === "real"
                          ? formData.real_post_retirement_capital_growth_low
                          : formData.nominal_post_retirement_capital_growth_low}
                        %
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#85322f] text-white">
                        {formData.primary_representation === "real"
                          ? formData.real_post_retirement_capital_growth_medium
                          : formData.nominal_post_retirement_capital_growth_medium}
                        %
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#85322f] text-white">
                        {formData.primary_representation === "real"
                          ? formData.real_post_retirement_capital_growth_high
                          : formData.nominal_post_retirement_capital_growth_high}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle text-[12px] font-semibold p-2 border-b border-l border-r border-solid border-[#999] ">
                        Cumulative opportunity cost
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-r border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                      <td className="align-middle text-[12px] text-center font-semibold p-2 border-b border-solid border-[#999] bg-[#f2e1c3]">
                        R
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card>
            </div>
          </div>
          <div className="col-span-4">
            <Card
              variant="white"
              className="h-full"
            >
              <p className="mr-8 font-bold text-[16px] text-center mb-4">
                {capitalizeFirstLetter(formData.primary_representation)} opportunity cost of ownership
              </p>
              <div className="max-h-[calc(100vh-200px)] h-[calc(100%-43px)]">
                {chartSeries && (
                  <Chart
                    options={chartOptions}
                    series={chartSeries}
                    type="line"
                    width="100%"
                    height="100%"
                    className="max-h-[calc(100vh-290px)_!important] h-[calc(100%-43px)]"
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </form>
    </StandardToolLayout>
  );
};

export default VehicleCost;
