const InvestmentTerms = () => {
  return (
    <div className="text-sm max-h-[calc(100vh-70px)] h-full scrollbar overflow-y-scroll overflow-x-hidden py-4">
      <p className="font-medium mb-4">
        <span className="font-bold">1. Active management: </span>
        {`the process of making decisions on the investments within a portfolio with the goal of delivering excess returns over the designated benchmark.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">2. Assets: </span>
        {`assets are vehicles that support the investment process and will include stocks, bonds, property and cash, both local and international.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">3. Asset allocation: </span>
        {`the process of allocating your investment portfolio to different assets. The rationale for this is risk and return diversification.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">4. Alpha: </span>
        {`a measure of excess return (usually in percentage terms) relative to target or benchmark.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">5. Annualisation: </span>
        {`the process of converting a series of short- or long-term statistics into a form that is comparable over an annual time frame.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">6. Bear market: </span>
        {`this describes an environment where asset prices typically decline over a period of time. Usually considered to be a sustained fall as distinct from short-term volatility.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">7. Benchmark: </span>
        {`the performance objective and/or neutral structure of a portfolio used to define the success (or failure) of an investment strategy. `}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">8. Beta: </span>
        {`a measure of how volatile an assets performance is relative to its target or benchmark. A beta of one represents an asset that has exactly the same volatility as its benchmark.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">9. Bond: </span>
        {`a debt security that a business or government sells to investors in return for a fixed (or variable) rate of interest over a set term with repayment of capital (principal) at the end of the term. `}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">10. Bull market: </span>
        {`this describes an environment where asset prices typically increase over a period of time. Usually considered to be a sustained rise as distinct from short-term volatility.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">11. Capital gain (loss): </span>
        {`the amount by which an asset appreciates (depreciates) over its purchase price. The gain is considered unrealized until the date of sale.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">12. Commodities: </span>
        {`a type of asset class that are typically raw materials or primary agricultural products that can be purchased or sold including gold, oil and grain.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">13. Common stock: </span>
        {`also stocks or equities; that portion of a company owned by its shareholders, both listed on the stock market and unlisted in private hands. Has associated voting rights.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">14. Currency risk: </span>
        {`the risk that results from changes in the price of one currency to another (also known as exchange rate risk).`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">15. Default risk: </span>
        {`also known as counterparty or credit risk – it is the risk that an individual or entity is unable to meet their debt obligations either in terms of interest or capital payments.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">16. Dividends: </span>
        {`payments made to its shareholders from the earnings or retained capital of a company.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">17. Diversification: </span>
        {`the technique used by investors to reduce the risk of losing money by spreading investments across a variety of assets, instruments and industries.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">18. Equities: </span>
        {`refer common stock.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">19. Fixed income: </span>
        {`refers to a debt instrument (bond) that pays a fixed amount of interest over a fixed period of time.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">20. Floating income: </span>
        {`refers to a debt instrument (bond) that pays a variable amount of interest over a fixed period of time. The variation is often a function of prevailing interest rates.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">21. Fundamental attribute: </span>
        {`this describes characteristics of a security that can largely be found under published income statements, balance sheets or cash-flow statements.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">22. Index: </span>
        {`a collection of securities used to track a particular market, asset class or sector. The methodology for the construction of an index follows mechanisms such as fundamental attributes or market capitalization.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">23. Interest rate risk: </span>
        {`the risk that the value of a security will change because of a change in interest rates.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">24. Market capitalization: </span>
        {`the total value of an instrument calculated by the quotient of the number of shares in issue X the prevailing price of the instrument.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">25. Maturity: </span>
        {`the date on which the bond issuer agrees to pay the last interest and principal amount due on the bond.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">26. Passive management: </span>
        {`the process of making decisions on the investments within a portfolio to mirror the benchmark i.e. a replicating strategy.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">27. Portfolio: </span>
        {`your investment portfolio represents the aggregation of all your investments.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">28. Quantitative management: </span>
        {`the process of making decisions on the investments within a portfolio on the basis of quantitative models and algorithms.`}
      </p>

      <p className="font-medium mb-4">
        <span className="font-bold">29. Security: </span>
        {`a generic term for an instrument that represents any of the asset classes.`}
      </p>

      <p className="font-medium">
        <span className="font-bold">30. Volatility: </span>
        {`a tendency for quick and unpredictable change, usually measured statistically over an historical period.`}
      </p>
    </div>
  );
};

export default InvestmentTerms;
