import PageTitle from "@elements/PageTitle";
import StandardToolLayout from "@layouts/StandardToolLayout";
import { useState } from "react";
import BuyVsRentToolOne from "./BuyVsRentToolOne";
import BuyVsRentToolThree from "./BuyVsRentToolThree";
import BuyVsRentToolTwo from "./BuyVsRentToolTwo";

const BuyVsRent = () => {
  const [tabs, setTabs] = useState("buyVsRentToolOne");

  const [showHelpPopup, setShowHelpPopup] = useState(localStorage.getItem("home-ownership-help-popup") === "true");

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Goal is to demonstrate 3 major aspects and highlight “hidden” costs viz
              <ul className="pl-4">
                <li className="my-1">a) What does it cost to purchase a home?</li>
                <li className="my-1">b) What home value can I afford?</li>
                <li className="my-1">
                  c) What period of time do I need to stay in my home before my home equity breaks-even with a renting
                  alternative?
                </li>
              </ul>
            </li>
            <li className="mb-2">
              This tool can also be used to understand the rent needed to offset the purchase of a buy-to-let home plus
              the tax implications.
            </li>
            <li className="mb-2">
              Representations #1 & #2 (cost to purchase/affordability vs projected home equity)
              <ul className="pl-4">
                <li className="my-1">
                  a) Inputs are combinations of
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Home value, deposit, bond amount, bond registration costs, property transfer costs, mortgage
                      period, mortgage costs (or input the interest rate and the system will calculate the monthly
                      payment)
                    </li>
                    <li className="my-1">ii) Maintenance, insurance & rates</li>
                    <li className="my-1">iii) Projected property growth rate</li>
                    <li className="my-1">iv) Liquidation costs including tax rates on a secondary home</li>
                    <li className="my-1">
                      v) Note that defaults are available but should be considered to be indicative only
                    </li>
                  </ul>
                </li>
                <li className="my-1">
                  b) Output #1 is the cost of ownership of a desired home and its Present Value over time including
                  liquidation value.
                </li>
                <li className="my-1">
                  c) Output #2 is the determination of what home the client can afford and its present value over time
                  including liquidation value vs cost of ownership.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Representation #3, inputs are a comparison of two options viz.
              <ul className="pl-4">
                <li className="my-1">
                  a) Cost of ownership of a home – goal is home equity creation (wealth)
                  <ul className="pl-4">
                    <li className="my-1">
                      i) Purchase value, costs of ownership variables, home value growth, sale variables (NB concept of
                      Capital Gains Tax / primary home rebate)
                    </li>
                    <li className="my-1">
                      ii) These are essentially the same variable as used in “what does it cost to purchase a home?”
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="pl-4">
                <li className="my-1">
                  b) Property rental & investing the difference – goal is flexibility
                  <ul className="pl-4">
                    <li className="my-1">i) Cost of renting an equivalent home with annual escalations</li>
                    <li className="my-1">
                      ii) Investment inputs – equivalent capital for deposit, investment returns, Capital Gains Tax (we
                      default to maximum i.e. max marginal tax rate (45%) * inclusion rate (40%) = 18%; this can be
                      overwritten by the client)
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="pl-4">
                <li className="my-1">
                  c) Output is a table / chart showing:
                  <ul className="pl-4">
                    <li className="my-1">i) Cumulative present value of home ownership costs (negative)</li>
                    <li className="my-1">ii) Cumulative present value of rent & offsets (negative)</li>
                    <li className="my-1">iii) Present value of home equity (i.e. home value less cost of ownership)</li>
                    <li className="my-1">
                      iv) Present value of investments (i.e. cumulative residual capital after rents etc.)
                    </li>
                    <li className="my-1">
                      v) Significant element will be the breakeven period (years) – how long should one stay in a home
                      before it becomes worthwhile to have purchased rather than rented?
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("home-ownership-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Home ownership"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Home Ownership" />
      <ul className="hidden text-md text-center rounded-lg shadow sm:flex fixed w-full z-10">
        <li
          className="w-full"
          onClick={() => {
            setTabs("buyVsRentToolOne");
          }}
        >
          <span
            className={`${tabs === "buyVsRentToolOne" ? "bg-[#e6e7ee] font-bold" : "bg-white"} hover:bg-gray-50 inline-block w-full p-4 text-gray-900 cursor-pointer`}
          >
            How much will my home cost?
          </span>
        </li>
        <li
          className="w-full"
          onClick={() => {
            setTabs("buyVsRentToolTwo");
          }}
        >
          <span
            className={`${tabs === "buyVsRentToolTwo" ? "bg-[#e6e7ee] font-bold" : "bg-white"} hover:bg-gray-50 inline-block w-full p-4 border-s-0 border-gray-200 cursor-pointer`}
          >
            What home can I afford?
          </span>
        </li>
        <li
          className="w-full"
          onClick={() => {
            setTabs("buyVsRentToolThree");
          }}
        >
          <span
            className={`${tabs === "buyVsRentToolThree" ? "bg-[#e6e7ee] font-bold" : "bg-white"} hover:bg-gray-50 inline-block w-full p-4 border-s-0 border-gray-200 cursor-pointer`}
          >
            Breakeven - ownership vs renting
          </span>
        </li>
      </ul>

      <div className="w-full min-h-[calc(100vh-126px)] pt-[55px]">
        {tabs === "buyVsRentToolOne" ? (
          <div className="col-span-1">
            <div>
              <BuyVsRentToolOne />
            </div>
          </div>
        ) : tabs === "buyVsRentToolTwo" ? (
          <div className="col-span-1">
            <div>
              <BuyVsRentToolTwo />
            </div>
          </div>
        ) : (
          tabs === "buyVsRentToolThree" && (
            <div className="col-span-1">
              <div>
                <BuyVsRentToolThree />
              </div>
            </div>
          )
        )}
      </div>
    </StandardToolLayout>
  );
};

export default BuyVsRent;
