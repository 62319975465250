import useBudgetingTool from "@api/Standard/Additional/useBudgetingTool";
import PageTitle from "@elements/PageTitle";
import StandardToolLayout from "@layouts/StandardToolLayout";
import { useState } from "react";

const Budgeting = () => {
  const { loading, error, formData, handleChange, handleSubmit, budgetingData } = useBudgetingTool();

  const [showHelpPopup, setShowHelpPopup] = useState(
    localStorage.getItem("simple-budgeting-tool-help-popup") === "true"
  );

  const HelpText = () => {
    return (
      <div className="text-[14px]">
        <p className="text-lg font-bold my-3">Description</p>
        <div className="mb-0 px-3">
          <ul className="list-decimal">
            <li className="mb-2">
              Tool will assist client to understand his/her income and expenditure within the contexts of
              <ul className="pl-4">
                <li className="my-1">
                  a) Active income (reward for work) vs passive income (due from financial assets including interest and
                  dividends)
                </li>
                <li className="my-1">b) Wealth creation spending (building assets for future consumption)</li>
                <li className="my-1">
                  c) Wealth preservation spending (protection against negative events including insurance spend)
                </li>
                <li className="my-1">d) Consumption spending (maintaining your standard of living)</li>
                <li className="my-1">
                  e) Residual / Net bank balance / opportunity costs (what’s left at the end of the month)
                </li>
              </ul>
            </li>
            <li className="mb-2">
              Note the significant size of table required to cover all aspects. Inputs include
              <ul className="pl-4">
                <li className="my-1">
                  a) Specific budget line items by category
                  <ul className="pl-4">
                    <li className="my-1">i) Current monthly amount (associated calculated annual amount)</li>
                    <li className="my-1">ii) Expected annual change by item.</li>
                    <li className="my-1">
                      iii) Projected terminal year for item (e.g. when will you stop earning an income, when will you
                      stop contributing to investment funds etc)
                    </li>
                  </ul>
                </li>
                <li className="my-1">b) Defaults are available to support projections.</li>
              </ul>
            </li>
            <li className="mb-2">
              Thes inputs are used to represent a comparison of each element (as above) in today’s terms, how these
              elements will change over time and what situation the client will likely find himself in over a 10-year
              period.
            </li>
            <li className="mb-2">
              We provide an estimated tax component based on current and projected official tax tables.
            </li>
            <li className="mb-2">
              We allow you to include a “year specific” element to cover ad hoc income and expenditure in order to
              provide a more comprehensive overview.
            </li>
            <li className="mb-2">
              The goal of this tool is to provide the client a graphic representation of his projected next 10- year
              journey with an associated table of annual expectations.
            </li>
          </ul>
        </div>
        <div className="text-[14px] font-medium mt-4">
          <div className="flex items-center">
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-[#243665] bg-gray-100 border-gray-300 rounded"
              checked={showHelpPopup}
              onChange={(e) => {
                localStorage.setItem("simple-budgeting-tool-help-popup", `${e.target.checked}`);
                setShowHelpPopup(e.target.checked);
              }}
            />
            <label
              htmlFor="default-checkbox"
              className="ms-2 text-md"
            >
              Do not show this again
            </label>
          </div>
        </div>
      </div>
    );
  };

  return (
    <StandardToolLayout
      showBackButton
      pageTitle="Simple Budgeting Tool"
      modalContent={<HelpText />}
      showHelpPopup={showHelpPopup}
    >
      <PageTitle title="Budgeting" />
      <div>
        <form
          onSubmit={handleSubmit}
          className="lg:w-[90%] mx-auto"
        >
          <label>
            Amount:
            <input
              type="text"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
            />
          </label>

          <button type="submit">Submit</button>
        </form>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {budgetingData && <div>{/* Display budgetingData */}</div>}
      </div>
    </StandardToolLayout>
  );
};

export default Budgeting;
