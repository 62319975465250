const words = () => {
  function capitalizeFirstLetter(word: string) {
    if (!word) {
      return ''
    }

    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  return {
    capitalizeFirstLetter,
  }
}

export default words
