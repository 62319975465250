import { differenceInDays, endOfMonth } from "date-fns";

const numbers = () => {
  const formatMobileNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    if (!numericValue) return "";

    const formattedValue = numericValue.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "+$1 $2 $3 $4");

    return formattedValue;
  };

  const maskMobileNumber = (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    const maskPattern = /^(\d{0,2})(\d{0,3})(\d{0,4})$/;

    const maskedValue = numericValue.replace(maskPattern, (_match, p1: number, p2: number, p3: number) => {
      let maskedNumber = "";
      if (p1) maskedNumber += `+${p1}`;
      if (p2) maskedNumber += ` ${p2}`;
      if (p3) maskedNumber += ` ${p3}`;
      return maskedNumber;
    });

    return maskedValue;
  };

  const isValidSAIDNumber = (idNumber: string) => {
    // Check if the ID number is of correct length and format
    const idRegex = /^[0-9]{13}$/;
    if (!idRegex.test(idNumber)) {
      return false;
    }

    // Extract components from the ID number
    const year = parseInt(idNumber.substring(0, 2), 10);
    const yearPrefix = year > 10 ? "19" : "20";
    const birthYear = parseInt(yearPrefix + year);
    const birthDate = new Date(birthYear, 0, 1);
    const month = parseInt(idNumber.substring(2, 4), 10);
    const day = parseInt(idNumber.substring(4, 6), 10);
    const currentDate = new Date();

    // Calculate a date 150 years ago from the current date
    const thresholdDate = new Date();
    thresholdDate.setFullYear(currentDate.getFullYear() - 150);

    if (birthDate < thresholdDate || birthDate > currentDate) {
      return false;
    }

    // Validate the Date of birth
    if (month < 1 || month > 12 || day < 1 || day > 31) {
      return false;
    }

    if (idNumber.length < 13) {
      return false;
    }

    // Passed all validations
    return true;
  };

  // const calculateCheckSum = (idNumber: string) => {
  //   const idArray = idNumber.split('').map((digit: string) => parseInt(digit));

  //   let sum = 0;
  //   for (let i = 0; i < 12; i++) {
  //     sum += idArray[i] * (i % 2 === 0 ? 1 : 2);
  //   }

  //   const remainder = sum % 10;
  //   return (10 - remainder) % 10;
  // };

  const generateDateOfBirthFromID = (idNumber: string) => {
    const year = parseInt(idNumber.substring(0, 2), 10);
    const month = parseInt(idNumber.substring(2, 4), 10);
    const day = parseInt(idNumber.substring(4, 6), 10);
    const currentYear = new Date().getFullYear();

    const fullYear = year < currentYear % 100 ? 2000 + year : 1900 + year;

    return new Date(fullYear, month - 1, day + 1);
  };

  const calculateCurrentAge = (dateOfBirth: Date, effectiveDate: Date) => {
    const daysDifference = differenceInDays(effectiveDate, dateOfBirth);
    const yearsDifference = daysDifference / 365.25;

    return parseFloat(yearsDifference.toFixed(2));
  };

  function calculateYearsToRetirement(effectiveDate: Date, retirementDate: Date): number {
    const start = endOfMonth(effectiveDate);
    const end = endOfMonth(retirementDate);

    const daysDifference = differenceInDays(end, start);
    const yearsDifference = daysDifference / 365.25;

    return parseFloat(yearsDifference.toFixed(2));
  }

  const calculateRetirementIncome = (
    annualIncome: number,
    effectiveDate: Date,
    retirementDate: Date,
    realGrowthInIncome: number,
    targetIncomeOnRetirement: number
  ) => {
    const start = endOfMonth(effectiveDate);
    const end = endOfMonth(retirementDate);

    const daysDifference = differenceInDays(end, start);
    const yearsToRetirement = daysDifference / 365.25;
    const growthRate = realGrowthInIncome / 100;
    const targetIncomePercentage = targetIncomeOnRetirement / 100;
    const growthFactor = Math.pow(1 + growthRate, yearsToRetirement);

    const retirementIncome = annualIncome * growthFactor * targetIncomePercentage;

    return parseInt(retirementIncome.toFixed(0));
  };

  const calculateRetirementDate = (dateOfBirth: number, retirementAge: string) => {
    const dob = new Date(dateOfBirth) as any;
    const retirementDate = new Date(
      dob.getFullYear() + parseInt(retirementAge, 10),
      dob.getMonth(),
      dob.getDate(),
      23,
      59,
      59
    ) as any;

    retirementDate.setMonth(retirementDate.getMonth() + 1, 1);
    retirementDate.setDate(retirementDate.getDate() - 1);

    return retirementDate.toISOString().split("T")[0];
  };

  const calculateRetirementLongevity = (retirementAge: any, lifeExpectancy: any) => {
    const retirementAgeParsed = parseInt(retirementAge, 10);
    const lifeExpectancyParsed = parseInt(lifeExpectancy, 10);

    const retirementLongevity = lifeExpectancyParsed - retirementAgeParsed;

    return parseInt(retirementLongevity.toFixed(0));
  };

  return {
    formatMobileNumber,
    maskMobileNumber,
    isValidSAIDNumber,
    generateDateOfBirthFromID,
    calculateCurrentAge,
    calculateYearsToRetirement,
    calculateRetirementDate,
    calculateRetirementLongevity,
    calculateRetirementIncome,
  };
};

export default numbers;
