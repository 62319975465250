import { useState } from "react";

const growthRateHandler = (formData: any, setFormData: any) => {
  const [updating, setUpdating] = useState(false);

  const calculateNominalGrowthRate = (realGrowth: number, inflation: number) => {
    let nominalGrowthRate = ((1 + realGrowth / 100) * (1 + inflation / 100) - 1) * 100;
    return parseFloat(nominalGrowthRate.toFixed(2));
  };

  const calculateRealGrowthRate = (nominalGrowth: number, inflation: number) => {
    let realGrowthRate = ((1 + nominalGrowth / 100) / (1 + inflation / 100) - 1) * 100;
    return parseFloat(realGrowthRate.toFixed(2));
  };

  const handleNominalChange = (value: any, name: any) => {
    if (!updating) {
      const realKey = name.replace("nominal", "real");
      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [realKey]: calculateRealGrowthRate(value, formData.projected_inflation_rate),
      }));
      setUpdating(false);
    }
  };

  const handleRealChange = (value: any, name: any) => {
    if (!updating) {
      const nominalKey = name.replace("real", "nominal");
      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [nominalKey]: calculateNominalGrowthRate(value, formData.projected_inflation_rate),
      }));
      setUpdating(false);
    }
  };

  function calculateFutureValue(targetIncome: number, inflationRate: number, yearsToRetirement: number): number {
    const futureValue = targetIncome * Math.pow(1 + inflationRate / 100, yearsToRetirement);

    return parseInt(futureValue.toFixed(0));
  }

  function calculatePresentValue(futureValue: number, inflationRate: number, yearsToRetirement: number): number {
    const presentValue = futureValue / Math.pow(1 + inflationRate / 100, yearsToRetirement);

    return parseInt(presentValue.toFixed(0));
  }

  const handleFutureChange = (value: any, name: any) => {
    if (!updating) {
      const realKey = name.replace("nominal", "real");
      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [realKey]: calculatePresentValue(value, formData.projected_inflation_rate, formData.years_to_retirement),
      }));
      setUpdating(false);
    }
  };

  const handlePresentChange = (value: any, name: any) => {
    if (!updating) {
      const nominalKey = name.replace("real", "nominal");
      setUpdating(true);
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
        [nominalKey]: calculateFutureValue(value, formData.projected_inflation_rate, formData.years_to_retirement),
      }));
      setUpdating(false);
    }
  };

  return {
    calculateNominalGrowthRate,
    calculateRealGrowthRate,
    handleNominalChange,
    handleRealChange,
    updating,
    setUpdating,
    calculateFutureValue,
    calculatePresentValue,
    handleFutureChange,
    handlePresentChange,
  };
};

export default growthRateHandler;
