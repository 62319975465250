const AnalysisType = ({ primaryRepresentation, handleChange }) => {
  return (
    <>
      <p className="mr-4 font-bold text-[16px]">Analysis type:</p>
      <div className="bg-white h-[44px] rounded-md p-1 flex items-center">
        <button
          className={`px-[18px] h-[36px] rounded mr-1 text-[12px] min-w-[100px] hover:bg-[#555555] hover:text-white transition-all ${
            primaryRepresentation === "nominal" ? "bg-[#555555] text-white" : "font-medium bg-[#f3f3f3]"
          }`}
          onClick={() => {
            handleChange("nominal");
          }}
        >
          Nominal
        </button>
        <button
          className={`px-[18px] h-[36px] rounded text-[12px] min-w-[100px] hover:bg-[#555555] hover:text-white transition-all ${
            primaryRepresentation === "real" ? "bg-[#555555] text-white" : "font-medium bg-[#f3f3f3]"
          }`}
          onClick={() => {
            handleChange("real");
          }}
        >
          Real
        </button>
      </div>
    </>
  );
};

export default AnalysisType;
